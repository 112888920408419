import "font-awesome/css/font-awesome.min.css";
import React from "react";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import MainModal from "./components/AdvisorRegister/MainModal";
import AuthPages from "./components/AuthPages/AuthPages";
import PrivateRoute from "./components/AuthPages/PrivateRoute";
import PublicRoute from "./components/AuthPages/PublicRoute";
import DialogAlert from "./components/DialogAlert/DialogAlert";
import ForgotAfterEmailScreen from "./components/ForgotPasswordNew/ForgotAfterEmailScreen";
import StaticPages from "./components/StaticPages/StaticPages";
import AppBanner from "./Utils/AppBanner/AppBanner";
import AdminPages from "./components/AdminDashboard/AdminPages";
import ConfirmUnsubscribe from "./components/unsubscribe/ConfirmUnsubscribe";
import Unsubscribe from "./components/unsubscribe/Unsubscribe";
import { SendAlert, store } from "@plootus/common";

Modal.setAppElement("#root");

function App(props) {
  return (
    <div className="App">
      <SendAlert render={(props) => <DialogAlert {...props} />} />
      {isMobile && <AppBanner />}
      <Switch>
        <PrivateRoute path="/auth" Component={AuthPages} />

        <Route
          path="/forgot"
          component={(props) => <ForgotAfterEmailScreen {...props} />}
        />
        <Route path="/confirm-unsubscribe" component={ConfirmUnsubscribe} />
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Route
          path="/advisor"
          component={(props) => <MainModal {...props} />}
        />
        <Route path="/admin" component={AdminPages} />
       
        <PublicRoute path="/" Component={StaticPages} />
      </Switch>
    </div>
  );
}

export default App;

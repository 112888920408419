import {
  DARK_RED_PLOOT,
  formatCommaString,
  GREEN_PLOOT_1,
  LIGHT_GREY,
  roundNum,
  TEXT_BLACK_2,
  TEXT_BLACK_3,
  toTitleCase,
} from '@plootus/common';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import styles from './CannotExpandItem.module.css';

const CannotExpandItem = ({
  rightValue,
  leftTop,
  leftBottomKey,
  leftBottomValue,
  index,
  valueColor,
  rightBottom,
  shouldShowDate,
  first,
  last,
}) => {
  const amount = `$${formatCommaString(roundNum(rightValue, 0, 2))}`;

  return (
    <div
      className={styles.wrapper}
      style={{
        borderLeft: '1px solid #ddd',
        borderRight: '1px solid #ddd',
        borderTop: first ? '1px solid #ddd' : '0px',
        borderBottom: last ? '1px solid #ddd' : '0px',
      }}
    >
      <Row noGutters>
        {shouldShowDate ? (
          <Col
            xs="12"
            className={styles.dateCol}
            style={{ backgroundColor: LIGHT_GREY, color: TEXT_BLACK_3 }}
          >
            {moment(rightBottom).format('DD MMM YYYY')}
          </Col>
        ) : null}
        <Col xs="12" className={styles.bothWrapper}>
          <div
            className={styles.contentWrapper}
            style={{
              backgroundColor: index % 2 ? '#f0f0f0' : '#fff',
            }}
          >
            <Row noGutters>
              <Col xs="8">
                <Row noGutters>
                  <Col xs="12" className={styles.topLeft}>
                    {toTitleCase(leftTop)}
                  </Col>
                  <Col
                    xs="12"
                    className={styles.leftBottom}
                    style={{ color: TEXT_BLACK_2 }}
                  >
                    {toTitleCase(leftBottomKey)}:{' '}
                    {leftBottomValue.toUpperCase()}
                  </Col>
                </Row>
              </Col>
              <Col
                xs="4"
                className={styles.money}
                style={{
                  color:
                    valueColor === 'INCOME' ? GREEN_PLOOT_1 : DARK_RED_PLOOT,
                }}
              >
                {amount.slice(0, -2)}
                <span className={styles.decimal}>{amount.slice(-2)}</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CannotExpandItem;

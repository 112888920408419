import React from 'react';
import { GENERAL_FUTURE_EXPENSES_TABLE } from '@plootus/common';
import styles from './TableHeader.module.css';
import MySlider from './Slider/Slider';
import CustomSlider from '../../Utils/Slider/CustomSlider';

const TableHeader = ({
  changeItem,
  dispatch,
  dispatchAction,
  inflation,
  isEditing
}) => {
  return (
    <div>
      <MySlider
        min={1}
        max={10}
        value={inflation}
        step={0.25}
        handleSliderChange={(event, newValue) =>
          dispatch({
            type: GENERAL_FUTURE_EXPENSES_TABLE,
            payload: { inflation: newValue }
          })
        }
        label='Inflation'
        displayLabel='yes'
      />
      {isEditing ? (
        <div className={styles.buttonCont}>
          <button
            className={styles.cancelBtn}
            onClick={() => {
              dispatchAction({
                type: 'CHANGE_EDITING',
                payload: false
              });
            }}
          >
            Cancel
          </button>
          <button
            className={styles.applyBtn}
            onClick={() => {
              changeItem();
            }}
          >
            Apply
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default TableHeader;

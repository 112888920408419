"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.process_type_rows = exports.data_type_rows = exports.genders = exports.strategyOptions = void 0;
exports.strategyOptions = [
    { title: 'Super Conservative', value: 0.035 },
    { title: 'Conservative', value: 0.052 },
    { title: 'Moderate', value: 0.0697 },
    { title: 'Growth', value: 0.0864 },
    { title: 'Super Growth', value: 0.0988 },
];
exports.genders = [
    { label: '--', value: null },
    { label: 'Male', value: '0' },
    { label: 'Female', value: '1' },
    { label: 'Rather Not Say', value: '2' },
    { label: 'Others', value: '3' },
];
// Picker for selecting type of screen in Visual Screens
exports.data_type_rows = [
    { label: 'By Category', value: '1' },
    { label: 'By Date', value: '2' },
];
// Sorting pickers for processing accord data
exports.process_type_rows = [
    { label: 'High to Low', value: '1' },
    { label: 'Low to High', value: '2' },
    { label: 'Most Recent', value: '3' },
    { label: 'Most Old', value: '4' },
];

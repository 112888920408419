import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../Assets/logo.png";
import MainModal from "../../../components/MainModal/MainModal";
import { CaresActContext } from "../../../Reusables/VisualAndAccord/Context/CaresActContext";
import useEmployerSearch from "../../../Reusables/VisualAndAccord/Hooks/useEmployerSearch";
import Search401kNew from "../../Search401kNew/SearchBox401kNew";
import StaticSidebar from "../StaticSidebar/StaticSidebar";
import styles from "./NavBar.module.css";
import cx from "classnames";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const selected = useRef(0);
  const [scrolled, setScrolled] = useState(false);
  const props = useEmployerSearch();

  const { openCaresAct } = useContext(CaresActContext);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY > 100;
      if (isTop) {
        setScrolled(true);
      } else setScrolled(false);
    });
  }, []);

  const myLocation = useRef("/");

  myLocation.current = useLocation().pathname;

  switch (myLocation.current) {
    case "/":
      selected.current = 1;
      break;

    case "/philosophy":
      selected.current = 2;
      break;

    case "/algorithm":
      selected.current = 3;
      break;

    case "/security":
      selected.current = 4;
      break;

    case "/faq":
      selected.current = 5;
      break;

    case "/employer":
      selected.current = 6;
      break;
    case "/401kCalculator":
      selected.current = 7;
      break;
  }

  const activeClass = {
    color: "#406bdf",
    opacity: "1",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: " none !important",
    borderTop: "2px solid #406bdf",
    paddingTop: "7px",
    transform: "scale(0, 1)",
    transition: "transform ease-in-out 250ms",
  };

  const [SideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  return (
    <div className={styles.container}>
      <nav
        className={styles.main}
        style={scrolled ? { height: "65px" } : { boxShadow: "none" }}
      >
        <input
          checked={open}
          type="checkbox"
          id="check"
          className={styles.Check}
        />

        <label
          for="check"
          className={styles.burger}
          onClick={() => setOpen((state) => !state)}
        >
          <div className={styles.toggle_button_line} />
          <div className={styles.toggle_button_line} />
          <div className={styles.toggle_button_line} />
        </label>

        <label className={styles.logo}>
          <img className={styles.actualLogo} src={Logo} alt="Plootus" />
        </label>
        {open ? (
          <StaticSidebar
            activeClass={activeClass}
            setOpen={setOpen}
            drawerToggleClickHandler={drawerToggleClickHandler}
          />
        ) : (
          <div className={styles.temp}>
            <ul className={styles.unordered}>
              <li className={styles.listItem} onClick={() => setOpen(false)}>
                <Link
                  to="/"
                  className={`${styles.itemLink} ${styles.active}`}
                  style={selected.current === 1 ? activeClass : null}
                >
                  Home
                </Link>
              </li>
              <li className={styles.listItem} onClick={() => setOpen(false)}>
                <Link
                  to="/philosophy"
                  className={styles.itemLink}
                  style={selected.current === 2 ? activeClass : null}
                >
                  Philosophy
                </Link>
              </li>

              <li className={styles.listItem} onClick={() => setOpen(false)}>
                <Link
                  to="/security"
                  className={styles.itemLink}
                  style={selected.current === 4 ? activeClass : null}
                >
                  Security
                </Link>
              </li>
              <li className={styles.listItem} onClick={() => setOpen(false)}>
                <Link
                  to="/401kCalculator"
                  className={styles.itemLink}
                  style={selected.current === 7 ? activeClass : null}
                >
                  401k Calculator
                </Link>
              </li>
              {/* <li className={styles.listItem} onClick={openCaresAct}>
                <Link
                  to="#corona-virus-stimulus-calculator"
                  className={cx(styles.caresAct, styles.itemLink)}
                >
                  Covid-19 Stimulus Calculator
                </Link>
              </li> */}
            </ul>

            <ul className={styles.unordered}>
              {/* <li
                className={`${styles.listItem} ${styles.biggerWidthParent} `}
                style={{
                  marginRight: "50px",
                }}
              >
                <div className={styles.myTp}>
                  <Search401kNew
                    myStyles={{
                      root: {
                        height: "40px",
                        fontSize: "13px",
                      },

                      innerDiv: {
                        textAlign: "left",
                        padding: "10px 0px",
                        paddingLeft: "20px",
                      },

                      icon: { fontSize: "25px" },
                      inputStyle: { fontSize: "13px" },
                    }}
                    {...props}
                    fromStatic={true}
                  />
                </div>
              </li> */}

              <li>
                <MainModal />
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default NavBar;

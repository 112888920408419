import React from 'react';
import {
  sendAlert,
  generalMoney,
  strategy_constants,
  generalGraphActions,
  updateProfile,
  SUCCESS_CODE,
  BLUE_PLOOT,
  useDidUpdate,
} from '@plootus/common';
import MyText from '../../../../Utils/Texts/MyText';
import styles from './RetirementStatus.module.css';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

const andContribution = (value) =>
  value
    ? `and increase the Annual Contribution to ${generalMoney(value)} per year`
    : '';
const andRetireMsg = (value) =>
  value ? `and increase the Retirement age to ${value}` : '';
const andStrategyMsg = (value) =>
  value ? `and change the Strategy to ${strategy_constants[value].label}` : '';

const lifExpMsg = (value) =>
  `while keeping all other inputs same. This is will grow retirement assets that will last until age ${value} years.`;

const ContributionMessage = ({
  contribution,
  lifeExp,
  retireAge,
  strategy,
}) => {
  return (
    <p
      className={styles.msg}
    >{`Increase the Annual Contribution to ${generalMoney(
      contribution
    )} per year, ${andRetireMsg(retireAge)} ${andStrategyMsg(
      strategy
    )}${lifExpMsg(lifeExp)}`}</p>
  );
};

const RetireAgeMessage = ({ contribution, lifeExp, retireAge, strategy }) => {
  return (
    <p
      className={styles.msg}
    >{`Increase the Retirement Age to ${retireAge}, ${andContribution(
      contribution
    )} ${andStrategyMsg(strategy)} ${lifExpMsg(lifeExp)}`}</p>
  );
};

const StrategyMessage = ({ contribution, lifeExp, retireAge, strategy }) => {
  return (
    <p className={styles.msg}>{`Change the Strategy to ${
      strategy_constants[strategy].label
    },${andContribution(contribution)} ${andRetireMsg(retireAge)} ${lifExpMsg(
      lifeExp
    )}`}</p>
  );
};

const selectMessage = (type, values) => {
  switch (type) {
    case 'contribution':
      return <ContributionMessage {...values} />;

    case 'retireAge':
      return <RetireAgeMessage {...values} />;

    case 'strategy':
      return <StrategyMessage {...values} />;
  }
};

const types = ['contribution', 'retireAge', 'strategy'];

const WarningMessage = ({
  OR,
  type,
  values,
  stateData,
  index,
  setIndex,
  setModalOpen,
  setLoading,
  setstateData,
}) => {
  const editAssumptions = useSelector((state) => state.graphReducer);
  const dispatch = useDispatch();
  useDidUpdate(() => {
    if (stateData.index === index && stateData.submit) {
      handlePress();
    }
  }, [stateData]);

  const handlePress = async () => {
    const updatedEditAssumtions = { ...editAssumptions };

    const updatedSliderOptions = {
      ...updatedEditAssumtions.sliderOptions,
    };

    Object.keys(values).map((key) => {
      if (key === 'strategy') {
        updatedEditAssumtions.strategyIndex = values.strategy;
      } else {
        const updatedSlider = {
          ...updatedSliderOptions[key],
        };

        updatedSlider.value = values[key];
        updatedSliderOptions[key] = updatedSlider;

        updatedEditAssumtions.sliderOptions = updatedSliderOptions;
      }
    });

    const postData = {
      retireAge: updatedSliderOptions?.retireAge.value,
      annualContribution: updatedSliderOptions?.contribution.value,
      strategy: updatedEditAssumtions.strategyIndex,
    };
    const code = await dispatch(updateProfile(postData));

    if (code === SUCCESS_CODE) {
      dispatch(generalGraphActions({ ...updatedEditAssumtions }));
      setLoading(false);
      setModalOpen(false);
    }
    setstateData((prev) => ({ ...prev, submit: false, index: null }));
  };

  return (
    <div>
      {OR ? (
        <MyText
          style={{
            textAlign: 'center',
            padding: '10px 0px',
            color: BLUE_PLOOT,
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          OR
        </MyText>
      ) : null}
      <div style={{ display: 'flex' }}>
        <div className={styles.circleDiv} onClick={() => setIndex()}>
          <div
            className={stateData.index === index ? styles.circle : styles.radio}
          />
        </div>
        <div className={styles.warningContainer} onClick={() => setIndex()}>
          {selectMessage(type, {
            ...values,
            lifeExp: editAssumptions.sliderOptions?.lifeExp.value,
          })}
        </div>
      </div>
    </div>
  );
};

export default WarningMessage;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPortfolio = exports.pieDataEmpty = exports.get_liab_credit = exports.get_assets_cat = exports.get_cashflow = exports.get_expense_transactions = exports.get_expense = exports.get_income_transactions = exports.get_income = exports.get_networth = exports.get_transactions_helper = void 0;
var helperfunctions_1 = require("../Utils/helperfunctions");
var return_code_constants_1 = require("./../../constants/return_code_constants");
var formatData_1 = require("./../../functions/formatData");
var utils_1 = require("./../../functions/utils");
var types_1 = require("./types");
var styles_1 = require("../../styles");
var selectors_1 = require("../user/selectors");
var convert_to_pie_helper = function (data, property) {
    var pie_data = [];
    var accord_data = [];
    var total = 0;
    var flag = false;
    data.map(function (item) {
        if (property !== undefined) {
            if (item[property]) {
                total += +item[property];
            }
        }
    });
    data.map(function (item, idx) {
        if (property !== undefined) {
            var should_add = item[property] !== null;
            if (should_add) {
                var per = +(0, formatData_1.roundNum)((+item[property] / total) * 100, 0, 2, true);
                // const color = getRandomColor().slice(1, 7);
                var color = styles_1.color_pallete[idx];
                accord_data.push(__assign(__assign({}, item), { per: per, color: color, selected: false }));
                if (+item[property] / total) {
                    pie_data.push(__assign({ x: (0, formatData_1.getFormattedValue)(+item[property] / 1000, 'money'), y: Math.abs(+item[property]), selected: flag, per: per, color: color }, item));
                }
                flag = false;
            }
        }
    });
    if (accord_data.length && pie_data.length) {
        return {
            accord_data: accord_data,
            pie_data: pie_data,
            total: total,
        };
    }
    return null;
};
var convert_to_graph_helper = function (data, property1, property2, property3, totalProp, dateProp, 
// Temp
api) {
    var _a, _b;
    var graph_data = [];
    var accord_data = (0, utils_1.resolveObjectPath)(property3, data);
    var total = (0, utils_1.resolveObjectPath)(totalProp, data);
    if (api === 'cashflow') {
        (_a = (0, utils_1.resolveObjectPath)(property2, data)) === null || _a === void 0 ? void 0 : _a.map(function (item) {
            var exp = 0;
            var inc = 0;
            item.transactionData.map(function (itm) {
                if (itm.categoryType === 'EXPENSE') {
                    exp += +itm.amount;
                }
                else {
                    inc += +itm.amount;
                }
            });
            graph_data.push({
                x: item[dateProp || 'date'],
                y: -exp,
                year: item.currentYear,
            });
            graph_data.push({
                x: item[dateProp || 'date'],
                y: inc,
                year: item.currentYear,
            });
        });
    }
    else {
        (_b = (0, utils_1.resolveObjectPath)(property2, data)) === null || _b === void 0 ? void 0 : _b.map(function (item) {
            if (property1 !== undefined) {
                graph_data.push({
                    x: item.date,
                    y: +item[property1],
                });
            }
        });
    }
    return { graph_data: graph_data, total: total, accord_data: accord_data || [] };
};
var api_helper = function (api, params, successActionType, _a, method) {
    var dispatch = _a[0], getState = _a[1];
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, url, pie_args, responseContainer, payloadCont, isAdvisor, userId, paramsOb, _c, responseData, errorCode, payload, successAction, screen, resData, payloadCheck, _d, pie_data, accord_data, total, _e, accord_data, total, graph_data, responseCode_1, responseCode;
        var _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    _b = (0, helperfunctions_1.get_api_responseContainer)(api), url = _b.api_params.url, pie_args = _b.pie_args, responseContainer = _b.responseContainer, payloadCont = _b.payloadCont;
                    isAdvisor = (0, selectors_1.isAdvisorSelector)(getState());
                    userId = isAdvisor
                        ? (_f = (0, selectors_1.currentClientSelector)(getState())) === null || _f === void 0 ? void 0 : _f.uuid
                        : (_g = getState().userReducer.userData) === null || _g === void 0 ? void 0 : _g.uuid;
                    paramsOb = __assign(__assign({}, params), { userId: userId });
                    return [4 /*yield*/, (0, helperfunctions_1.async_func_data)(url, paramsOb, [getState, dispatch], method)];
                case 1:
                    _c = _h.sent(), responseData = _c.responseData, errorCode = _c.errorCode;
                    screen = (0, helperfunctions_1.getScreenFromApi)(api);
                    resData = (0, utils_1.resolveObjectPath)(responseContainer, responseData);
                    payloadCheck = payloadCont
                        ? (0, utils_1.resolveObjectPath)(payloadCont, responseData).length
                        : resData.length;
                    if (!(errorCode === return_code_constants_1.SUCCESS_CODE)) return [3 /*break*/, 3];
                    payload = payloadCheck
                        ? successActionType === types_1.GENERAL_SET_GRAPH_DATA
                            ? convert_to_graph_helper.apply(void 0, __spreadArray(__spreadArray([resData], pie_args, false), [api], false)) : convert_to_pie_helper.apply(void 0, __spreadArray([resData], pie_args, false))
                        : null;
                    if (!payload) {
                        dispatch((0, exports.pieDataEmpty)({ api: api, screen: screen }));
                        return [2 /*return*/, return_code_constants_1.SUCCESS_CODE];
                    }
                    if (successActionType === 'GENERAL_SET_PIE_DATA') {
                        _d = payload, pie_data = _d.pie_data, accord_data = _d.accord_data, total = _d.total;
                        successAction = {
                            type: successActionType,
                            payload: {
                                pie_data: pie_data,
                                accord_data: accord_data,
                                total: total,
                                screen: screen,
                                api_name: api,
                            },
                        };
                    }
                    else {
                        _e = payload, accord_data = _e.accord_data, total = _e.total, graph_data = _e.graph_data;
                        successAction = {
                            type: successActionType,
                            payload: {
                                accord_data: accord_data,
                                total: total,
                                graph_data: graph_data,
                                screen: screen,
                                api_name: api,
                            },
                        };
                    }
                    return [4 /*yield*/, (0, helperfunctions_1.handleResponse)(errorCode, dispatch, successAction)];
                case 2:
                    responseCode_1 = _h.sent();
                    return [2 /*return*/, responseCode_1];
                case 3: return [4 /*yield*/, (0, helperfunctions_1.handleResponse)(errorCode, dispatch, null)];
                case 4:
                    responseCode = _h.sent();
                    return [2 /*return*/, responseCode];
            }
        });
    });
};
var get_transactions_helper = function (api, params, _a, method) {
    var dispatch = _a[0], getState = _a[1];
    return __awaiter(void 0, void 0, void 0, function () {
        var transactionApiParams, url, otherParams, container, isAdvisor, userId, _b, responseData, errorCode, successAction, transaction, responseCode_2, responseCode;
        var _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    transactionApiParams = (0, helperfunctions_1.get_api_responseContainer)(api).transactionApiParams;
                    if (!(transactionApiParams !== undefined)) return [3 /*break*/, 5];
                    url = transactionApiParams.url, otherParams = transactionApiParams.otherParams;
                    if (!otherParams) return [3 /*break*/, 5];
                    container = otherParams.container;
                    isAdvisor = (0, selectors_1.isAdvisorSelector)(getState());
                    userId = isAdvisor
                        ? (_c = (0, selectors_1.currentClientSelector)(getState())) === null || _c === void 0 ? void 0 : _c.uuid
                        : (_d = getState().userReducer.userData) === null || _d === void 0 ? void 0 : _d.uuid;
                    return [4 /*yield*/, (0, helperfunctions_1.async_func_data)(url, __assign(__assign({}, params), { userId: userId }), [getState, dispatch], method)];
                case 1:
                    _b = _e.sent(), responseData = _b.responseData, errorCode = _b.errorCode;
                    successAction = void 0;
                    transaction = (0, utils_1.resolveObjectPath)(container, responseData);
                    if (!(errorCode === return_code_constants_1.SUCCESS_CODE)) return [3 /*break*/, 3];
                    successAction = {
                        type: types_1.GENERAL_TRASACTION,
                        payload: {
                            trans_data: transaction || [],
                            screen: (0, helperfunctions_1.getScreenFromApi)(api),
                            api_name: api,
                        },
                    };
                    return [4 /*yield*/, (0, helperfunctions_1.handleResponse)(errorCode, dispatch, successAction)];
                case 2:
                    responseCode_2 = _e.sent();
                    return [2 /*return*/, responseCode_2];
                case 3: return [4 /*yield*/, (0, helperfunctions_1.handleResponse)(errorCode, dispatch, null)];
                case 4:
                    responseCode = _e.sent();
                    return [2 /*return*/, responseCode];
                case 5: return [2 /*return*/, return_code_constants_1.ERROR_OCCURED];
            }
        });
    });
};
exports.get_transactions_helper = get_transactions_helper;
/** NODE API CALLS  *** */
// GET NET WORTH
var get_networth = function () { return function () {
    var thunkArgs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        thunkArgs[_i] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, api_helper(helperfunctions_1.NET_API, null, types_1.GENERAL_SET_GRAPH_DATA, thunkArgs, 'get')];
        });
    });
}; };
exports.get_networth = get_networth;
// GET INCOME
var get_income = function (params) { return function () {
    var thunkArgs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        thunkArgs[_i] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, api_helper(helperfunctions_1.INCOME_API, params, types_1.GENERAL_SET_PIE_DATA, thunkArgs, 'get')];
        });
    });
}; };
exports.get_income = get_income;
// EXTRACT TRANSACTIONS FROM DATA -> later convert to api call
var get_income_transactions = function (params) { return function () {
    var thunkArgs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        thunkArgs[_i] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, exports.get_transactions_helper)(helperfunctions_1.INCOME_API, params, thunkArgs, 'get')];
        });
    });
}; };
exports.get_income_transactions = get_income_transactions;
// GET EXPENSE
var get_expense = function (params) { return function () {
    var thunkArgs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        thunkArgs[_i] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, api_helper(helperfunctions_1.EXP_API, params, types_1.GENERAL_SET_PIE_DATA, thunkArgs, 'get')];
        });
    });
}; };
exports.get_expense = get_expense;
// EXTRACT TRANSACTIONS FROM DATA -> later convert to api call
var get_expense_transactions = function (params) { return function () {
    var thunkArgs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        thunkArgs[_i] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, exports.get_transactions_helper)(helperfunctions_1.EXP_API, params, thunkArgs, 'get')];
        });
    });
}; };
exports.get_expense_transactions = get_expense_transactions;
// GET EXPENSE
var get_cashflow = function (params) { return function () {
    var thunkArgs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        thunkArgs[_i] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, api_helper(helperfunctions_1.CASH_API, params, types_1.GENERAL_SET_GRAPH_DATA, thunkArgs, 'get')];
        });
    });
}; };
exports.get_cashflow = get_cashflow;
// GET ASSETS
var get_assets_cat = function () { return function () {
    var thunkArgs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        thunkArgs[_i] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, api_helper(helperfunctions_1.ASSET_CAT_API, null, types_1.GENERAL_SET_PIE_DATA, thunkArgs, 'get')];
        });
    });
}; };
exports.get_assets_cat = get_assets_cat;
var get_liab_credit = function () { return function () {
    var thunkArgs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        thunkArgs[_i] = arguments[_i];
    }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, api_helper(helperfunctions_1.CREDIT_API, null, types_1.GENERAL_SET_PIE_DATA, thunkArgs, 'get')];
        });
    });
}; };
exports.get_liab_credit = get_liab_credit;
// Actions
var pieDataEmpty = function (payload) { return ({
    type: types_1.PIE_DATA_EMPTY,
    payload: payload,
}); };
exports.pieDataEmpty = pieDataEmpty;
var resetPortfolio = function () { return ({
    type: types_1.RESET_PORTFOLIO,
}); };
exports.resetPortfolio = resetPortfolio;

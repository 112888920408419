import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import styles from "./GraphStyles.module.css";
import LineChart from "../../../Utils/Charts/NivoLine/LineChart";
import { useDispatch, useSelector } from "react-redux";
import {
  get_cashflow,
  GREEN_PLOOT,
  DARK_RED_PLOOT,
  getDate,
  DRAK_GREEN_PLOOT,
  formatCommaString,
  getFormattedValue,
  roundNum,
} from "@plootus/common";
import BarGraph from "../../../Utils/Charts/BarGraph/BarGraph";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";

const CashflowGraph = (props) => {
  const dispatch = useDispatch();

  const graph = useSelector((state) => state.portfolioReducer.incomexp);

  const [loading, setLoading] = useState(true);

  const styleB = { height: "100%", width: "100%" };

  const styleS = { height: "115%", width: "115%", marginLeft: "-40px" };

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    (async () => {
      dispatch(get_cashflow({ interval: 6 }));
      setLoading(false);
    })();
  }, []);

  if (loading) return <div>Loading...</div>;

  //console.log(graph, "new graphs");
  //   console.log(graph.networth.networth_graph, "hhh")

  let newGraph = [];
  if (graph.cashflow_graph)
    graph.cashflow_graph.map((item) => {
      newGraph.push({
        x: item.x.substr(0, 3) + " " + item.year,
        y: item.y,
      });
    });

  //newGraph.reverse();
  const total = `$${formatCommaString(
    roundNum(Math.abs(graph.cashflow_total), 0, 2)
  )}`;
  return (
    <Card
      className={styles.mainCard}
      style={{
        borderRadius: "10px",
        boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className={styles.cardHeader}>
        <div className={styles.headerLeft}>Cashflow</div>
        <div className={styles.headerRight} style={{ color: DARK_RED_PLOOT }}>
          {total.slice(0, -2)}
          <span className={styles.afterDecimal}>{total.slice(-2)}</span>
          {/* {graph.networth.networth_total} */}
        </div>
      </div>
      {graph.cashflow_graph ? (
        <div style={width <= 768 ? styleS : styleB}>
          <BarGraph
            sampleData={newGraph}
            color1={GREEN_PLOOT}
            color2={DARK_RED_PLOOT}
            barRatio={0.8}
            gType="cash"
            showTickX={true}
          />
        </div>
      ) : null}
      <Link className={styles.seeMore} to="/auth/incomexp">
        See More {">"}
      </Link>
    </Card>
  );
};

export default CashflowGraph;

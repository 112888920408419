"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDataLoading = void 0;
var react_1 = require("react");
var useDataLoading = function (initData) {
    var _a = (0, react_1.useState)(initData || {
        data: null,
        loading: true,
    }), data = _a[0], setDataState = _a[1];
    var setLoading = function (loading) {
        return setDataState(function (prev) { return (__assign(__assign({}, prev), { loading: loading })); });
    };
    var setData = function (dataArg) { return setDataState({ loading: false, data: dataArg }); };
    return __assign(__assign({}, data), { setLoading: setLoading, setData: setData });
};
exports.useDataLoading = useDataLoading;

import SearchIcon from "@material-ui/icons/Search";
import React, { useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../Assets/logo.png";
import MainModal from "../../../components/MainModal/MainModal";
import { CaresActContext } from "../../../Reusables/VisualAndAccord/Context/CaresActContext";
import styles from "./StaticSidebar.module.css";

const StaticSidebar = ({ activeClass, setOpen, drawerToggleClickHandler }) => {
  const selected = useRef(0);

  const { openCaresAct } = useContext(CaresActContext);

  const myL = useRef("/");

  myL.current = useLocation().pathname;

  switch (myL.current) {
    case "/":
      selected.current = 1;
      break;

    case "/philosophy":
      selected.current = 2;
      break;

    case "/security":
      selected.current = 3;
      break;

    case "/employer":
      selected.current = 4;
      break;

    case "/faq":
      selected.current = 5;
      break;

    case "/algorithm":
      selected.current = 6;
      break;
    case "/401kCalculator":
      selected.current = 7;
      break;
  }

  const dispatch = useDispatch();

  return (
    <div className={styles.sidebar_inner}>
      <button
        className={styles.closeBtn}
        onClick={() => setOpen((state) => !state)}
      >
        <img className={styles.cross} src="/cross-thin.png" alt="close" />
      </button>
      <div className={styles.logo}>
        <img src={Logo} className={styles.img} alt="Plootus" />
      </div>
      <ul className={styles.sidebar_menu}>
        <li>
          <Link
            className={styles.link}
            onClick={() => {
              drawerToggleClickHandler();
              selected.current = 4;
            }}
          >
            <div className={styles.temp}>
              <MainModal />
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/"
            className={styles.link}
            onClick={() => {
              selected.current = 1;
              setOpen(false);
            }}
          >
            <div className={selected.current === 1 ? styles.bold : styles.text}>
              Home
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/philosophy"
            className={styles.link}
            onClick={() => {
              selected.current = 2;
              setOpen(false);
            }}
          >
            <div className={selected.current === 2 ? styles.bold : styles.text}>
              Philosophy
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/security"
            className={styles.link}
            onClick={() => {
              setOpen(false);
              selected.current = 3;
            }}
          >
            <div className={selected.current === 3 ? styles.bold : styles.text}>
              Security
            </div>
          </Link>
        </li>
        {/* <li>
          <Link
            to="/employer"
            className={styles.link}
            onClick={() => {
              setOpen(false);
              selected.current = 4;
            }}
            style={{ marginRight: "50px" }}
          >
            <div className={selected.current === 4 ? styles.bold : styles.text}>
              401k Plan
              <SearchIcon
                style={{
                  fontSize: "30px",
                  // ...iconStyle,
                }}
                className={styles.inputIcon}
              />
            </div>
          </Link>
        </li> */}
        <li>
          <Link
            to="/401kCalculator"
            className={styles.link}
            onClick={() => {
              setOpen(false);
              selected.current = 7;
            }}
          >
            <div className={selected.current === 7 ? styles.bold : styles.text}>
              401k Calculator
            </div>
          </Link>
        </li>
        {/* <li>
          <Link
            to="#corona-virus-stimulus-calculator"
            className={styles.link}
            onClick={openCaresAct}
          >
            <div className={styles.text} style={{ textAlign: "left" }}>
              Corona Virus Stimulus Calculator
            </div>
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default StaticSidebar;

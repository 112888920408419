import { DARK_RED_PLOOT, DRAK_GREEN_PLOOT } from '@plootus/common';
import React from 'react';
import CategoryTabs from '../../../../Utils/CategoryTabs/CategoryTabs';
import Picker from '../../../../Utils/Containers/Picker/Picker';
import ProcessPicker from '../../../../Utils/Containers/ProcessPicker/ProcessPicker';
import IncomeSearch from '../../../../Utils/IncomeSearchBox/IncomeSearch';
import styles from './TableNewData.module.css';

const TableNewData = ({
  api_name,
  mappingData,
  title,
  amount,
  picker,
  timePeriod,
  dispatchReducerAction,
  setIndex,
  negativeModal,
  setValue,
  singleScreen,
  data_type_rows,
  dataTypePick,
  setdataTypePick,
  dataTypeModal,
  processPick,
  value,
  data,
  screen,
  dispatch,
  actionType,
  _key,
  expandSearchedAccord,
  expandAccordAndArc,
  expandNestedAccord,
  total,
  setprocessPick,
  AccordPartComponent,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.pickers}>
        <div className={styles.text}>
          <div className={styles.actualText}>{title}</div>
          <div
            className={styles.value}
            style={{
              color:
                total >= 0 && api_name !== 'expense'
                  ? DRAK_GREEN_PLOOT
                  : DARK_RED_PLOOT,
            }}
          >
            {amount.slice(0, -2)}
            <span className={styles.afterDecimal}>{amount.slice(-2)}</span>
          </div>
        </div>

        <div className={styles.datePicker}>
          {api_name === 'networth' ||
          api_name === 'income' ||
          api_name === 'expense' ||
          api_name === 'cashflow' ? (
            <Picker
              {...picker}
              rows={timePeriod}
              setIndex={(idx) => {
                dispatchReducerAction({
                  type: 'LOADING',
                });
                setIndex(idx);
              }}
              handlePress={negativeModal}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {mappingData.metaData ? (
        <div className={styles.search}>
          <div className={styles.searchDiv}>
            <IncomeSearch setValue={setValue} />
          </div>
        </div>
      ) : null}
      {!singleScreen ? (
        <div className={styles.dataPicker}>
          <div className={styles.dataPickerWrapper}>
            {/* <Picker
              {...dataTypePick}
              rows={data_type_rows}
              setIndex={(idx) => {
                dispatchReducerAction({
                  type: "LOADING",
                });
                setdataTypePick(idx);
              }}
              handlePress={dataTypeModal}
            /> */}
            <CategoryTabs
              {...dataTypePick}
              rows={data_type_rows}
              setIndex={(idx) => {
                dispatchReducerAction({
                  type: 'LOADING',
                });
                setdataTypePick(idx);
              }}
              handlePress={dataTypeModal}
            />
          </div>
        </div>
      ) : null}
      <div className={styles.empty}></div>
      <div className={styles.accordArea}>
        <div className={styles.processpicker}>
          <ProcessPicker
            singleScreen={singleScreen}
            setprocessPick={setprocessPick}
            setValue={setValue}
            value={value}
            setLoading={() =>
              dispatchReducerAction({
                type: 'LOADING',
              })
            }
            processPick={processPick}
            dataTypePick={dataTypePick}
          />
        </div>
        {api_name === 'networth' ? (
          <AccordPartComponent
            data={data}
            api_name={api_name}
            screen={screen}
            dispatch={dispatch}
            actionType={actionType}
            canExpand={dataTypePick.index}
            value={value}
            _key={_key}
            mappingData={mappingData}
            expandSearchedAccord={expandSearchedAccord}
            highlight_pie={expandAccordAndArc}
            expandNested={expandNestedAccord}
          />
        ) : (
          <div className={styles.accordCont}>
            <AccordPartComponent
              data={data}
              api_name={api_name}
              screen={screen}
              dispatch={dispatch}
              actionType={actionType}
              canExpand={dataTypePick.index}
              value={value}
              _key={_key}
              mappingData={mappingData}
              expandSearchedAccord={expandSearchedAccord}
              highlight_pie={expandAccordAndArc}
              expandNested={expandNestedAccord}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableNewData;

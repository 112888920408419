import React, { useState, Suspense } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { BLUE_PLOOT } from '@plootus/common';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import Footer from '../../Utils/Containers/Footer/Footer';
import NavBar from '../../Utils/Containers/Navbar/NavBar';
import Error404 from '../404/Error404';
import Algorithm from '../Algorithm/Algorithm';
import StaticEmployer from '../Employer2/StaticEmployer';
import Faqs from '../FAQs/Faqs';
import Privacy from '../Privacy/Privacy';
import SocialSecurityModal from '../SocialSecurityCalculator/SocialSecurityCalculator';
import TermsAndCondition from '../TermsAndCondition/TermsAndCondition';
import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import Crypto from '../Crypto/Crypto';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT } // Purple and green play nicely together.
  },
  typography: { useNextVariants: true }
});

const HomePage = React.lazy(() => import('../HomePage/HomePage'));
const Philosophy = React.lazy(() => import('../Philosophy/Philosophy'));
const Security = React.lazy(() => import('../Security/Security'));
const CalculatorStatic = React.lazy(() =>
  import('../401kCalculatorStatic/CalculatorStatic')
);

ReactGA.initialize('UA-141755935-1');

const StaticPages = () => {
  const location = useLocation();
  const [signupModal, setSignupModal] = useState(false);
  const [loginModal, setLoginModal] = useState(
    location.query?.viaEmail || false
  );
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/5a57a3a2d7591465c706a90d/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }, []);

  return (
    <div>
      <LoginSignupContext.Provider
        value={{
          signupModal,
          setSignupModal,
          loginModal,
          setLoginModal,
          idx,
          setIdx
        }}
      >
        <Suspense
          fallback={
            <div
              style={{
                width: '99vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <MuiThemeProvider theme={theme}>
                <CircularProgress size={50} />
              </MuiThemeProvider>
            </div>
          }
        >
          <NavBar />
          <div>
            <Switch>
              <Route exact path='/' component={HomePage} />
              <Route exact path='/philosophy' component={Philosophy} />
              <Route exact path='/algorithm' component={Algorithm} />
              <Route exact path='/security' component={Security} />
              <Route exact path='/faq' component={Faqs} />
              <Route exact path='/employer' component={StaticEmployer} />
              <Route
                exact
                path='/401kCalculator'
                component={CalculatorStatic}
              />
              <Route
                exact
                path='/termsandcondition'
                component={TermsAndCondition}
              />
              <Route exact path='/privacy' component={Privacy} />
              <Route exact path='/crypto-stocks' component={Crypto} />
              {/* <Route path="/socialSecurity" component={SocialSecurityModal} /> */}
              {/* <Route path='/' component={Error404} /> */}
            </Switch>
          </div>
        </Suspense>
        <Footer />
      </LoginSignupContext.Provider>
    </div>
  );
};

export default withRouter(StaticPages);

import React, { useState } from "react";
import SocialSecurityModal from "../../../components/SocialSecurityCalculator/SocialSecurityCalculator";

export const SocialSecurityContext = React.createContext(null);

export default function SocialSecurityProvider(props) {
  const [showSocialSecurity, setShowSocialSecurity] = useState(false);

  const closeSocialSecurity = () => {
    setShowSocialSecurity(false);
  };

  const openSocialSecurity = () => {
    setShowSocialSecurity(true);
  };

  return (
    <SocialSecurityContext.Provider
      value={{ showSocialSecurity, closeSocialSecurity, openSocialSecurity }}
    >
      <>
        {props.children}
        {showSocialSecurity && <SocialSecurityModal />}
      </>
    </SocialSecurityContext.Provider>
  );
}

import React from "react";
import styles from "./Error404.module.css";
import Logo from "../../Assets/404.svg";
import { Link } from "react-router-dom";

const Error404 = () => {
  let auth = localStorage.getItem("jwt_token");

  return (
    <div className={auth ? styles.root : styles.root2}>
      <div className={styles.element}>
        <img className={styles.img} src={Logo} />
      </div>
      <div className={styles.element}>
        <p className={styles.header}>SORRY, PAGE NOT FOUND</p>
      </div>
      <div className={styles.element}>
        {auth ? (
          <Link to="/auth/dashboard" className={styles.button}>
            Go To Dashboard
          </Link>
        ) : (
          <Link to="/" className={styles.button}>
            Go To HomePage
          </Link>
        )}
      </div>
    </div>
  );
};

export default Error404;

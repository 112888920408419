import React, { useContext, useState } from "react";
import { CaresActContext } from "../../Reusables/VisualAndAccord/Context/CaresActContext";
import cx from "classnames";
import styles from "./CaresActModal.module.css";
import { formatCommaString, isKhaliCheck } from "@plootus/common";
import Modal from "react-modal";
import Error from "@material-ui/icons/Error";
import Close from "@material-ui/icons/Close";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";

import {
  BrowserView,
  isBrowser,
  isMobile,
  MobileView,
} from "react-device-detect";

import { BLUE_PLOOT } from "@plootus/common";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import { Helmet } from "react-helmet";

const INIT_STATE = {
  AGI: "$0",
  children: 0,
  netRebate: "0",
};

function CaresActModal(props) {
  const [activeTab, setActiveTab] = useState(1);
  const { showCaresAct, closeCaresAct } = useContext(CaresActContext);
  const [{ AGI, children, netRebate }, setItem] = useState(INIT_STATE);

  const handleAGIChange = (e) => {
    let { value } = e.target;
    let amount = value.replace(/\$|,/g, "");
    let j = 0;
    for (let i = 0; i < amount.length; i += 1) {
      if (amount[i] === "0") {
        j += 1;
      } else {
        break;
      }
    }

    let stripped = amount.slice(j);

    if (isKhaliCheck(stripped)) {
      setItem((prev) => ({ ...prev, AGI: "$0", netRebate: "0" }));
      return;
    }
    if (/^\d+$/.test(stripped)) {
      setItem((prev) => ({ ...prev, AGI: `$${formatCommaString(stripped)}` }));
    }
    calcRebate(amount, children ? children : 0);
  };

  const handleChildrenChange = (e) => {
    if (e.target.value > 100) {
      setItem((prev) => ({ ...prev, children: prev.children }));

      return;
    }
    let value = e.target.value.toString();
    let j = 0;
    for (let i = 0; i < value.length; i += 1) {
      if (value[i] === "0") {
        j += 1;
      } else {
        break;
      }
    }

    let stripped = value.slice(j);

    if (isKhaliCheck(stripped)) {
      setItem((prev) => ({ ...prev, children: 0 }));
    }
    if (/^\d+$/.test(stripped)) {
      setItem((prev) => ({ ...prev, children: stripped }));
    }
    calcRebate(AGI.replace(/\$|,/g, ""), value ? value : 0);
  };

  const calcRebate = (amount, _children, tab = activeTab) => {
    amount = parseInt(amount.replace(/\$|,/g, ""));
    _children = parseInt(_children);
    if (amount <= 0) return;
    let rebate = null,
      reduction = null,
      _netRebate = null;
    switch (tab) {
      case 1:
        // if (amount > 75000) {
        rebate = 600 * (_children + 1);
        reduction = ((amount - 75000) / 100) * 5;
        _netRebate = rebate - (reduction > 0 ? reduction : 0);
        setItem((prev) => ({
          ...prev,
          netRebate: _netRebate > 0 ? _netRebate : 0,
        }));
        // }
        return;
      case 2:
        // if (amount > 150000) {
        rebate = 600 * (_children + 2);
        reduction = ((amount - 150000) / 100) * 5;
        _netRebate = rebate - (reduction > 0 ? reduction : 0);
        setItem((prev) => ({
          ...prev,
          netRebate: _netRebate > 0 ? _netRebate : 0,
        }));
        // }
        return;
      case 3:
        // if (amount > 112500) {
        rebate = 600 * (_children + 1);
        reduction = ((amount - 112500) / 100) * 5;
        _netRebate = rebate - (reduction > 0 ? reduction : 0);
        setItem((prev) => ({
          ...prev,
          netRebate: _netRebate > 0 ? _netRebate : 0,
        }));
        // }
        return;
      default:
        return;
    }
  };

  const handleClose = () => {
    closeCaresAct();
    setItem(INIT_STATE);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        {/* <title>
          Stimulus Calculator | Calculate your cares act second stimulus check
        </title> */}
        <meta
          name="description"
          content="Calculate your cares act second stimulus check provided for corona virus relief. Based on your recent filing info."
        />
        <meta
          name="keywords"
          content="cares act, cares act calculator, calculate cares act, calculate cares act stimulus, stimulus check calculator, second stimulus check calculator"
        />
      </Helmet>
      <Modal
        isOpen={showCaresAct}
        className={styles.modal}
        footer={null}
        onCancel={handleClose}
        onRequestClose={handleClose}
      >
        <div style={{ padding: 20 }}>
          <h6 className={styles.title}>Corona Virus Stimulus Calculator</h6>
          <p className={styles.filing}>Filing status as per 2019 tax form*</p>
          <BrowserView>
            <ButtonGroup className={styles.buttonGroup}>
              <button
                onClick={() => {
                  setActiveTab(1);
                  calcRebate(AGI, children, 1);
                }}
                className={
                  activeTab === 1
                    ? cx(styles.tab, styles.activeTab, styles.left)
                    : cx(styles.tab, styles.left)
                }
                style={
                  activeTab === 1
                    ? { borderBottomWidth: 3, borderBottomColor: BLUE_PLOOT }
                    : null
                }
              >
                Single
              </button>
              <button
                onClick={() => {
                  setActiveTab(2);
                  calcRebate(AGI, children, 2);
                }}
                className={
                  activeTab === 2
                    ? cx(styles.tab, styles.activeTab)
                    : styles.tab
                }
                style={
                  activeTab === 2
                    ? { borderBottomWidth: 3, borderBottomColor: BLUE_PLOOT }
                    : null
                }
              >
                Married Filing Jointly
              </button>
              <button
                onClick={() => {
                  setActiveTab(3);
                  calcRebate(AGI, children, 3);
                }}
                className={
                  activeTab === 3
                    ? cx(styles.tab, styles.activeTab, styles.right)
                    : cx(styles.tab, styles.right)
                }
                style={
                  activeTab === 3
                    ? { borderBottomWidth: 3, borderBottomColor: BLUE_PLOOT }
                    : null
                }
              >
                Head of Household
              </button>
            </ButtonGroup>
          </BrowserView>
          <MobileView>
            <select
              className={styles.activeTab}
              style={{ height: 30, borderRadius: 5 }}
              onChange={(e) => {
                setActiveTab(parseInt(e.target.value));
                calcRebate(AGI, children, parseInt(e.target.value));
              }}
            >
              <option value={1}>Single Filer</option>
              <option value={2}>Joint Filer</option>
              <option value={3}>Head of Household Filer</option>
            </select>
          </MobileView>
          <p className={styles.toolTip}>
            *If you are married and filed seperately, choose Single
          </p>
          <div className={styles.content}>
            <div className={styles.item}>
              <p>Adjusted Gross Income</p>
              <div className={styles.inputDiv}>
                <input
                  className={styles.input}
                  onChange={handleAGIChange}
                  value={AGI}
                />
              </div>
            </div>

            <div className={styles.item}>
              <p>
                Number of Children {isMobile && <br />}
                <span>({"<"}17 years)</span>
              </p>
              <div className={styles.childDiv}>
                <ArrowLeft
                  style={{ color: BLUE_PLOOT, cursor: "pointer", fontSize: 30 }}
                  onClick={() => {
                    let _children = children > 0 ? children - 1 : 0;
                    setItem((prev) => ({
                      ...prev,
                      children: _children,
                    }));
                    calcRebate(AGI, _children);
                  }}
                />
                <input
                  className={cx(styles.input, styles.child)}
                  onChange={handleChildrenChange}
                  value={children}
                  type="number"
                />
                <ArrowRight
                  style={{ color: BLUE_PLOOT, cursor: "pointer", fontSize: 30 }}
                  onClick={() => {
                    let _children = children < 100 ? children + 1 : children;
                    setItem((prev) => ({
                      ...prev,
                      children: _children,
                    }));
                    calcRebate(AGI, _children);
                  }}
                />
              </div>
            </div>
            <div className={styles.item}>
              <p>Expected Second Stimulus Check</p>
              <div className={styles.inputDiv}>
                <input
                  value={`$${formatCommaString(netRebate)}`}
                  disabled
                  className={styles.input}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.icon}>
            <Error size={30} style={{ color: "grey" }} />
          </div>
          <p>
            The Second Stimulus check is based on the Consolidated
            Appropriations Act, 2021. This is an approximate amount of Second
            Stimulus Check based on the information you provided. The actual
            amount will be determined by the Internal Revenue Service (IRS).
          </p>
        </div>
        <div className={styles.close} onClick={closeCaresAct}>
          <Close size={25} />
        </div>
      </Modal>
    </>
  );
}

export default CaresActModal;

import React from 'react';
import styles from './DialogAlert.module.css';
import Modal from 'react-modal';
import { TEXT_BLACK_2, TEXT_BLACK_1 } from '@plootus/common';

const DialogAlert = (props) => {
  const {
    visible,

    title,
    titleStyle,
    textContent,
    textContentStyle,

    buttonLeftPress,
    buttonLeftContStyle,
    buttonLeftText,

    buttonRightPress,
    buttonRightContStyle,
    buttonRightText,
  } = props;

  return (
    <Modal
      isOpen={visible}
      className={styles.Modal}
      overlayClassName={styles.Overlay}
    >
      <div
        className={styles.header}
        style={{ ...titleStyle, color: TEXT_BLACK_1 }}
      >
        {title}
      </div>
      <div
        className={styles.textContent}
        style={{ ...textContentStyle, color: TEXT_BLACK_2 }}
      >
        {textContent}
      </div>
      <div className={styles.btnDiv}>
        {buttonLeftText && (
          <button
            className={styles.left_btn}
            onClick={buttonLeftPress}
            style={buttonLeftContStyle}
          >
            {buttonLeftText}
          </button>
        )}
        <button
          className={styles.right_btn}
          style={{
            ...buttonRightContStyle,
            marginLeft: buttonLeftText ? '25px' : '0px',
          }}
          onClick={buttonRightPress}
        >
          {buttonRightText}
        </button>
      </div>
    </Modal>
  );
};

export default DialogAlert;

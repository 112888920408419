import { GENERAL_GRAPH, getFormattedValue } from "@plootus/common";
import React from "react";
import { connect } from "react-redux";
import ReactSlider from "react-slider";
import styled from "styled-components";
import styles from "./Slider2.module.css";

const StyledSlider = styled(ReactSlider)`
  width: 95%;
  height: 25px;
  align-self: flex-start;
  justify-self: baseline;

  margin-top: 12px;
`;

const StyledThumb = styled.div`
  height: 26px;
  line-height: 22px;
  width: 26px;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  background-color: #416ce1;
  color: white;
  border: 2px solid whitesmoke;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.31);
  margin-top: -10px;

  border-radius: 50%;
  cursor: pointer;
`;

const StyledTrack = styled.div`
  height: 5px;
  borderradius: 4px;
  color: #416ce1;
  background: ${(props) =>
    props.index === 2 ? "#e5e5e5" : props.index === 1 ? "#e5e5e5" : "#416ce1"};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const getConfig = (title, min, max, type) => ({
  title,
  min,
  max,
  type,
});

const Slider2 = (props) => {
  const {
    initialValue,
    minValue,
    maxValue,
    label,
    stepValue,
    marksArray,
    displayLabel,
    sliderOptions,
    dispatch,
    slidername,
    changeHandler = null,
    setShowText = null,
    myDispatch,
    state,
    setEditMode = null,
    page = null,
    type = "after",
    WIDTH = "95%",
    socialSecurity = false,
    handleSocialSecurityChange,
    isCurrency = false,
  } = props;

  const Thumb = (props, state) => (
    <StyledThumb {...props} className={styles.thumb}>
      {displayLabel === "yes" ? state.valueNow : ""}
    </StyledThumb>
  );

  const handleSliderChange = (newValue) => {
    if (socialSecurity) {
      handleSocialSecurityChange(newValue);
      return;
    }
    if (setShowText) setShowText(true);

    if (setEditMode) setEditMode(true);

    if (changeHandler) {
      changeHandler(newValue);
    }

    // setTimeout(() => {
    //   console.log(value);
    // }, 2000);

    let updatedSliderOptions;
    if (page === "home") updatedSliderOptions = { ...sliderOptions };
    else updatedSliderOptions = { ...state.sliderOptions };
    // Switch case to check which slider is changed depending on the prop - slidername
    switch (slidername) {
      case "currentAge": {
        const updatedCurrentAge = {
          ...updatedSliderOptions[slidername],
        };
        updatedCurrentAge.value = newValue;
        updatedSliderOptions[slidername] = updatedCurrentAge;

        // Change min of retire age
        const updatedRetireAge = { ...updatedSliderOptions.retireAge };
        updatedRetireAge.config = getConfig(
          "Retirement Age",
          newValue + 1,
          100,
          "num"
        );

        if (updatedRetireAge.value < newValue) {
          updatedRetireAge.value = newValue + 1;
        }

        updatedSliderOptions.retireAge = updatedRetireAge;

        // Change min of Life Exp age
        const updatedLifeAge = { ...updatedSliderOptions.lifeExp };
        updatedLifeAge.config = getConfig(
          "Life Expectancy",
          newValue + 2,
          120,
          "num"
        );

        if (updatedLifeAge.value < newValue + 1) {
          updatedLifeAge.value = newValue + 2;
        }

        updatedSliderOptions.lifeExp = updatedLifeAge;

        if (page === "home") {
          dispatch({
            type: GENERAL_GRAPH,
            payload: { sliderOptions: updatedSliderOptions },
          });
        } else {
          myDispatch({
            type: "GENERAL_SLIDER",
            payload: updatedSliderOptions,
          });
        }

        break;
      }

      // Case for retireAge -

      case "retireAge": {
        const updatedRetireAge = { ...updatedSliderOptions[slidername] };
        updatedRetireAge.value = newValue;
        updatedSliderOptions[slidername] = updatedRetireAge;

        // Change min of Life Exp age
        const updatedLifeAge = { ...updatedSliderOptions.lifeExp };
        updatedLifeAge.config = getConfig(
          "Life Expectancy",
          newValue + 1,
          120,
          "num"
        );

        if (updatedLifeAge.value <= newValue) {
          updatedLifeAge.value = newValue + 1;
        }

        updatedSliderOptions.lifeExp = updatedLifeAge;

        if (page === "home") {
          dispatch({
            type: GENERAL_GRAPH,
            payload: { sliderOptions: updatedSliderOptions },
          });
        } else {
          myDispatch({
            type: "GENERAL_SLIDER",
            payload: updatedSliderOptions,
          });
        }

        break;
      }

      // Case for strategyIndex -

      case "strategyIndex": {
        myDispatch({
          type: "STRAT_INDEX",
          payload: newValue,
        });
        break;
      }

      // Default case - In this all other cases are considered

      default: {
        const updatedSliderKey = { ...updatedSliderOptions[slidername] };
        updatedSliderKey.value = newValue;

        updatedSliderOptions[slidername] = updatedSliderKey;

        // console.log(updatedSliderOptions, newValue);

        if (page === "home") {
          dispatch({
            type: GENERAL_GRAPH,
            payload: { sliderOptions: updatedSliderOptions },
          });
        } else {
          myDispatch({
            type: "GENERAL_SLIDER",
            payload: updatedSliderOptions,
          });
        }
      }
    }
  };

  return (
    <div className={styles.root} style={{ width: WIDTH }}>
      <div gutterBottom align="left" className={styles.label}>
        {label}
        {marksArray ? null : (
          <span className={`${styles.labelSpacing} ${styles.tp}`}>
            {displayLabel === "no"
              ? getFormattedValue(initialValue, "money")
              : ""}
          </span>
        )}
        {socialSecurity && (
          <span className={`${styles.labelSpacing} ${styles.tp}`}>
            {isCurrency
              ? getFormattedValue(initialValue, "money")
              : initialValue}
          </span>
        )}
      </div>
      <StyledSlider
        className={styles.main}
        thumbClassName="example-thumb"
        trackClassName="example-track"
        renderThumb={Thumb}
        renderTrack={Track}
        value={initialValue}
        onChange={type === "on" ? handleSliderChange : null}
        onAfterChange={type === "after" ? handleSliderChange : null}
        //getAriaValueText={valuetext}
        aria-labelledby="input-slider"
        // auto , on , off
        valueLabelDisplay={displayLabel}
        marks={marksArray}
        step={stepValue}
        min={minValue}
        max={maxValue}
      />
    </div>
  );
};

export default connect(({ graphReducer }) => ({
  sliderOptions: graphReducer.sliderOptions,
  strategyIndex: graphReducer.strategyIndex,
}))(Slider2);

import React from 'react';
import { useSelector } from 'react-redux';
import Advisor from './Advisor';
import Client from './Client';

const AdvisorOrClient = () => {
  const userType = useSelector((state) => state.userReducer.userData);
  const isAdvisor = userType?.userType === 'advisor';
  return <div>{!isAdvisor ? <Advisor /> : <Client />}</div>;
};

export default AdvisorOrClient;

import React, { useState, useRef } from "react";
import EmailEditor from "react-email-editor";
import { Modal, Spin, Button, Typography, List, Radio } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { TextField } from "@material-ui/core";

import defaultDesign from "./defaultDesign.json";
import styles from "./EmailUsers.module.css";
import AdminNav from "./AdminNav";
import {
  testEmailApi,
  fetchDesign,
  saveDesign,
  updateDesign,
  emailUsers,
} from "./api";
import EmailUsersModal from "./EmailUsersModal";

import "react-toastify/dist/ReactToastify.css";

function EmailUsers(props) {
  const [showTestEmailModal, setShowTestEmailModal] = useState(false);
  const [showLoadDesignModal, setShowLoadDesignModal] = useState(false);
  const [showSaveDesignModal, setShowSaveDesignModal] = useState(false);
  const [showEmailUsersModal, setShowEmailUsersModal] = useState(false);

  const [testEmail, setTestEmail] = useState({
    subject: "",
    emails: "",
    design: {
      id: null,
      tag: null,
    },
  });

  const [currentDesign, setCurrentDesign] = useState({
    id: null,
    design: defaultDesign,
    name: "",
    saved: false,
  });

  const [availableDesign, setAvailableDesign] = useState([]);

  const emailEditorRef = useRef(null);

  const handleEmailUsers = async ({
    all,
    partial,
    users,
    ein,
    subject,
    tag,
  }) => {
    setShowEmailUsersModal(false);
    const response = await emailUsers({
      all,
      partial,
      users,
      ein,
      subject,
      tag,
    });
    if (response) {
      toast.success("Email sent to the users!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Some error occurred. Please try again!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSaveDesign = async () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const response = await saveDesign(data, currentDesign.name);
      if (response) {
        toast.success("Design saved successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCurrentDesign((prev) => ({
          ...prev,
          saved: true,
          id: response.data.id,
        }));
      } else {
        toast.error("Some error occurred. Please try again!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setShowSaveDesignModal(false);
    });
  };

  const handleTestEmailOk = async () => {
    if (
      testEmail.subject.length === 0 ||
      testEmail.emails.length === 0 ||
      testEmail.design === null
    ) {
      toast.error("Fill all fields", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const emails = testEmail.emails.split(",");
    const response = await testEmailApi({
      emails,
      tag: testEmail.design.tag,
      subject: testEmail.subject,
    });
    if (response) {
      toast.success("Test Email Sent", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Some error occurred. Please try again!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setShowTestEmailModal(false);
  };

  const handleLoadDesignModal = async (flag) => {
    if (flag) setShowLoadDesignModal(true);
    try {
      const design = await fetchDesign();
      if (design) setAvailableDesign(design.reverse());
    } catch (err) {
      console.log(err);
    }
  };

  const handleLoad = () => {
    if (currentDesign !== null) {
      setTimeout(() => {
        emailEditorRef.current.editor.loadDesign(currentDesign.design);
      }, 500);
    }
  };

  const handleUpdateDesign = async () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const response = await updateDesign({
        id: currentDesign.id,
        design: data.design,
        name: currentDesign.name,
        html: data.html,
      });
      if (response) {
        toast.success("Design updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Some error saving the design. Please try again!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setShowSaveDesignModal(false);
    });
  };

  const handleSelectDesign = (design) => {
    const JSONDesign = JSON.parse(design.data);
    setCurrentDesign({
      id: design.id,
      design: JSONDesign,
      name: design.tag,
      saved: true,
    });
    emailEditorRef.current.editor.loadDesign(JSONDesign);
    setShowLoadDesignModal(false);
  };

  return (
    <>
      <AdminNav
        {...props}
        isSaved={currentDesign.saved}
        handleLoadDesignModal={handleLoadDesignModal}
        handleEmailUsers={() => setShowEmailUsersModal(true)}
        handleTestEmail={() => {
          handleLoadDesignModal(false);
          setShowTestEmailModal(true);
        }}
        handleSaveDesign={() => {
          currentDesign.saved
            ? handleUpdateDesign()
            : setShowSaveDesignModal(true);
        }}
      />
      <div className={styles.emailEditorDiv}>
        <EmailEditor
          onLoad={handleLoad}
          className={styles.emailEditor}
          ref={emailEditorRef}
          minHeight={window.innerHeight - 50}
        />
        <ToastContainer />
        <Modal
          visible={showTestEmailModal}
          title="Send Test Email"
          okText="Send Email"
          onOk={handleTestEmailOk}
          onCancel={() => setShowTestEmailModal(false)}
        >
          <TextField
            label="Subject"
            placeholder="Enter email subject"
            onChange={(e) => {
              e.persist();
              setTestEmail((prev) => ({ ...prev, subject: e.target.value }));
            }}
            fullWidth
            className={styles.input}
            style={{ margin: 10 }}
          />
          <TextField
            placeholder="Email of the recipients seperated by comma"
            fullWidth
            onChange={(e) => {
              e.persist();
              setTestEmail((prev) => ({ ...prev, emails: e.target.value }));
            }}
            style={{ margin: 10 }}
          />
          {availableDesign.length === 0 ? (
            <div className={styles.spin}>
              <Spin />
            </div>
          ) : (
            <List
              style={{ margin: 10 }}
              header={null}
              className={styles.list}
              footer={null}
              dataSource={availableDesign}
              locale={{ emptyText: "No Designs Available" }}
              renderItem={(design) => {
                return (
                  <List.Item>
                    <Typography.Text>
                      {design.tag +
                        " (Last Updated : " +
                        design.updatedAt.split("T")[0] +
                        " " +
                        design.updatedAt.split("T")[1].substring(0, 4) +
                        ")"}
                    </Typography.Text>
                    <Radio
                      checked={design.id === testEmail.design.id}
                      onChange={() =>
                        setTestEmail((prev) => ({ ...prev, design: design }))
                      }
                    >
                      SELECT
                    </Radio>
                  </List.Item>
                );
              }}
            />
          )}
        </Modal>
        <Modal
          visible={showSaveDesignModal}
          title="Enter a name or keyword for this design"
          onCancel={() => setShowSaveDesignModal(false)}
          okText={!currentDesign.saved ? "Save" : "Update"}
          onOk={() => handleSaveDesign()}
        >
          <TextField
            label="Name"
            placeholder="Enter a unique name (Don't enter already saved names)"
            fullWidth
            value={currentDesign.name}
            onChange={(e) => {
              e.persist();
              setCurrentDesign((prev) => ({
                ...prev,
                name: e.target.value,
              }));
            }}
          />
        </Modal>
        <Modal
          visible={showLoadDesignModal}
          title="Previously saved designs"
          footer={null}
          onCancel={() => setShowLoadDesignModal(false)}
          className={styles.modal}
        >
          {availableDesign.length === 0 ? (
            <div className={styles.spin}>
              <Spin />
            </div>
          ) : (
            <List
              header={null}
              className={styles.list}
              footer={null}
              dataSource={availableDesign}
              locale={{ emptyText: "No Designs Available" }}
              renderItem={(design) => {
                return (
                  <List.Item>
                    <Typography.Text>
                      {design.tag +
                        " (Last Updated : " +
                        design.updatedAt.split("T")[0] +
                        " " +
                        design.updatedAt.split("T")[1].substring(0, 4) +
                        ")"}
                    </Typography.Text>
                    <Button onClick={() => handleSelectDesign(design)}>
                      USE
                    </Button>
                  </List.Item>
                );
              }}
            />
          )}
        </Modal>
        {showEmailUsersModal && (
          <EmailUsersModal
            setShowEmailUsersModal={setShowEmailUsersModal}
            handleEmailUsers={handleEmailUsers}
            availableDesign={availableDesign}
            handleLoadDesignModal={handleLoadDesignModal}
          />
        )}
      </div>
    </>
  );
}

export default EmailUsers;

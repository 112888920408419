import React from "react";
import { Route, Redirect } from "react-router-dom";

const AdminRoute = ({ Component, ...rest }) => {
  const token = localStorage.getItem("admin_token");

  return (
    <Route
      {...rest}
      render={(props) =>
        token !== null ? (
          <Component {...props} adminToken={token} />
        ) : (
          <Redirect to="/admin" />
        )
      }
    />
  );
};

export default AdminRoute;

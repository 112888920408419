import React, { useState, useEffect } from 'react';
import styles from './NotLinked.module.css';
import NetWorth from '../Graphs/NotLinkedGraphs/NetWorth/NetWorth';
import Cashflow from '../Graphs/NotLinkedGraphs/Cashflow/Cashflow';
import Graph from '../../HomePage/EditAssumptions/Graphs/AssetsGraph';
import GapGraph from '../../HomePage/EditAssumptions/Graphs/GapGraph';
import EditAssumptions from '../../EditAssumptions/EditAssumptions';
import { Card } from '@material-ui/core';
import useWindowDimensions from '../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import SmallScreen from '../../HomePage/EditAssumptions/SmallScreen';
import RetirementStatus from '../../HomePage/EditAssumptions/RetirementStatus/RetirementStatus';
import { useSelector } from 'react-redux';
import FeesGraph from '../Graphs/FeesGraph';
import Picker from '../../../Utils/Containers/Picker/Picker';
import DashboardTable from '../Table/DashboardTable';
import {
  isAdvisorSelector,
  currentClientSelector,
  usePickerModal,
  strategy_constants,
  employerNewDataSelector,
} from '@plootus/common';
import { Link } from 'react-router-dom';

const NotLinked = () => {
  const { height, width } = useWindowDimensions();

  const user = useSelector((state) => state.userReducer);

  const [editMode, setEditMode] = useState(false);
  const graph = useSelector((state) => state.graphReducer);

  const [modalIsOpen, setModalOpen] = useState(false);

  const state = useSelector((state) => state);
  const User = isAdvisorSelector(state)
    ? currentClientSelector(state)
    : user.userData;
  const { employerEin } = User;

  const [strategy, setStrategy] = useState(User.strategy);
  useEffect(() => {
    setStrategy(User.strategy);
  }, [User.strategy]);

  const { picker, negativeModal, setIndex } = usePickerModal(
    (idx) => {
      setStrategy(idx);
    },
    strategy,
    true
  );
  const { funds: newFunds } = useSelector(employerNewDataSelector);
  const [amodalIsOpen, setAModal] = useState(false);

  const amodalOpen = () => {
    setAModal(true);
  };
  const amodalClose = () => {
    setAModal(true);
  };
  return (
    <div className={styles.root}>
      <div className={styles.row1}>
        <div className={styles.feesSaved}>
          <FeesGraph coming={false} strategy={strategy} />
        </div>
        <div className={styles.employerTable}>
          <div
            className={styles.picker}
            style={
              !employerEin && !newFunds.length
                ? { padding: '10px 0px 0px 20px', fontSize: '20px' }
                : {}
            }
          >
            {employerEin || newFunds.length ? (
              <>
                <div className={styles.tp}>Select A Strategy</div>
                <div className={styles.tp}>
                  <Picker
                    rows={strategy_constants}
                    {...picker}
                    handlePress={negativeModal}
                    setIndex={setIndex}
                    myStyle={{ width: '100%', padding: '0px', margin: '0px' }} // style for the main conatiner of picker
                    myStyle2={{
                      width: '100%',
                      margin: '0px',
                      fontSize: '13px',
                    }} // style for the actual select box
                    myStyle3={{ width: '100%' }}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className={styles.mainTable}>
            <div className={styles.table}>
              <DashboardTable
                strategy={strategy}
                setStrategy={setStrategy}
                setIndex={setIndex}
                {...picker}
                handlePress={negativeModal}
              />
            </div>
            {!employerEin && newFunds.length ? (
              <div className={styles.seeMoreCont}>
                <Link className={styles.seeMore} to="/auth/401k">
                  See More {'>'}
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {width <= 1280 ? (
        <div className={styles.small}>
          <SmallScreen myStyle={{ height: width > 576 ? '350px' : '260px' }} />
        </div>
      ) : (
        <>
          <div className={styles.graphCont}>
            <div className={styles.title}>
              401k Calculator{' '}
              {graph.graph.results.retirementGap < 0 ? (
                <RetirementStatus
                  dashy={true}
                  status={false}
                  modalIsOpen={amodalIsOpen}
                  closeModal={amodalClose}
                  aopenModal={amodalOpen}
                  setModalOpen={setAModal}
                />
              ) : (
                <div>
                  <img src="/check.svg" alt="" className={styles.exclaim} />
                </div>
              )}
            </div>
            <Card
              style={{
                boxShadow: '9px 7px 15px rgba(0,0,0,.05)',
                border: '2px solid #ededed',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
              }}
            >
              <div className={styles.upper}>
                <div className={styles.graph1}>
                  <Graph
                    myGraph={{ padding: '0px' }}
                    myStyle={{ height: '380px' }}
                  />
                </div>
                <div className={styles.graph2}>
                  <GapGraph
                    myGraph={{ padding: '0px' }}
                    myStyle={{ height: '380px' }}
                  />
                </div>
              </div>

              <div className={styles.bottomText}>
                <RetirementStatus
                  status={graph.graph.results.retirementGap > 0}
                  modalIsOpen={modalIsOpen}
                  setModalOpen={setModalOpen}
                />
              </div>
            </Card>
          </div>
          <div className={styles.slider}>
            <Card
              style={{
                height: '100%',
                width: '100%',
                borderRadius: '10px',
                boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
              }}
            >
              <EditAssumptions editMode={editMode} setEditMode={setEditMode} />
            </Card>
          </div>
        </>
      )}
      <div className={styles.khaliGraph1}>
        <NetWorth />
      </div>
      <div className={styles.khaliGraph2}>
        <Cashflow />
      </div>
    </div>
  );
};

export default NotLinked;

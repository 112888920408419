import React from "react";
import styles from "./MyText.module.css";

const MyText = ({ children, style, onPress }) => {
  return (
    <div onClick={onPress} style={{ ...style }} className={styles.err}>
      {children}
    </div>
  );
};

export default MyText;

import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { GENERAL_GRAPH, getCurrentAge } from "@plootus/common";
import { useSelector, useDispatch } from "react-redux";
import CustomSlider from "../../../Utils/Slider/CustomSlider";
import styles from "./EditSlider.module.css";
import InputBox from "../../../Utils/InputBox/InputBox";
import useWindowDimensions from "../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import Slider2 from "../../../Utils/Slider2/Slider2";

const EditSlider = ({ setShowText, closeModal }) => {
  const dispatch = useDispatch();

  const graph = useSelector((state) => state.graphReducer);

  const { sliderOptions, strategyIndex } = graph;

  const marksArray = [
    { value: 0, label: "Super  Conservative" },
    { value: 1, label: "Conservative" },
    { value: 2, label: "Moderate" },
    { value: 3, label: "Growth" },
    { value: 4, label: "Super Growth" },
  ];

  const [selected, setSelected] = useState(0);

  const { height, width } = useWindowDimensions();

  let age = getCurrentAge("1991-01-01");
  // useEffect(() => {
  //   age = getCurrentAge("1991-01-01");
  // }, []);

  const handleChange = (val) => {
    dispatch({
      type: GENERAL_GRAPH,
      payload: { openingBalance: val },
    });
  };

  const myStyle = {
    maxHeight: "605px",
    boxShadow: "9px 7px 15px rgba(0,0,0,.05)",
    border: "2px solid #ededed",
    borderRadius: "10px",
    width: "100%",
  };

  return (
    <div className={styles.edit}>
      <div
        style={width >= 868 ? myStyle : null}
        className={width > 768 ? styles.editCard : null}
      >
        <div className={styles.innerSlider}>
          <div className={styles.header}>
            <h5>Edit Assumptions</h5>
            <img
              className={styles.crossBtn}
              src="/cross-thin.png"
              onClick={closeModal}
            />
          </div>
          <div className={styles.inputDiv}>
            <InputBox
              value={graph?.openingBalance}
              setShowText={setShowText}
              handleChange={handleChange}
            />
          </div>
          <Slider2
            initialValue={sliderOptions ? sliderOptions.currentAge?.value : 20}
            label="Current Age (Years)"
            displayLabel="yes"
            slidername="currentAge"
            setShowText={setShowText}
            page="home"
            minValue={13}
            maxValue={100}
          />
          {/* <CustomSlider
            //flag={flag}
            initialValue={sliderOptions ? sliderOptions.currentAge?.value : 20}
            label="Current Age (Years)"
            displayLabel="yes"
            slidername="currentAge"
            setShowText={setShowText}
            page="home"
          /> */}

          <Slider2
            initialValue={sliderOptions ? sliderOptions.retireAge?.value : 65}
            label="Retirement Age (Years)"
            displayLabel="yes"
            slidername="retireAge"
            minValue={sliderOptions ? sliderOptions.currentAge?.value + 1 : age}
            maxValue={100}
            setShowText={setShowText}
            page="home"
          />
          <Slider2
            initialValue={sliderOptions ? sliderOptions.lifeExp?.value : 85}
            label="Life Expectancy (Years)"
            displayLabel="yes"
            slidername="lifeExp"
            minValue={sliderOptions ? sliderOptions.retireAge?.value + 1 : age}
            maxValue={120}
            setShowText={setShowText}
            page="home"
          />
        </div>
        <div className={styles.innerSlider}>
          <Slider2
            initialValue={sliderOptions ? sliderOptions.contribution?.value : 5}
            label="Annual Contribution ($)"
            maxValue={50000}
            displayLabel="no"
            slidername="contribution"
            unit="$"
            setShowText={setShowText}
            page="home"
            type="on"
          />
          <div className={styles.stratDiv}>
            <p
              style={{
                marginLeft: width > 768 ? "25px" : "0px",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
              }}
            >
              Strategy
            </p>
            <div className={styles.btnDiv}>
              {marksArray.map((item) => (
                <button
                  onClick={() => {
                    setShowText(true);
                    setSelected(item.value);
                    dispatch({
                      type: GENERAL_GRAPH,
                      payload: { strategyIndex: item.value },
                    });
                  }}
                  key={item.value}
                  className={
                    selected === item.value
                      ? styles.selectedBtn
                      : styles.editButton
                  }
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>

          <Slider2
            initialValue={sliderOptions ? sliderOptions.expenses?.value : 50500}
            label="Annual Expenses (After Retirement)"
            stepValue={1}
            minValue={0}
            maxValue={250000}
            displayLabel="no"
            slidername="expenses"
            unit="$"
            setShowText={setShowText}
            page="home"
            type="on"
          />
        </div>
      </div>
    </div>
  );
};

export default EditSlider;

import React from "react";
import Modal from "react-modal";
import check from "../../../Assets/logo.svg";
import styles from "./TermsConditionsModal.module.css";

const TermsConditionsModal = ({ isOpen, setIsOpen, onSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      className={styles.Modal}
      overlayClassName={styles.Overlay}
    >
      <div className={styles.container}>
        <div className={styles.image}>
          <img src={check} className={styles.check} />
        </div>
        <div className={styles.content}>
          By creating an account, you agree to our{" "}
          <a href="http://localhost:3000 /termsandcondition" target="_blank">
            Terms of Conditions
          </a>{" "}
          and{" "}
          <a href="http://localhost:3000 /privacy" target="_blank">
            Privacy Policy
          </a>
        </div>
        <div className={styles.buttonCont}>
          <button className={styles.cancel} onClick={() => setIsOpen(false)}>
            Cancel
          </button>
          <button className={styles.confirm} onClick={onSubmit}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TermsConditionsModal;

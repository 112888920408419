import React, { useContext, useRef, useState } from "react";
import cx from "classnames";
import styles from "./SocialSecurity.module.css";
import Modal from "react-modal";
import Close from "@material-ui/icons/Close";
import useDateValidation from "../../Reusables/VisualAndAccord/Hooks/useDateValidation";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  async_func_data,
  BLUE_PLOOT,
  formatCommaString,
} from "@plootus/common";
import { SocialSecurityContext } from "../../Reusables/VisualAndAccord/Context/SocialSecurityContext";
import Slider2 from "../../Utils/Slider2/Slider2";
import DobInput from "../../Utils/DobInput/DobInput";
import Axios from "axios";

function SocialSecurityModal(props) {
  const [{ age, salary }, setState] = useState({
    age: 65,
    salary: 10000,
  });
  const [loading, setLoading] = useState(false);
  const [benefit, setBenefit] = useState("-");
  const { showSocialSecurity, closeSocialSecurity } = useContext(
    SocialSecurityContext
  );

  const outputDivRef = useRef(null);

  const { minDate, maxDate } = useDateValidation();
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("1993-01-01")
  );
  const [dob, setDob] = useState("1993-01-01");

  // function convert(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  // }

  const handleDateChange = (date) => {
    setSelectedDate(new Date(date));
    setDob(moment(date).format("YYYY-MM-DD"));
  };

  const handleClose = () => {
    closeSocialSecurity();
  };

  const handleCalculate = async () => {
    setLoading(true);
    outputDivRef.current.scrollIntoView({ behavior: "smooth" });
    const response = await Axios.post(
      "https://www.plootus.com/api/transaction/getSocialSecurity",
      {
        mean_wages: salary,
        retire_age: age,
        dob: dob,
      }
    );
    setLoading(false);
    if (response.status === 200) {
      const { data } = response;
      setBenefit(data.benefits > 0 ? data.benefits.toFixed(2) : 0);
    }
  };

  return (
    <>
      <Modal
        isOpen={showSocialSecurity}
        className={styles.modal}
        footer={null}
        onRequestClose={handleClose}
      >
        <div style={loading ? { animation: "scroll" } : null}>
          <div
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              padding: 15,
              marginLeft: -15,
              marginRight: -15,
            }}
          >
            <div className={styles.close} onClick={handleClose}>
              <Close size={25} color="grey" />
            </div>
            <p className={styles.header}>Social Security Calculator</p>
          </div>
          <div>
            <p style={{ fontWeight: 500, fontSize: 17 }}>Input</p>
            <div className={styles.inputDiv}>
              <Slider2
                label={
                  <p style={{ fontWeight: 500, fontSize: "15px" }}>
                    Expected Retirement Age (Years)
                  </p>
                }
                step={1}
                maxValue={75}
                minValue={30}
                socialSecurity={true}
                handleSocialSecurityChange={(value) =>
                  setState((prev) => ({ ...prev, age: value }))
                }
                initialValue={age}
                displayLabel="no"
                marksArray
                type="on"
              />
              <Slider2
                label={
                  <p style={{ fontWeight: 500, fontSize: "15px" }}>
                    Average salary of the last 15 years
                  </p>
                }
                step={1000}
                maxValue={50000}
                minValue={1000}
                socialSecurity={true}
                handleSocialSecurityChange={(value) =>
                  setState((prev) => ({ ...prev, salary: value }))
                }
                initialValue={salary}
                displayLabel="no"
                marksArray
                isCurrency={true}
                type="on"
              />
              <div style={{ marginBottom: -20 }}>
                <DobInput
                  dob={dob}
                  handleDateChange={handleDateChange}
                  maxDate={maxDate}
                  minDate={minDate}
                  selectedDate={selectedDate}
                  setDob={setDob}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 20,
                }}
              >
                <button className={styles.button} onClick={handleCalculate}>
                  Calculate Social Security Benefit
                </button>
              </div>
            </div>
            <p style={{ marginTop: 20, fontWeight: "500", fontSize: 17 }}>
              Output
            </p>
            <div className={styles.outputDiv} ref={outputDivRef}>
              <p style={{ fontWeight: "500" }}>
                Estimated Social Security Benefit is
              </p>
              <div className={styles.benefitDiv}>
                {!loading ? (
                  <p>{`$${formatCommaString(benefit)}`}</p>
                ) : (
                  <CircularProgress size={20} style={{ margin: 10 }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SocialSecurityModal;

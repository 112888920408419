import React, { useContext } from 'react';
import MainSignupScreen from '../SignUp/MainScreen';
import styles from './MainModal.module.css';
import LogIn from '../LogIn/LogIn';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { generalUserAction } from '@plootus/common';
import { useDispatch } from 'react-redux';
import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';

const MainModal = () => {
  const {
    signupModal,
    setSignupModal,
    loginModal,
    setLoginModal,
    idx,
    setIdx,
  } = useContext(LoginSignupContext);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      generalUserAction({
        viaEmail: location.query?.viaEmail,
        userAdvisorId: location.query?.userAdvisorId,
      })
    );
  }, []);

  const signupopenModal = () => {
    setSignupModal(true);
  };

  const signupcloseModal = () => {
    setSignupModal(false);
  };
  const loginopenModal = () => {
    setLoginModal(true);
  };

  const logincloseModal = () => {
    setLoginModal(false);
  };

  return (
    <div className={styles.modalBtns}>
      <div className={styles.LogInBtn}>
        <LogIn
          modalIsOpen={loginModal}
          openModal={loginopenModal}
          signupopenModal={signupopenModal}
          closeModal={logincloseModal}
          idx={idx}
          setIdx={setIdx}
        />
      </div>
      <div className={styles.SignupBtn}>
        <MainSignupScreen
          modalIsOpen={signupModal}
          openModal={signupopenModal}
          closeModal={signupcloseModal}
          loginopenModal={loginopenModal}
        />
      </div>
    </div>
  );
};

export default MainModal;

import { employerNewDataSelector } from "@plootus/common";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import styles from "./FundsTable.module.css";
import TableFooter from "./TableFooter/TableFooter";
import TableHeader from "./TableHeader/TableHeader";
import TableRow, { getStratData } from "./TableRow";

export const get_name = (strategy) => {
  switch (strategy) {
    case 0:
      return "proposed_5";
    case 1:
      return "proposed_1";
    case 2:
      return "proposed_2";
    case 3:
      return "proposed_3";
    case 4:
      return "proposed_4";

    default:
      return undefined;
  }
};

const wrapper = (strat) => {
  const strat_name = get_name(strat);
  function compare(a, b) {
    if (strat_name) {
      if (parseFloat(a[strat_name]) < parseFloat(b[strat_name])) {
        return 1;
      }
      if (parseFloat(a[strat_name]) > parseFloat(b[strat_name])) {
        return -1;
      }
      return 0;
    } else {
      console.log("Strategy is undefined");
    }
  }

  return compare;
};

const FundsTable = ({ strategy, style }) => {
  const [total, setTotal] = useState(0);
  const { funds } = useSelector(employerNewDataSelector);
  const current = false;
  const _funds = funds.sort(wrapper(strategy));

  useEffect(() => {
    if (funds) {
      let Total = 0;
      for (let i = 0; i < funds.length; i++) {
        Total += parseFloat(getStratData(strategy, funds[i]));
      }

      setTotal(Total);
    }
  }, [strategy, funds]);

  return (
    <div className={styles.container} style={style}>
      <div className={styles.headerDiv}>
        <TableHeader current={current} />
      </div>
      <div className={styles.rows}>
        <Scrollbars style={{ width: "100%", height: "100%" }}>
          {_funds.map((item, index) => {
            return (
              <TableRow
                item={item}
                index={index}
                strategy={strategy}
                current={current}
              />
            );
          })}
          <TableFooter total={Math.round(total)} current={current} />
        </Scrollbars>
      </div>
    </div>
  );
};

export default FundsTable;

import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {
  BLUE_PLOOT,
  employerNewDataSelector,
  fetch_allocations,
  generalEmployerNew
} from '@plootus/common';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import styles from './SearchBox401k.module.css';
const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT } // Purple and green play nicely together.
  },
  typography: { useNextVariants: true }
});

function SearchBox401k({
  style,
  setSignupData, // for user signup process
  setUpdateData, // for updating the profile
  style1,
  disable = false,
  dontNull,
  fromStatic,
  myStyles,

  ...rest
}) {
  const { width } = useWindowDimensions();
  const [route, setRoute] = useState(false);

  const history = useHistory();

  const {
    setShowList,
    value,
    showList,
    valIsEmpty,
    data,
    loading,
    setLocalData
  } = rest;

  const dispatch = useDispatch();
  const employerRedux = useSelector(employerNewDataSelector);

  const { employer_selected } = employerRedux;

  const myL = useRef('/');

  myL.current = useLocation().pathname;

  // if (!dontNull && myL.current === '/auth/2401k') return null;

  return (
    <div className={styles.searchWrapper} style={style1}>
      <div
        className={styles.inputWithIcon}
        style={{
          ...(showList
            ? {
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                borderBottom: '0px'
              }
            : {}),
          ...style
        }}
      >
        <div className={styles.searchCont}>
          <SearchIcon
            style={{
              fontSize: width > 576 ? '25px' : '20px',
              ...myStyles?.icon
            }}
            className={styles.inputIcon}
          />
        </div>
        <div className={styles.inputCont}>
          <input
            value={dontNull ? employer_selected || value : value}
            type='text'
            disabled={disable}
            placeholder="Search your Employer's 401k Plan"
            onChange={(e) => {
              if (employer_selected) {
                dispatch(
                  generalEmployerNew({
                    funds: [],
                    employer_selected: ''
                  })
                );
              }

              setLocalData({
                value: e.target.value,
                showList: true
              });
            }}
            className={styles.inputField}
            onBlur={() => setShowList(false)}
            style={{ ...style, ...myStyles?.inputStyle }}
          />
        </div>
      </div>
      <div className={showList ? styles.dropDownDiv : styles.hideDiv}>
        <ul className={styles.list}>
          {loading ? (
            <div className={styles.loader}>
              <MuiThemeProvider theme={theme}>
                <CircularProgress size={30} />
              </MuiThemeProvider>
            </div>
          ) : data && data.length ? (
            data.map((item, index) => {
              return (
                <li
                  onMouseDown={async () => {
                    if (setUpdateData) {
                      setUpdateData((prev) => ({
                        ...prev,
                        employerEin: { ...prev.employerEin, value: item.ein },
                        sponsor_name: {
                          ...prev.sponsor_name,
                          value: item.sponsor_name.split(' (EIN')[0]
                        }
                      }));

                      setLocalData((prev) => ({
                        ...prev,
                        value: item.sponsor_name.split(' (EIN')[0],
                        ein: item.ein
                      }));
                    } else if (setSignupData) {
                      setSignupData((prev) => ({
                        ...prev,
                        employerEin: item.ein,
                        sponser_name: item.sponsor_name.split(' (EIN')[0]
                      }));

                      setLocalData((prev) => ({
                        ...prev,
                        value: item.sponsor_name.split(' (EIN')[0],

                        ein: item.ein
                      }));
                    } else {
                      await dispatch(
                        fetch_allocations(
                          {
                            ein: item.ein,
                            name: item.sponsor_name.split(' (EIN')[0],
                            showLimitExceedError: true
                          },
                          true
                        )
                      );
                      localStorage.setItem(
                        'empData',
                        JSON.stringify({
                          ein: item.ein,
                          name: item.sponsor_name.split(' (EIN')[0]
                        })
                      );
                      setLocalData((prev) => ({ ...prev, value: '' }));
                      if (!dontNull) {
                        if (fromStatic) history.push('/employer');
                        // else history.push('/auth/401k');
                      }
                    }

                    //setRoute(true);
                  }}
                  className={styles.listItem}
                  style={
                    index === data.length - 1 ? { borderBottom: '0px' } : {}
                  }
                >
                  <div className={styles.innerDiv} style={myStyles?.innerDiv}>
                    {item.sponsor_name}
                  </div>
                </li>
              );
            })
          ) : !valIsEmpty &&
            !data?.length &&
            !loading &&
            employer_selected === '' ? (
            <div className={styles.noMatchFound}>No Match Found</div>
          ) : null}
        </ul>
      </div>
    </div>
  );
}

export default SearchBox401k;

import React from 'react';
import TimePeroidNewData from './TimePeriodNewData/TimePeriodNewData';

const VisualData = ({
  api_name,
  screen,
  title,
  type,
  _key,
  _key_trasaction,

  dispatchAction,
  dispatchTransaction,
  actionType,
  _key_accord,
  ...rest
}) => {
  return (
    <TimePeroidNewData
      {...rest}
      visualAction={dispatchAction}
      api_name={api_name}
      screen={screen}
      title={title}
      type={type}
      _key={_key}
      actionType={actionType}
      actionToDispatch={dispatchAction}
      dispatchTransaction={dispatchTransaction}
    />
  );
};

export default VisualData;

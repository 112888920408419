import React from "react";
import { useEffect } from "react";
import {
  BLUE_PLOOT,
  currentUserDataSelector,
  employerNewDataSelector,
} from "@plootus/common";
import { useSelector } from "react-redux";
import styles from "./DashboardTable.module.css";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { get_name } from "../../Employer/FundsTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import TableFooter from "./TableFooter";
import { Scrollbars } from "react-custom-scrollbars";
import Tooltip from "../../../Utils/Tooltip/Tooltip";
import Axios from "axios";
import { useState } from "react";
import { getStratData } from "./TableRow";
import SearchBox401k from "../../../Utils/Search401kNew/SearchBox401kNew";
import useEmployerSearch from "../../../Reusables/VisualAndAccord/Hooks/useEmployerSearch";

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const DashboardTable = ({
  strategy,
  setStrategy,
  setIndex,
  index,
  handlePress,
  visible,
}) => {
  const item = {
    ein: "911986545",
    name: "AMAZON.COM SERVICES,INC.",
  };

  const [funds, setFunds] = useState(null);
  const [showFunds, setShowFunds] = useState(true);
  const state = useSelector((state) => state);
  const [total, setTotal] = useState(0);
  const { ein, sponsor_name, employerEin } = useSelector(
    currentUserDataSelector
  );
  const newEin = ein | employerEin;
  const { funds: newFunds, employer_selected } = useSelector(
    employerNewDataSelector
  );

  const props = useEmployerSearch();

  useEffect(() => {
    if (ein || employerEin) {
      setShowFunds(true);
      (async () => {
        const response = await Axios.get(
          `https://www.plootus.com/api/employee/getallocation?ein=${newEin}`
        );
        setFunds(response.data.allocations);
        setShowFunds(false);
      })();
    }
  }, [sponsor_name, newEin, newFunds, employer_selected]);

  useEffect(() => {
    if (funds || newFunds) {
      let Total = 0;
      let data = funds || newFunds;
      for (let i = 0; i < data.length; i++) {
        Total += parseFloat(getStratData(strategy, data[i]));
      }
      setTotal(Total);
    }
  }, [strategy, funds, newFunds]);

  const wrapper = (strat) => {
    const strat_name = get_name(strat);
    function compare(a, b) {
      if (strat_name) {
        if (parseFloat(a[strat_name]) < parseFloat(b[strat_name])) {
          return 1;
        }
        if (parseFloat(a[strat_name]) > parseFloat(b[strat_name])) {
          return -1;
        }
        return 0;
      } else {
        console.log("Strategy is undefined");
      }
    }

    return compare;
  };
  const _funds = funds?.sort(wrapper(strategy));
  const sortedFunds = newFunds?.sort(wrapper(strategy));

  console.log(funds);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {newEin ? (
            <div className={styles.left}>
              {sponsor_name ? sponsor_name : item.name}
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  title="Based on anticipated future expenses and assets created until life expectancy."
                  render={(props) => (
                    <img
                      style={{
                        width: "15px",
                        height: "15px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      src="/question.png"
                      alt=""
                    />
                  )}
                />
              </div>
            </div>
          ) : (
            <div className={styles.headerContainer}>
              <div className={styles.SearchHeader} style={{ display: "flex" }}>
                401k Plan
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title="Plootus patent-pending algorithm selects the best-performing investment options from employer-sponsored 401k plan based on your risk strategy and saves thousands of dollars in hidden fees."
                    render={(props) => (
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        src="/question.png"
                        alt=""
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.searchCont}>
                <SearchBox401k dontNull={true} {...props} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.tableContainer}>
          {employerEin ? (
            !showFunds ? (
              <>
                <div className={styles.headerDiv}>
                  <TableHeader
                    strategy={strategy}
                    setStrategy={setStrategy}
                    setIndex={setIndex}
                    index={index}
                    handlePress={handlePress}
                    visible={visible}
                  />
                </div>
                <Scrollbars style={{ width: "100%", height: "100%" }}>
                  <div className={styles.rowContainer}>
                    {_funds.map((item, index) => {
                      return (
                        <TableRow
                          data={item}
                          index={index}
                          strategy={strategy}
                        />
                      );
                    })}
                    <div>
                      <TableFooter total={Math.round(total)} />
                    </div>
                  </div>
                </Scrollbars>
              </>
            ) : (
              <div className={styles.loader}>
                <MuiThemeProvider theme={theme}>
                  <CircularProgress size={50} />
                </MuiThemeProvider>
              </div>
            )
          ) : newFunds.length ? (
            <>
              <div className={styles.headerDiv}>
                <TableHeader
                  strategy={strategy}
                  setStrategy={setStrategy}
                  setIndex={setIndex}
                  index={index}
                  handlePress={handlePress}
                  visible={visible}
                />
              </div>
              <Scrollbars style={{ width: "100%", height: "100%" }}>
                <div className={styles.rowContainer}>
                  {sortedFunds.map((item, index) => {
                    return (
                      <TableRow data={item} index={index} strategy={strategy} />
                    );
                  })}
                  <div>
                    <TableFooter total={Math.round(total)} />
                  </div>
                </div>
              </Scrollbars>
            </>
          ) : employer_selected ? (
            <div className={styles.msgBoxCont}>
              <div className={styles.msgBox}>
                Thanks for using our app. We have not analyzed your employer's
                401k plan but we will get right at it. Check back in a few days.
              </div>
            </div>
          ) : (
            <div className={styles.msgBoxCont}>
              <div className={styles.msgBox}>
                Plootus has analysed $279 billion assets in 401k retirement
                plans. Search your employer and we'll suggest you investment
                options based on your investment strategy
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardTable;

import React from "react";

import {
  GREEN_PLOOT,
  DANGER_RED,
  DARK_RED_PLOOT,
  DRAK_GREEN_PLOOT,
} from "@plootus/common";
import MyText from "../Texts/MyText";

const styles = {
  alert: {
    fontSize: 11.5,
    position: "absolute",
    bottom: -3,
  },
};

const ErrorBelow = ({ showError, err }) => (
  <>
    {showError ? (
      <MyText
        style={{
          ...styles.alert,
          ...{ color: err.err ? DARK_RED_PLOOT : DRAK_GREEN_PLOOT },
          padding: "0",
        }}
      >
        {err.value}
      </MyText>
    ) : null}
  </>
);

export default ErrorBelow;

import React from 'react';
import styles from './Cashflow.module.css';
import Chart from '../../../../../Utils/Charts/NotLinkedCharts/Chart';
import { Card } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ResponsiveLine } from '@nivo/line';
import useWindowDimensions from '../../../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';

const NetWorth = () => {
  const myData = [
    {
      data: [
        { x: null, y: '20k' },
        { x: null, y: '30k' },
        { x: null, y: '40k' },
        { x: null, y: '50k' },
        { x: null, y: '60k' },
      ],
    },
  ];

  const { width } = useWindowDimensions();
  return (
    <Card
      className={styles.outerClass}
      style={{
        borderRadius: '10px',
        boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <div className={styles.header}>
        <div className={styles.headTag}>Cashflow</div>
        <div className={styles.amtTag}>$ 00,000</div>
      </div>
      <ResponsiveLine
        data={myData}
        margin={{
          top: width > 576 ? 40 : 30,
          right: width > 576 ? 50 : 30,
          bottom: 80,
          left: width > 576 ? 50 : 50,
        }}
        xScale={{ type: 'linear', min: 0, max: 100 }}
        yScale={{
          type: 'linear',
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'This Month',
          legendOffset: 46,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Amount',
          legendOffset: width > 576 ? -45 : -35,
          legendPosition: 'middle',
          tickValues: 5,
        }}
        pointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        enableArea={true}
        areaOpacity={1}
      />
      <div className={styles.center}>
        To view this information, Please{' '}
        <Link to="/auth/linkaccounts">link accounts</Link>
      </div>
    </Card>
  );
};

export default NetWorth;

import React from 'react';
import Modal from 'react-modal';
import { Redirect, Link } from 'react-router-dom';
import styles from './ConfirmModal.module.css';
import Logo from '../../../Assets/logo.svg';
import Axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';

const ConfirmModal = ({
  modalIsOpen,
  closeModal,
  userAdvisorId,
  emailFrom,
  isRegistered,
  emailTo,
  advisorUId,
}) => {
  useEffect(() => {
    (async () => {
      if (isRegistered) {
        await Axios.post('https://www.plootus.com/api/advisor/updateAdvisor', {
          emailTo,
          userAdvisorId,
          advisorUId,
        });
      }
    })();
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={styles.Modal}
      overlayClassName={styles.Overlay}
    >
      <div className={styles.container}>
        <div>
          <img src={Logo} alt="Plootus" />
        </div>
        <div className={styles.actualText}>
          You’ve been successfully registered as an advisor to {emailFrom} !
        </div>
        <div className={styles.btnDiv}>
          <Link to="/" className={styles.btn}>
            Go To Login
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

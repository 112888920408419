import React from 'react';
import MyText from './MyText';
import { TEXT_BLACK_2, TEXT_BLACK_1 } from '@plootus/common';

const DataNotAvailable = ({ box }) => {
  return (
    <div
      style={{
        border: box ? '1px solid' : '0px',
        borderColor: TEXT_BLACK_2,
        padding: '20px',
        textAlign: 'center',
        borderRadius: '5px',
      }}
    >
      <MyText style={{ color: TEXT_BLACK_1, fontSize: '18px' }}>
        Data Not Available
      </MyText>
    </div>
  );
};

export default DataNotAvailable;

export const faqs = [
  {
    title: "Why should I sign up for Plootus?",
    content: `Plootus is an easy to use, a comprehensive financial planning tool that will assist
    you to plan for retirement. The product breaks down income and expenses in different
    categories and then projects total money required for retirement. The product
    suggests the investments/funds you should choose from the options available within
    your retirement plan account.`,
  },
  {
    title: "How does Plootus decide which funds I invest in?",
    content: `We analyze funds taking into considerations various return and risk factors before
    making a recommendation to you. We consider your risk profile and investment options
    available within your 401k, 403b, 457 or TSP plans to construct a diversified
    portfolio.`,
  },
  {
    title: "How does Plootus estimate future expenses?",
    content: `We calculate future expenses based on your current spending habits and adjust for
    likely major life changes, location, inflation and other factors.`,
  },
  {
    title: "How does Plootus make money?",
    content: `We are currently offering our services for free. In the future, we may charge a
    fixed amount equivalent to what you pay for a Netflix or an Amazon Prime
    subscription, much lower than the industry. 
     We are a for-profit entity but our vision is to revive the American Dream and offer
    stress- free financial planning to everyone.`,
  },
  {
    title: "What should I do after sign up?",
    content: `After signing up, you should link your bank, credit cards, brokerage, and retirement
    accounts to the app. We process the information gathered through these accounts to
    provide you a summarized view and a transaction by transaction view across all
    financial institutions in one place. We use your information for the purposes of
    estimating how much money you will need for your retirement period.`,
  },
  {
    title:
      "Is there a minimum balance required in my 401k account to use Plootus?",
    content: `No. Plootus does not require any minimum balance. It does not matter whether you
    just got your first job and are starting with a zero 401k account balance or you
    have millions of dollars. Plootus will provide you 401k recommendations and help you
    reach your retirement goal.`,
  },
  {
    title:
      "Why doesn’t a broker at a brokerage firm help me with my 401k investments?",
    content: `Mostly, brokers do not help 401k participants in choosing investments from a 401k
    plan because most brokerage firms do not allow brokers (read salespeople) to offer
    specific advice as it would tantamount to acting as a fiduciary. These firms do not
    want to open themselves up to potential liabilities that may arise from advice given
    by their employees.`,
  },
  {
    title:
      "How long does Plootus take to offer me specific recommendations for my 401k investments?",
    content: `Plootus is committed to offering advice within a week of linking your 401k account.
    Sometimes it takes longer but usually no more than 30 days in offering specific
    recommendations for your 401k investments.`,
  },
  {
    title: "Are there people or robots helping with my 401k?",
    content: `There are both people and robots helping with your 401k.
    Our Artificial intelligence tools (patent pending) use an advanced algorithm to
    determine how much a user needs for retirement and provides the recommendations best
    for you.`,
  },
  {
    title: "How often will Plootus adjust my 401k account?",
    content: `Plootus monitors the funds’/investments’ performance regularly and suggests changes
    if the allocated funds are not performing well compared to their peers within your
    401k account or if you need a different allocation based on changed circumstances
    such as expense run rate, change of location etc. Plootus recommends adjustment your
    401k when it is required.`,
  },
  {
    title: `Isn’t my 401k custodian such as Fidelity Net Benefits or ADP or
    Prudential account (where I log in to check my 401k balance) doing
    retirement planning and helping me invest and grow my 401k balance in the
    same way as Plootus does?`,
    content: `No. None of the 401k custodians such as Fidelity Net Benefits, ADP or Prudential
    give you clear advice on which investment to choose and how to invest. They provide
    some basic calculators for 401k. They merely invest your money in investment options
    you chose when you opened your 401k account or as and when you make any changes.`,
  },
  {
    title:
      "What keeps me from signing up with Plootus and then quitting after Plootus provides me 401k recommendations?",
    content: `You can quit using the service at any time. However, your 401k investments are
    similar to any other investments you make in life. Ideally, you do not make an
    investment and forget about it. You need to keep tracking and monitoring to ensure
    you are obtaining desirable results. Plootus helps you make changes or adjustments
    to your portfolio as required. As per a Vanguard study, guided rebalancing of your
    investment portfolio at regular intervals adds 1.40% additional return to your 401k
    account.
    So nothing stop you from your signing up and then quitting later after getting 401k
    recommendations.`,
  },
  {
    title: "How do I change my 401k allocation?",
    content: `Log into your 401k plan account (Fidelity Net Benefits, ADP, Prudential etc.) and
    make changes to your 401k allocation as suggested by Plootus. You should also
    consider how future contributions will be invested.`,
  },
  {
    title: "I want to change my retirement age. How can I change it?",
    content: `Once you log into the Plootus app, click Profile on the top left and change your
    retirement age.`,
  },
  {
    title:
      "Can I reach someone at Plootus to address a specific question about 401k?",
    content: `Yes, anytime. Feel free to email us with any questions related to 401k and other
    financial planning issues. We are here to help. Please email us.`,
  },
  {
    title: "Can I adjust my 401k allocations without informing Plootus?",
    content: `Yes, you can adjust your 401k allocations anytime. You are the owner of your account
    and can change 401k allocations anytime. Just log in to your 401k custodian account
    (Fidelity Net Benefits, ADP, Prudential etc.) or call your custodian to make changes
    to 401k allocation.`,
  },
  {
    title:
      "Do you review my complete financial profile including all my credit cards, banks, 401k, 529s and HSAs accounts?",
    content: `Plootus requests customers to connect their banks, credit cards, 401k, 529s, HSAs
    etc. to understand the user’s complete financial profile. Our AI algorithm analyzes
    and makes suggestions based on the information you provide and which is received
    from linked financial accounts. Although we consider the complete financial profile
    of the user, we are currently focused on making recommendations only for your 401k
    account.
    In the future, we expect to offer services around 529 college savings planning as
    well.`,
  },
  {
    title:
      "Do you analyze and recommend portfolio allocation for Health Saving Accounts (HSAs) and 529 college savings plans?",
    content: `At this time, we only analyze and make recommendations related to employer-sponsored
    retirement accounts, such as 401k, 403b, 457 and TSP accounts. In future, we will
    offer 529s services as well.`,
  },
  {
    title: "When is the Plootus team available?",
    content: `We are available during normal business hours Monday to Friday. We try to respond to
    all queries within a few hours. Feel free to drop us an email.`,
  },
  {
    title: "Why is the service provided by Plootus free?",
    content: `We want to democratize financial planning for everyone and therefore our services
    are currently offered for free. We firmly believe it should not be a privilege
    available to only a certain section of society. We are here for the 99%.`,
  },
  {
    title: "Are there any hidden fees?",
    content: `Plootus does not have any hidden fees. In fact, for a short period, all our services
    are currently being offered free of charge. However, the investments in your 401k
    account have hidden expenses and fees. We choose the lowest cost and best performing
    investments from the options available with your 401k account.`,
  },
  {
    title: "Am I entering into any long term contract with Plootus?",
    content: `No, you are not entering into any long term contract with Plootus. You are free to
    leave at any time. We will not be happy to lose you but wish you all the best for a
    rich and healthy retirement life.`,
  },
  {
    title: "Do you try to time the market?",
    content: `Plootus does not time the market. Many studies that have been conducted suggest
    timing the market is a waste of time, especially when making a long-term investment
    such as that in a 401k scenario. We construct a well-diversified portfolio that
    optimizes return for a given level of risk and rebalances as and when required.`,
  },
  {
    title:
      "How does Plootus determine 401k portfolio allocation between stocks, bonds and other asset classes?",
    content: `Plootus determines portfolio allocation between stocks, bonds and other assets based
    on past performance. Plootus algorithm is built on the past performance of various
    assets classes over decades to construct a well-diversified portfolio allocation
    model for various strategies such as conservative, moderate, growth and super
    growth. Although many factors impact your portfolio allocation. As a general rule,
    the farther you are from retirement, the more the equity allocation will be
    recommended as equity generates a better return as compared to bonds over the longer
    term.`,
  },
  {
    title:
      "Why should I use Plootus instead of investing in a target-date fund offered in my 401k account?",
    content: `Plootus first determines the user’s retirement needs and based on that creates a
    diversified portfolio. Therefore, we recommend using Plootus instead of investing in
    target-date funds.`,
  },
  {
    title: "Does Plootus manage IRAs or Roth IRAs?",
    content: `We don’t manage in IRAs or Roth IRAs currently but plan to offer similar services in
    the future.`,
  },
  {
    title:
      "I have company stock as part of my 401k account. How much of my 401k portfolio should I allocate to the company’s stock?",
    content: `This is an interesting question. One of our Co-Founders worked with GE (General
        Electric) for a number of years and had an option to invest in the company stock. As
        you may be aware, GE’s stock has done poorly in the last few years and a lot of
        employees lost a significant amount of money in their 401k account.
        Wisdom tells us “Don’t put all your eggs in one basket”. If you work for a company,
        your current income (and life) is already somewhat dependent on the company’
        performance. Associating your retirement income to the same employer would mean
        taking on additional risk.

        The bottom line is that it is not prudent to have exposure to your company stock. If
        you still want to invest (maybe because you work for a growing company with an
        apparently bright future), don’t allocate more than 15-20% of your total assets to
        the company stock.`,
  },
  {
    title:
      "My employer provides an option to invest my 401k money in a self-directed brokerage. Does Plootus help in the allocation of that portfolio?",
    content: `Plootus manages 401k assets that are outside the Self Directed Brokerage because you
    have authorized the Self Directed Brokerage team to manage your portion of your 401k
    assets. In fact, you are also not allowed to make portfolio allocation changes by
    yourself and you need to call them if you want to make any changes to your 401k
    portfolio.`,
  },
  {
    title: "How do you improve a customer’s overall return?",
    content: `We improve customer’s overall return by selecting low fees and best performing funds
    from your 401k account. Based on the analysis we conducted, Plootus helps users save
    anywhere between 0.0% - 0.30% in fees in their 401k account. It can make you richer
    by $0- $61,200 over a period of 30 years.
    Re-balancing your 401k portfolio further adds 0% -1.40% in return your 401k account.
    It can make you richer by $0-$318,000 over a period of 30 years.
    Please note investments are subject to market risk and no two users will experience
    the same performance.`,
  },
  {
    title:
      "Will Plootus assist in withdrawals out of my account for a loan or other purposes?",
    content: `No. We don’t assist in withdrawals out of your account for a loan or any other
    purpose. Please reach out to your HR team to get custodian details to make a
    withdrawal or request a loan.`,
  },
  {
    title: "Will Plootus assist with my old 401k with a previous employer?",
    content: `Absolutely. We can assist with your old 401k account with a previous employer(s) and
    a current 401k account with your current employer.`,
  },
  {
    title: "Will Plootus change the contribution amount in my 401k account?",
    content: `No. We provide a recommendation for how much to contribute and how to invest to
    reach your retirement goal. We do not make any changes to the contribution amount in
    your 401k account.`,
  },
  {
    title: "Is my data secure?",
    content: `Your data is absolutely secure. We take information security very seriously and
    therefore do not ask for personal information such as Social Security Number or
    complete address. Our app uses bank-level security to encrypt data. Read more about
    security.`,
  },
];

import React, { useState, useEffect } from 'react';
import { switchTab } from '../../components/IncomeExpenseScreen/IncomeExpTab';
import styles from './Tabs.module.css';

const Tabs = ({ data, setContent }) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setContent(selected);
  }, [selected]);

  return (
    <div className={styles.tabdiv}>
      <div className={styles.dummy}>
        <ul className={styles.tablist}>
          {data.map((dataItem, index) => {
            return (
              <div
                className={`${styles.singleItem}  ${
                  selected === dataItem.id ? styles.selectedSingleItem : null
                }`}
              >
                <span
                  onClick={() => setSelected(dataItem.id)}
                  className={styles.tabTitle}
                >
                  {dataItem.title}
                </span>
                <li
                  key={dataItem.id}
                  className={`${styles.tablistItem} ${
                    selected === dataItem.id ? styles.selectedItem : null
                  } `}
                  onClick={() => setSelected(dataItem.id)}
                  style={{
                    borderRight:
                      index === data.length - 1 ? '0px' : 'solid 1px #f5f5f5',
                  }}
                ></li>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default Tabs;

import {
  GENERAL_GRAPH,
  getCurrentAge,
  isAdvisorSelector,
  SUCCESS_CODE,
  updateProfile,
  currentUserDataSelector,
} from "@plootus/common";
import React, { useEffect, useReducer, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import InputBox from "../../Utils/InputBox/InputBox";
import CustomSlider from "../../Utils/Slider/CustomSlider";
import styles from "./EditAssumptions.module.css";
import Slider2 from "../../Utils/Slider2/Slider2";

// These are temporary values for testing
const marksArray = [
  { value: 0, label: "Super  Conservative" },
  { value: 1, label: "Conservative" },
  { value: 2, label: "Moderate" },
  { value: 3, label: "Growth" },
  { value: 4, label: "Super Growth" },
];

const sliderReducer = (state, action) => {
  switch (action.type) {
    case "GENERAL_SLIDER":
      return {
        ...state,
        sliderOptions: action.payload,
      };
      break;

    case "OPENING":
      return {
        ...state,
        openingBalance: action.payload,
      };
      break;

    case "STRAT_INDEX":
      return {
        ...state,
        strategyIndex: action.payload,
      };
      break;

    case "RESET_SLIDER":
      return {
        ...state,
        sliderOptions: action.payload.sliderOptions,
        strategyIndex: action.payload.strategyIndex,
        openingBalance: action.payload.openingBalance,
      };
      break;

    default:
      return state;
  }
};

const EditAssumptions = (props) => {
  // if (sliderOptions) console.log(sliderOptions["lifeExp"].config.min);

  //  console.log(sliderOptions);

  const {
    sliderOptions,
    strategyIndex,
    openingBalance,
    editMode,
    setEditMode,
    closeModal,
  } = props;

  const initialState = {
    sliderOptions,
    openingBalance,
    strategyIndex,
  };

  const [state, myDispatch] = useReducer(sliderReducer, initialState);

  useEffect(() => {
    myDispatch({
      type: "RESET_SLIDER",
      payload: {
        sliderOptions,
        openingBalance,
        strategyIndex,
      },
    });
  }, [sliderOptions, openingBalance, strategyIndex]);
  let flag = 1;
  let age, dob;
  let user = useSelector((state) => state.userReducer);
  if (user.userData.userType === "advisor") {
    dob = user.advisor?.clients[user.advisor?.selectedClient]?.dob;
  } else {
    dob = user.userData?.dob;
  }
  if (dob) {
    flag = 0;
  }

  age = getCurrentAge(dob || "1991-01-01");

  const dispatch = useDispatch();

  const handleChange = (val) => {
    myDispatch({
      type: "OPENING",
      payload: val,
    });
    setEditMode(true);
  };

  const handleApply = async () => {
    const postData = {
      retireAge: state?.sliderOptions?.retireAge.value,
      lifeExpectancy: state?.sliderOptions?.lifeExp.value,
      annualContribution: state?.sliderOptions?.contribution.value,
      approx_401k_balance: state?.openingBalance,
      strategy: state?.strategyIndex,
      ...(sliderOptions?.expenses
        ? { futureExpense: sliderOptions?.expenses?.value }
        : {}),
    };

    const code = await dispatch(updateProfile(postData));

    if (code === SUCCESS_CODE) {
      dispatch({
        type: GENERAL_GRAPH,
        payload: {
          sliderOptions: state.sliderOptions,
          strategyIndex: state.strategyIndex,
          openingBalance: state.openingBalance,
        },
      });
    }

    setEditMode(false);
  };

  const handleCancel = () => {
    myDispatch({
      type: "RESET_SLIDER",
      payload: { sliderOptions, strategyIndex, openingBalance },
    });

    setEditMode(false);
  };
  const stateRedux = useSelector((state) => state);
  const userDataTemp = isAdvisorSelector(stateRedux)
    ? user.advisor.clients[user.advisor.selectedClient]
    : user.userData;

  const { height, width } = useWindowDimensions();

  const [selected, setSelected] = useState(state.strategyIndex);

  // const dob = user.userData.dob || "1993-01-01";
  // const age = getCurrentAge(dob);

  //console.log(sliderOptions);

  const tp = useSelector(currentUserDataSelector);

  const t1 = state.sliderOptions.currentAge;
  const t2 = state.sliderOptions.expenses;

  const retireSlider = (
    <Slider2
      initialValue={
        state.sliderOptions ? state?.sliderOptions?.retireAge?.value : 0
      }
      label="Retirement Age (Years)"
      displayLabel="yes"
      slidername="retireAge"
      minValue={age ? age : 0}
      maxValue={100}
      myDispatch={myDispatch}
      state={state}
      setEditMode={setEditMode}
    />
  );

  const ageSlider = (
    <Slider2
      initialValue={
        state.sliderOptions ? state?.sliderOptions?.currentAge?.value : 0
      }
      label="Current Age (Years)"
      displayLabel="yes"
      minValue={
        state.sliderOptions ? state?.sliderOptions?.currentAge?.config?.min : 0
      }
      maxValue={
        state.sliderOptions
          ? state?.sliderOptions?.currentAge?.config?.max
          : 100
      }
      slidername="currentAge"
      myDispatch={myDispatch}
      state={state}
      setEditMode={setEditMode}
    />
  );

  const lifeSlider = (
    <Slider2
      initialValue={state.sliderOptions ? state.sliderOptions.lifeExp.value : 0}
      label="Life Expectancy (Years)"
      displayLabel="yes"
      slidername="lifeExp"
      minValue={state.sliderOptions["lifeExp"].config.min}
      maxValue={120}
      myDispatch={myDispatch}
      state={state}
      setEditMode={setEditMode}
    />
  );

  const contriSlider = (
    <Slider2
      initialValue={
        state.sliderOptions ? state.sliderOptions.contribution.value : 1
      }
      label="Annual Contribution ($)"
      maxValue={40000}
      displayLabel="no"
      slidername="contribution"
      unit="$"
      myDispatch={myDispatch}
      state={state}
      setEditMode={setEditMode}
      type="on"
    />
  );

  const expensesSlider = (
    <Slider2
      initialValue={
        state.sliderOptions ? state?.sliderOptions?.expenses?.value : 50000
      }
      minValue={
        state.sliderOptions ? state?.sliderOptions?.expenses?.config?.min : 1
      }
      maxValue={
        state.sliderOptions
          ? state?.sliderOptions?.expenses?.config?.max
          : 1000000
      }
      label="Annual Expenses ($)"
      slidername="expenses"
      displayLabel="no"
      type="on"
      myDispatch={myDispatch}
      state={state}
      setEditMode={setEditMode}
    />
  );

  let col1, col2;

  if (t1) {
    col1 = (
      <>
        {ageSlider}
        {retireSlider}
      </>
    );
    if (t2) {
      col2 = (
        <>
          {lifeSlider}
          {contriSlider} {expensesSlider}
        </>
      );
    } else {
      col2 = (
        <>
          {lifeSlider}
          {contriSlider}
        </>
      );
    }
  } else {
    if (t2) {
      col1 = (
        <>
          {retireSlider}
          {lifeSlider}
        </>
      );

      col2 = (
        <>
          {contriSlider}
          {expensesSlider}
        </>
      );
    } else {
      col1 = <>{retireSlider}</>;
      col2 = (
        <>
          {lifeSlider}
          {contriSlider}
        </>
      );
    }
  }

  return sliderOptions ? (
    <div className={styles.root}>
      <Row className={styles.main_row}>
        <Col xs="12">
          <div className={styles.title}>
            <h5>Edit Assumptions</h5>
            <img
              className={styles.crossBtn}
              src="/cross-thin.png"
              onClick={closeModal}
            />
          </div>
        </Col>
        <Col className={styles.main_col} lg="12" xl="6">
          <InputBox
            openingBalance={state.openingBalance}
            handleChange={handleChange}
            value={state.openingBalance}
          />
          {col1}
        </Col>
        <Col className={styles.main_col} lg="12" xl="6">
          {col2}
        </Col>
        {/* {!tp.isLinked ? <Col className={styles.main_col} xs="12"></Col> : null} */}

        <Col xs="12">
          <div className={styles.sliderDiv}>
            <p style={{ fontSize: "15px" }}>Strategy</p>
            <div className={styles.btnDiv}>
              {marksArray.map((item, index) => (
                <button
                  onClick={() => {
                    setEditMode(true);
                    myDispatch({
                      type: "STRAT_INDEX",
                      payload: item.value,
                    });
                  }}
                  key={item.value}
                  className={
                    state.strategyIndex === item.value
                      ? styles.selectedBtn
                      : styles.editButton
                  }
                  style={{
                    borderRadius: "0px",
                    borderTopLeftRadius: index === 0 ? "5px" : "0px",
                    borderBottomLeftRadius: index === 0 ? "5px" : "0px",
                    borderTopRightRadius: index === 4 ? "5px" : "0px",
                    borderBottomRightRadius: index === 4 ? "5px" : "0px",

                    borderRight: index !== 4 ? "2px solid #e5e5e5" : "0px",
                  }}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        </Col>

        {editMode ? (
          <Col xs="12" className={styles.editRow}>
            <button className={styles.cancelBtn} onClick={handleCancel}>
              Cancel
            </button>
            <button className={styles.saveBtn} onClick={handleApply}>
              Apply
            </button>
          </Col>
        ) : null}
      </Row>
    </div>
  ) : null;
};

export default connect(({ graphReducer }) => ({
  sliderOptions: graphReducer.sliderOptions,
  strategyIndex: graphReducer.strategyIndex,
  openingBalance: graphReducer.openingBalance,
}))(EditAssumptions);

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import VisualData from "../../../Reusables/VisualAndAccord/Container/VisualData";
import { EXP_API, INCOMEXP_SCREEN } from "@plootus/common";
import { get_expense, get_expense_transactions } from "@plootus/common";
import { HIGHLIGHT_PIE } from "@plootus/common";

const ExpenseData = (props) => {
  const dispatch = useDispatch();
  const {
    expense_data,
    expense_pie,
    expense_col,
    expense_labels,
    expense_total,
    expense_trans_data,
  } = useSelector((state) => state.portfolioReducer.incomexp);
  const expense = {
    expense_data,
    expense_pie,
    expense_col,
    expense_labels,
    expense_total,
    expense_trans_data,
  };
  return (
    <VisualData
      api_name={EXP_API}
      screen={INCOMEXP_SCREEN}
      dispatchAction={get_expense}
      dispatchTransaction={get_expense_transactions}
      title="Total Expenses"
      type="pie"
      _key="cat_id"
      actionType={HIGHLIGHT_PIE}
      _key_accord="id"
      {...props}
      {...expense}
      dispatch={dispatch}
    />
  );
};

export default ExpenseData;

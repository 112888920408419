import StarBorderIcon from '@material-ui/icons/StarBorder';
import Rating from '@material-ui/lab/Rating';
import { BLUE_PLOOT, GREEN_PLOOT, roundNum } from '@plootus/common';
import React from 'react';
import { Col, Row } from 'reactstrap';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import styles from './TableRow.module.css';

export const getStratData = (strategy, item) => {
  switch (strategy) {
    case 0:
      return item.proposed_5;
    case 1:
      return item.proposed_1;
    case 2:
      return item.proposed_2;
    case 3:
      return item.proposed_3;
    case 4:
      return item.proposed_4;
    default:
      return undefined;
  }
};

const TableRow = (props) => {
  const { item, strategy, index, current } = props;
  const { width } = useWindowDimensions();
  const color = index % 2 ? '#fff' : '#f2f2f2';
  if (width > 576) {
    return item?.ticker_name ? (
      <div className={styles.container} style={{ backgroundColor: color }}>
        <Row noGutters>
          <Col xs='1' className={styles.srno}>
            {index + 1}.
          </Col>
          <Col xs='4' className={styles.left}>
            <Row noGutters>
              <Col xs='2' className={styles.circleWrapper}>
                <div style={{ paddingRight: '15px' }}>
                  <div
                    className={styles.circle}
                    style={{
                      backgroundColor: index % 2 ? GREEN_PLOOT : BLUE_PLOOT
                    }}
                  ></div>
                </div>
              </Col>
              <Col xs='10' className={styles.leftText}>
                <Row noGutters>
                  <Col
                    xs='12'
                    className={styles.leftText}
                    style={{ color: BLUE_PLOOT }}
                  >
                    <div style={{ paddingRight: '20px' }}>
                      {item.ticker_name}
                    </div>
                  </Col>
                  <Col
                    xs='12'
                    className={styles.leftText}
                    style={{ color: '#404a57', fontSize: '12px' }}
                  >
                    <div>{item.ticker}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {current ? (
            <Col xs='2' className={styles.center}>
              0.0%
            </Col>
          ) : null}
          <Col xs={current ? '2' : '3'} className={styles.right}>
            {`${roundNum(getStratData(strategy, item), 1, 1).slice(0, -1)}`}
            {`${roundNum(getStratData(strategy, item), 1, 1).slice(-1)} %`}
          </Col>
          <Col xs={current ? '3' : '4'} className={styles.center}>
            {item.rating ? (
              <Rating
                name='customized-empty'
                defaultValue={item.rating}
                precision={0.1}
                readOnly={true}
                size='small'
                emptyIcon={
                  <StarBorderIcon
                    fontSize='inherit'
                    style={{ color: '#fcce41' }}
                  />
                }
                style={{ color: '#fcce41' }}
              />
            ) : (
              '--'
            )}
          </Col>
        </Row>
      </div>
    ) : null;
  } else {
    return item ? (
      <div className={styles.container} style={{ backgroundColor: color }}>
        <Row noGutters>
          <Col xs='4' className={styles.left}>
            <Row noGutters>
              <Col xs='2' className={styles.circleWrapper}>
                <div style={{ paddingRight: width > 1024 ? '15px' : '0px' }}>
                  <div
                    className={styles.circle}
                    style={{
                      backgroundColor: index % 2 ? GREEN_PLOOT : BLUE_PLOOT
                    }}
                  ></div>
                </div>
              </Col>
              <Col xs='10' className={styles.leftText}>
                <Row noGutters>
                  <Col
                    xs='12'
                    className={styles.leftText}
                    style={{ color: BLUE_PLOOT }}
                  >
                    <div style={{ paddingRight: '20px' }}>
                      {item.ticker_name}
                    </div>
                  </Col>
                  <Col
                    xs='12'
                    className={styles.leftText}
                    style={{ color: '#404a57', fontSize: '12px' }}
                  >
                    <div>{item.ticker}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {current ? (
            <Col xs='2' className={styles.center}>
              0.0%
            </Col>
          ) : null}
          <Col xs={current ? '2' : '3'} className={styles.right}>
            {`${roundNum(getStratData(strategy, item), 1, 1).slice(0, -1)}`}
            {`${roundNum(getStratData(strategy, item), 1, 1).slice(-1)} %`}
          </Col>
          <Col xs={current ? '4' : '5'} className={styles.right}>
            {item.rating ? (
              <Rating
                name='customized-empty'
                defaultValue={item.rating}
                precision={0.1}
                readOnly={true}
                size='small'
                emptyIcon={
                  <StarBorderIcon
                    fontSize='inherit'
                    style={{ color: '#fcce41' }}
                  />
                }
                style={{ color: '#fcce41' }}
              />
            ) : (
              '--'
            )}
          </Col>
        </Row>
      </div>
    ) : null;
  }
};

export default TableRow;

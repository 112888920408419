import { BLUE_PLOOT, TEXT_BLACK_1 } from "@plootus/common";
import React from "react";
import Accordion from "../../Utils/Accordion/Accordion";
import { faqs } from "../FAQs/Questions";
import styles from "./faqs.module.css";

function Faqs() {
  return (
    <div>
      <div className={styles.main}>
        <div className={styles.textContent}>
          <h1 className={styles.philoH1}>Frequently Asked Questions</h1>
          <p className={styles.philoP}>
            Here are answers to some of the questions asked by our users.
          </p>
        </div>
      </div>
      <div className={styles.faqSection}>
        <div className={styles.faqLeft}>
          <div className={styles.faqLeftText}>
            <h2
              className={styles.faqHeader}
              style={{ fontSize: "25px", color: TEXT_BLACK_1 }}
            >
              Do you have any query regarding Plootus?
            </h2>
            <p
              className={styles.faqDesc}
              style={{ fontSize: "18px", color: TEXT_BLACK_1 }}
            >
              Contact us anytime, and post your query below
            </p>
          </div>
        </div>
        <div className={styles.faqRight}>
          <div className={styles.AccordionContainer}>
            {faqs.map((faq, i) => {
              return (
                <Accordion
                  title={faq.title}
                  first={i === 0}
                  last={i === faqs.length - 1}
                  style={{ width: "90%" }}
                  contentStyle={{
                    padding: "20px",
                    fontSize: "18px",
                    backgroundColor: BLUE_PLOOT,
                  }}
                  index={i}
                >
                  <span className="accordion-text">{faq.content}</span>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className={styles.feedback}>
        <div className='container'>
          <h2>How can we help ?</h2>
          <h6>
            Our team is here for you. We will get back to you in 2 business
            days.
          </h6>

          <form method='POST' action='#' class='mt-5'>
            <div className='row'>
              <div className='col-md-4 form-group'>
                <label for='formGroupExampleInput'>Full Name</label>
                <input
                  type='text'
                  className='form-control'
                  id='formGroupExampleInput'
                  placeholder='Enter your full name'
                  required
                />
              </div>
              <div className='col-md-4 form-group'>
                <label for='formGroupExampleInput'>Email Address</label>
                <input
                  type='email'
                  className='form-control'
                  id='formGroupExampleInput'
                  placeholder='Enter your Email Id'
                  required
                />
              </div>
              <div className='col-md-4 form-group'>
                <label for='formGroupExampleInput'>Phone Number</label>
                <input
                  type='number'
                  className='form-control'
                  id='formGroupExampleInput'
                  placeholder='Enter your Phone no.'
                  required
                />
              </div>

              <div className='col-12 form-group'>
                <label for='formGroupExampleInput'>Query</label>
                <textarea
                  name=''
                  id=''
                  cols='30'
                  rows='5'
                  className='form-control'
                  required
                ></textarea>
              </div>
            </div>
            <div
              className='col-12 button-div'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button type='submit' className='btn btn-primary'>
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div> */}
    </div>
  );
}

export default Faqs;

import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import axios, { post } from 'axios';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { formatCommaString, roundNum, BLUE_PLOOT } from '@plootus/common';
import { Helmet } from 'react-helmet';
import Tooltip from '../../Utils/Tooltip/Tooltip';
import './newmod.css';
import { Card } from '@material-ui/core';
import ReactSpeedometer from 'react-d3-speedometer';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import styles from './Crypto.module.css';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
export default function Crypto() {
  const { width } = useWindowDimensions();
  const [cryG, setCryG] = useState(true);
  const [cryptoSortMatrix, setCryptoSortMatrix] = useState([1, 0, 0, 0, 0]);
  const [cryptoDataG, setCryptoDataG] = useState([]);
  const [cryptoDataGL, setCryptoDataGL] = useState([]);
  const [cryptoDataL, setCryptoDataL] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [stockDataG, setStockDataG] = useState([]);
  const [stockDataGL, setStockDataGL] = useState([]);
  const [stockDataL, setStockDataL] = useState([]);
 
  const [cryptoSelected, setCryptoSelected] = useState();
  const [stockSelected, setStockSelected] = useState();
  const [cryptoGLSelected, setCryptoGLSelected] = useState();
  const [stockGLSelected, setStockGLSelected] = useState();
  const [stockSelection, setStockSelection] = useState('gain');
  const [stockG, setStockG] = useState(true);
  const history = useHistory();

  const cryptoSortMapping = ['name', 'gains_24h', 'current_price', 'mcap', 'sentiment_score'];

  const theme = createMuiTheme({
    palette: {
      primary: { main: BLUE_PLOOT } // Purple and green play nicely together.
    },
    typography: { useNextVariants: true }
  });
  useEffect(() => {
    const url = `https://www.plootus.com/api/getSentiments`;

    axios.get(url).then((res) => {
      console.log(res.data);
      const crypto_gain24h = res.data.crypto.slice(0).sort((x, y) => (parseFloat(y.gains_24h) - parseFloat(x.gains_24h))); 
      const stock_gain24h = res.data.stocks.slice(0).sort((x, y) => (parseFloat(y.gains_24h) - parseFloat(x.gains_24h)));
      setCryptoDataGL(crypto_gain24h);
      setCryptoGLSelected(crypto_gain24h[0]);
      setCryptoDataG(res.data.crypto);
      setCryptoSelected(res.data.crypto[0]);
      setCryptoDataL(res.data.crypto);
      setStockDataGL(stock_gain24h);
      setStockGLSelected(stock_gain24h[0]);
      setStockSelected(res.data.stocks[0]);
      setStockDataG(res.data.stocks);
      setStockDataL(res.data.stocks);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <div
        style={{
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <MuiThemeProvider theme={theme}>
          <CircularProgress size={40} />
        </MuiThemeProvider>
      </div>
    );
  }
  if (
    cryptoDataG.length > 0 &&
    cryptoDataL.length > 0 &&
    stockDataG.length > 0 &&
    stockDataL.length > 0
  ) {
    console.log('cryptoSortMatrix :: ', cryptoSortMatrix, (cryptoSortMatrix.findIndex(v => (v === true) )));
    return (
      <div>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            401k 403b 457 Philosophy and Investment Stategy | Plootus
          </title>
          <meta
            name='description'
            content='Plootus 401k, IRA, 403b and 457 investment philosophy & strategy starts with picking the right mix of stocks, bonds, & funds totally free. Get your financial planner cum Robo Advisor!'
          />
          <meta
            name='keywords'
            content='Retirement calculator, Advisory services, 401k allocation, 403b optimization, 457 plan, TSP, best Retirement Plans, how to manage 401k, low cost account management, Plan Rollover, IRA, deferred compensation,Retirement early withdrawl, Financial Planning, Financial advisor'
          />
        </Helmet>
        <div className={styles.gapper} />
        <div className={styles.main}>
          <div className={styles.msgBoxCont}>
            {width > 590 ? (
              <div className={styles.msgBox}>
                <div className={styles.msgBoxText}>
                  Want to invest in Cryptocurrency but don't know which one to
                  select?
                </div>
                <div className={styles.msgBoxText1}>
                  Check out the Top Gainers/Losers and their market sentiments
                  based on Plootus AI.
                </div>
              </div>
            ) : (
              <div className={styles.msgBox}>
                Want to invest in Cryptocurrency but don't know which one to
                select? Check out the Top Gainers/Losers and their market
                sentiments based on Plootus AI.
              </div>
            )}
          </div>

          {/* Crypto Currency */}
          <div className={styles.crypto}>
            <div className={styles.mytestHeadercry}>
              <p className={styles.feesTextcry}>Cryptocurrency</p>
            </div>
            <Card
              className={styles.mainCard}
              // style={{
              //   borderRadius: '10px',
              //   boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)'
              // }}
            >
              {width > 590 ? (
                <div className={styles.cryptoMover}>
                  <div className={styles.title}>
                    {/* <div className={styles.name}>
                      {cryG == true ? (
                        <>
                          <div
                            className={styles.moversTitle}
                            style={{ marginRight: '20px' }}
                            onClick={() => setCryG(!cryG)}
                          >
                            CryptoCurrency
                            <ArrowDropUpIcon color='black' />
                          </div>
                          <div className={styles.moversTitle}>
                            % Gain
                            <ArrowDropUpIcon color='black' />
                          </div>
                          <div className={styles.moversTitle}>
                            Current Price
                            <ArrowDropUpIcon color='black' />
                          </div>
                          <div className={styles.moversTitle}>
                            Market Cap
                            <ArrowDropUpIcon color='black' />
                          </div>
                          <div className={styles.moversTitle}>
                            Sentiment Score
                            <ArrowDropUpIcon color='black' />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={styles.moversTitle}
                            style={{ marginRight: '50px' }}
                            onClick={() => setCryG(!cryG)}
                          >
                            CryptoCurrency
                            <ArrowDropDownIcon color='black' />
                          </div>
                          <div className={styles.moversTitle}>
                            % Loss
                            <ArrowDropDownIcon color='black' />
                          </div>
                          <div className={styles.moversTitle}>
                            Current Price
                            <ArrowDropDownIcon color='black' />
                          </div>
                          <div className={styles.moversTitle}>
                            Market Cap
                            <ArrowDropDownIcon color='black' />
                          </div>
                          <div className={styles.moversTitle}>
                            Sentiment Score
                            <ArrowDropDownIcon color='black' />
                          </div>
                        </>
                      )}
                    </div> */}
                    <div className={styles.name}>
                      {(cryptoSortMatrix[0])?(
                        (cryptoSortMatrix[0] === -1)?(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '10px' }}
                          onClick={() => setCryptoSortMatrix([1, 0, 0, 0, 0])}
                          >
                            CryptoCurrency
                            <ArrowDropUpIcon color='black' />
                          </div>
                        ):(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '10px' }}
                          onClick={() => setCryptoSortMatrix([1, 0, 0, 0, 0])}
                        >
                          CryptoCurrency
                          <ArrowDropDownIcon color='black' />
                        </div>
                        )
                      ):(
                        <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([1, 0, 0, 0, 0])}
                        >
                          CryptoCurrency
                        </div>
                      )}

                      {(cryptoSortMatrix[1])?(
                        (cryptoSortMatrix[1] === -1)?(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 1, 0, 0, 0])}
                          >
                            % Loss
                            <ArrowDropUpIcon color='black' />
                          </div>
                        ):(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, -1, 0, 0, 0])}
                        >
                          % Gain
                          <ArrowDropDownIcon color='black' />
                        </div>
                        )
                      ):(
                        <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 1, 0, 0, 0])}
                        >
                          % Gain
                        </div>
                      )}

                      {(cryptoSortMatrix[2])?(
                        (cryptoSortMatrix[2] === -1)?(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, 1, 0, 0])}
                          >
                            Current Price
                            <ArrowDropUpIcon color='black' />
                          </div>
                        ):(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, -1, 0, 0])}
                        >
                          Current Price
                          <ArrowDropDownIcon color='black' />
                        </div>
                        )
                      ):(
                        <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, 1, 0, 0])}
                        >
                          Current Price
                        </div>
                      )}

                      {(cryptoSortMatrix[3])?(
                        (cryptoSortMatrix[3] === -1)?(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, 0, 1, 0])}
                          >
                            Market Cap
                            <ArrowDropUpIcon color='black' />
                          </div>
                        ):(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, 0, -1, 0])}
                        >
                          Market Cap
                          <ArrowDropDownIcon color='black' />
                        </div>
                        )
                      ):(
                        <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, 0, 1, 0])}
                        >
                          Market Cap
                        </div>
                      )}

                      {(cryptoSortMatrix[4])?(
                        (cryptoSortMatrix[4] === -1)?(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, 0, 0, 1])}
                          >
                            Sentiment Score
                            <ArrowDropUpIcon color='black' />
                          </div>
                        ):(
                          <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, 0, 0, -1])}
                        >
                          Sentiment Score
                          <ArrowDropDownIcon color='black' />
                        </div>
                        )
                      ):(
                        <div
                          className={styles.moversTitle}
                          style={{ marginRight: '20px' }}
                          onClick={() => setCryptoSortMatrix([0, 0, 0, 0, 1])}
                        >
                          Sentiment Score
                        </div>
                      )}

                    </div>
                  </div>
                  <div className={styles.selectionTabs}>
                    { 
                    ((cryptoSortMatrix.findIndex(v => (v !== 0))) >= 0)
                    ? 
                    (
                      <div className={styles.cryptoGainScroll}>
                        {
                        cryptoDataG.slice(0).sort((x, y) => 
                          (
                            cryptoSortMatrix[(cryptoSortMatrix.findIndex(v => (v !== 0)))]*parseFloat(y[cryptoSortMapping[(cryptoSortMatrix.findIndex(v => (v !== 0)))]])
                            - 
                            cryptoSortMatrix[(cryptoSortMatrix.findIndex(v => (v !== 0)))]*parseFloat(x[cryptoSortMapping[(cryptoSortMatrix.findIndex(v => (v !== 0)))]])
                          )
                        )
                        .map((item, key) => (
                          <div
                            key={key}
                            className={
                              cryptoSelected.ticker == item.ticker
                                ? styles.moverSelection
                                : styles.moverSelectionWithout
                            }
                            onClick={() => setCryptoSelected(item)}
                          >
                            <div className={styles.tickerRow}>
                              <div className={styles.selectDarkText}>
                                {item.name}
                              </div>
                              <div className={styles.selectGrayText}>
                                ({item.ticker})
                              </div>
                            </div>

                            <>
                              {(item.gains_24h > 0)?(
                                <div className={styles.selectLightTextGreenC}>
                                  {formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%
                                </div>
                              ):(
                                <div className={styles.selectLightTextRedC}>
                                  {formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%
                                </div>
                              )}
                            </>
                            <div
                              className={styles.selectDarkText}
                              style={{
                                marginLeft: '-50px',
                                marginRight: '-30px'
                              }}
                            >
                              {formatCommaString(
                                roundNum(item.current_price, 2, 10, true, true)
                              )}
                            </div>
                            <div className={styles.selectDarkText}>
                              ${item.mcap}
                            </div>

                            <div
                              className={styles.selectDarkText}
                              style={{ marginRight: '70px' }}
                            >
                              {item.sentiment_score}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className={styles.cryptoGainScroll}>
                        {
                        cryptoDataG
                        .map((item, key) => (
                          <div
                            key={key}
                            className={
                              cryptoSelected.ticker == item.ticker
                                ? styles.moverSelection
                                : styles.moverSelectionWithout
                            }
                            onClick={() => setCryptoSelected(item)}
                          >
                            <div className={styles.tickerRow}>
                              <div className={styles.selectDarkText}>
                                {item.name}
                              </div>
                              <div className={styles.selectGrayText}>
                                ({item.ticker})
                              </div>
                            </div>

                            <>
                              {(item.gains_24h > 0)?(
                                <div className={styles.selectLightTextGreenC}>
                                  {formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%
                                </div>
                              ):(
                                <div className={styles.selectLightTextRedC}>
                                  {formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%
                                </div>
                              )}
                            </>
                            <div
                              className={styles.selectDarkText}
                              style={{
                                marginLeft: '-50px',
                                marginRight: '-30px'
                              }}
                            >
                              {formatCommaString(
                                roundNum(item.current_price, 2, 10, true, true)
                              )}
                            </div>
                            <div className={styles.selectDarkText}>
                              ${item.mcap}
                            </div>

                            <div
                              className={styles.selectDarkText}
                              style={{ marginRight: '70px' }}
                            >
                              {item.sentiment_score}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {/* <div className={styles.selectionTabs}>
                    {cryG == true ? (
                      <div className={styles.cryptoGainScroll}>
                        {cryptoDataG.map((item, key) => (
                          <div
                            key={key}
                            className={
                              cryptoSelected.ticker == item.ticker
                                ? styles.moverSelection
                                : styles.moverSelectionWithout
                            }
                            onClick={() => setCryptoSelected(item)}
                          >
                            <div className={styles.tickerRow}>
                              <div className={styles.selectDarkText}>
                                {item.name}
                              </div>
                              <div className={styles.selectGrayText}>
                                ({item.ticker})
                              </div>
                            </div>

                            <>
                              <div className={styles.selectLightTextGreenC}>
                                {formatCommaString(
                                  roundNum(item.gains_24h, 0, 2, true, true)
                                )}
                                %
                              </div>
                            </>
                            <div
                              className={styles.selectDarkText}
                              style={{
                                marginLeft: '-50px',
                                marginRight: '-30px'
                              }}
                            >
                              {formatCommaString(
                                roundNum(item.current_price, 2, 10, true, true)
                              )}
                            </div>
                            <div className={styles.selectDarkText}>
                              ${item.mcap}
                            </div>

                            <div
                              className={styles.selectDarkText}
                              style={{ marginRight: '70px' }}
                            >
                              {item.sentiment_score}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className={styles.cryptoGainScroll}>
                        {cryptoDataL.slice(0).reverse().map((item, key) => (
                          <div
                            key={key}
                            className={
                              cryptoSelected.ticker == item.ticker
                                ? styles.moverSelection
                                : styles.moverSelectionWithout
                            }
                            onClick={() => setCryptoSelected(item)}
                          >
                            <div className={styles.tickerRow}>
                              <div className={styles.selectDarkText}>
                                {item.name}
                              </div>
                              <div className={styles.selectGrayText}>
                                ({item.ticker})
                              </div>
                            </div>

                            <>
                              <div className={styles.selectLightTextRed}>
                                {formatCommaString(
                                  roundNum(item.gains_24h, 0, 2, true, true)
                                )}
                                %
                              </div>
                            </>
                            <div
                              className={styles.selectDarkText}
                              style={{
                                marginLeft: '-50px',
                                marginRight: '-30px'
                              }}
                            >
                              {formatCommaString(
                                roundNum(item.current_price, 2, 10, true, true)
                              )}
                            </div>
                            <div className={styles.selectDarkText}>
                              ${item.mcap}
                            </div>

                            <div
                              className={styles.selectDarkText}
                              style={{ marginRight: '70px' }}
                            >
                              {item.sentiment_score}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div> */}
                  {/* <Redirect to='/crypto'> */}

                  {/* </Redirect> */}
                </div>
              ) : (
                <div className={styles.cryptoMover}>
                  <div className={styles.title}>
                    <div className={styles.name}>
                      <div className={styles.moversTitle}>Top Gainers</div>
                      <div className={styles.moversTitle}>Top Losers</div>
                    </div>
                  </div>
                  <div className={styles.selectionTabs}>
                    <div className={styles.cryptoGainScroll}>
                      {cryptoDataGL.slice(0, ((cryptoDataGL.length/2) + 1)).map((item, key) => (
                        <div
                          key={key}
                          className={
                            cryptoGLSelected.ticker == item.ticker
                              ? styles.moverSelection
                              : styles.moverSelectionWithout
                          }
                          onClick={() => setCryptoGLSelected(item)}
                        >
                          <div className={styles.selectDarkText}>
                            {item.name}
                            <div className={styles.selectGrayText}>
                              ({item.ticker})
                            </div>
                          </div>

                          <>
                            <div className={styles.selectLightText}> </div>
                            {(item.gains_24h > 0)?(
                              <div className={styles.selectLightTextGreenC}>
                                ({formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%)
                              </div>
                            ):(
                              <div className={styles.selectLightTextRedC}>
                                ({formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%)
                              </div>
                            )}
                            {/* <div className={styles.selectLightTextGreenC}>
                              (
                              {formatCommaString(
                                roundNum(item.gains_24h, 0, 2, false, true)
                              )}
                              %)
                            </div> */}
                          </>
                        </div>
                      ))}
                    </div>
                    <div className={styles.cryptoGainScroll}>
                      {cryptoDataGL.slice(((cryptoDataGL.length/2) + 1)).reverse().map((item, key) => (
                        <div
                          key={key}
                          className={
                            cryptoGLSelected.ticker == item.ticker
                              ? styles.moverSelection
                              : styles.moverSelectionWithout
                          }
                          onClick={() => setCryptoGLSelected(item)}
                        >
                          <div className={styles.selectDarkText}>
                            {item.name}
                            <div className={styles.selectGrayText}>
                              ({item.ticker})
                            </div>
                          </div>

                          <>
                            <div className={styles.selectLightText}> </div>
                            {/* color coding change as per sign */}
                            {(item.gains_24h > 0)?(
                              <div className={styles.selectLightTextGreenC}>
                                ({formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%)
                              </div>
                            ):(
                              <div className={styles.selectLightTextRedC}>
                                ({formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%)
                              </div>
                            )}
                          </>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <Redirect to='/crypto'> */}

                  {/* </Redirect> */}
                </div>
              )}
              {/* speedometer */}
              <div className={styles.speedometerView}>
                <div className={styles.title1}>
                  <div className={styles.nameWrapper}>
                    <div className={styles.name1}>
                      <div className={styles.moversTitle1}>Sentiment Score</div>
                      <Tooltip
                        title='Sentiment score is determined based on news from various media outlets.  Plootus AI analyses this news and determines if the overall sentiment is positive or negative for Cryptocurrency/Security.'
                        render={(props) => (
                          <img
                            style={{
                              width: '17px',
                              height: '17px',
                              alignItems: 'center',
                              cursor: 'pointer',
                              marginLeft: '7px',
                              marginTop: '3px'
                            }}
                            src='/question.png'
                            alt=''
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                {width > 590 ? (
                  <div>
                    <div className={styles.stockNameUpper}>
                      <div className={styles.stockCardDetail}>
                        <div className={styles.selectLightText}>
                          CURRENT PRICE($)
                        </div>
                        {cryptoSelected.current_price > 1 ? (
                          <div className={styles.selectDarkTexts}>
                            $
                            {formatCommaString(
                              roundNum(
                                cryptoSelected.current_price,
                                0,
                                2,
                                true,
                                true
                              )
                            )}
                          </div>
                        ) : (
                          <div className={styles.selectDarkTexts}>
                            $
                            {formatCommaString(
                              roundNum(
                                cryptoSelected.current_price,
                                0,
                                9,
                                true,
                                true
                              )
                            )}
                          </div>
                        )}
                      </div>
                      <div className={styles.stockCardDetail}>
                        <div className={styles.selectLightText}>
                          MARKET CAP ($B)
                        </div>

                        <div className={styles.selectDarkTexts}>
                          ${cryptoSelected.mcap}
                        </div>
                      </div>
                      <div className={styles.stockCardDetail}>
                        <div className={styles.selectLightText}>
                          % GAIN (24 HRS)
                        </div>
                        <div className={styles.selectLightTextRow}>
                          <div
                            style={{ marginLeft: '5px' }}
                            className={
                              cryptoSelected.gains_24h < 0
                                ? styles.selectLightTextRed
                                : styles.selectLightTextGreen
                            }
                          >
                            {formatCommaString(
                              roundNum(cryptoSelected.gains_24h, 0, 2, true, true)
                            )}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '350px',
                        height: '250px',
                        background: '#ffffff',
                        marginTop: '50px',
                        marginLeft: '50px'
                      }}
                    >
                      <ReactSpeedometer
                        fluidWidth={true}
                        startColor='red'
                        endColor='green'
                        ringWidth={30}
                        minValue={-1}
                        maxValue={1}
                        maxSegmentLabels={0}
                        segments={1000}
                        value={cryptoSelected.sentiment_score}
                        needleColor='black'
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={styles.stockNameUpper}>
                      <div className={styles.stockCardDetail}>
                        <div className={styles.selectLightText}>
                          CURRENT PRICE($)
                        </div>
                        {cryptoGLSelected.current_price > 1 ? (
                          <div className={styles.selectDarkTexts}>
                            $
                            {formatCommaString(
                              roundNum(
                                cryptoGLSelected.current_price,
                                0,
                                2,
                                true,
                                true
                              )
                            )}
                          </div>
                        ) : (
                          <div className={styles.selectDarkTexts}>
                            $
                            {formatCommaString(
                              roundNum(
                                cryptoGLSelected.current_price,
                                0,
                                9,
                                true,
                                true
                              )
                            )}
                          </div>
                        )}
                      </div>
                      <div className={styles.stockCardDetail}>
                        <div className={styles.selectLightText}>
                          MARKET CAP ($B)
                        </div>

                        <div className={styles.selectDarkTexts}>
                          ${cryptoGLSelected.mcap}
                        </div>
                      </div>
                      <div className={styles.stockCardDetail}>
                        <div className={styles.selectLightText}>
                          % GAIN (24 HRS)
                        </div>
                        <div className={styles.selectLightTextRow}>
                          <div
                            style={{ marginLeft: '5px' }}
                            className={
                              cryptoGLSelected.gains_24h < 0
                                ? styles.selectLightTextRed
                                : styles.selectLightTextGreen
                            }
                          >
                            {formatCommaString(
                              roundNum(cryptoGLSelected.gains_24h, 0, 2, true, true)
                            )}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  <div
                    style={{
                      width: '350px',
                      height: '250px',
                      background: '#ffffff',
                      marginTop: '30px',
                      marginLeft: '-40px'
                    }}
                  >
                    <ReactSpeedometer
                      fluidWidth={true}
                      startColor='red'
                      endColor='green'
                      ringWidth={30}
                      minValue={-1}
                      maxValue={1}
                      maxSegmentLabels={0}
                      segments={1000}
                      value={cryptoGLSelected.sentiment_score}
                      needleColor='black'
                    />
                  </div>
                </div>
                )}
                <div className={styles.speedSellContUpperRange}>
                  <div className={styles.speedSellBtnTxt}>-1</div>

                  <div className={styles.speedBuyBtnTxt}>1</div>
                </div>
                <div className={styles.speedSellContUpper}>
                  <div className={styles.speedSellBtn}>
                    <div className={styles.speedSellBtnTxt}>Sell</div>
                  </div>
                  <div className={styles.speedBuyBtn}>
                    <div className={styles.speedBuyBtnTxt}>Buy</div>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          {/* Stock UI */}

          <div className={styles.cryptoStock}>
            <div className={styles.mytestHeadercry}>
              <p className={styles.feesTextcry}>Stock</p>
            </div>
            {width > 590 ? (
              <div className={styles.mainCard2}>
                <div className={styles.cryptoMover1}>
                  <div
                    className={styles.title}
                    onClick={() => setStockG(!stockG)}
                  >
                    {stockG == true ? (
                      <div className={styles.name}>
                        <div
                          className={styles.moversTitle}
                          style={{ marginRight: '80px' }}
                        >
                         Stocks
                          <ArrowDropUpIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          % Gain
                          <ArrowDropUpIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          Current Price
                          <ArrowDropUpIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          Target Price
                          <ArrowDropUpIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          Market Cap
                          <ArrowDropUpIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          Sentiment Score
                          <ArrowDropUpIcon color='black' />
                          <div className={styles.stockSentiRange}>Range : -1 to 1</div>
                        </div>
                        <div className={styles.moversTitle}>
                          Analyst Score
                          <ArrowDropUpIcon color='black' />
                          <div className={styles.stockSentiRange}>Range : 1 to 5</div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.name}>
                        <div
                          className={styles.moversTitle}
                          style={{ marginRight: '80px' }}
                        >
                          Stocks
                          <ArrowDropDownIcon color='black' />
                        </div>
                        <div className={styles.moversTitle} style={{marginLeft:'20px'}}>
                          % Loss
                          <ArrowDropDownIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          Current Price
                          <ArrowDropDownIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          Target Price
                          <ArrowDropDownIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          Market Cap
                          <ArrowDropDownIcon color='black' />
                        </div>
                        <div className={styles.moversTitle}>
                          Sentiment Score
                          <ArrowDropDownIcon color='black' />
                          <div className={styles.stockSentiRange}>Range : -1 to 1</div>
                        </div>
                        <div className={styles.moversTitle}>
                          Analyst Score
                          <ArrowDropDownIcon color='black' />
                          <div className={styles.stockSentiRange}>Range : 1 to 5</div>
                        </div>
                      </div>
                    )}
                  </div>
                  {stockG == true ? (
                    <div className={styles.stockGain}>
                      {stockDataG.map((item, key) => {
                        return (
                          <div
                            key={key}
                            className={
                              stockSelected.ticker == item.ticker
                                ? styles.moverSelectionStock
                                : styles.moverSelectionWithoutStock
                            }
                            onClick={() => setStockSelected(item)}
                          >
                            <div className={styles.tickerRowStock}>
                              <div className={styles.selectDarkText}>
                                {item.name}
                              </div>
                              <div className={styles.selectGrayText}>
                                ({item.ticker})
                              </div>
                            </div>
                            <>
                              <div className={styles.selectLightTextGreenC}>
                                {formatCommaString(
                                  roundNum(item.gains_24h, 0, 2, true, true)
                                )}
                                %
                              </div>
                            </>

                            <div
                              className={styles.selectDarkText}
                              style={{
                                marginLeft: '-120px',
                                marginRight: '-70px'
                              }}
                            >
                              {item.current_price}
                            </div>

                            <div
                              className={styles.selectDarkText}
                              style={{
                                marginRight: '-60px'
                              }}
                            >
                              {item.target_price}
                            </div>
                            <div className={styles.selectDarkText}>
                              {item.mcap}
                            </div>

                            <div
                              className={
                                item.sentiment_score > 0
                                  ? styles.selectLightTextGreenC
                                  : styles.selectLightTextRed
                              }
                              style={{
                                marginLeft: '-70px'
                              }}
                            >
                              {formatCommaString(
                                roundNum(item.sentiment_score, 0, 2, true, true)
                              )}
                            </div>

                            <div
                              className={
                                item.analyst_rating > 0
                                  ? styles.selectLightTextGreenC
                                  : styles.selectLightTextRed
                              }
                              style={{
                                marginRight: '90px'
                              }}
                            >
                              {formatCommaString(
                                roundNum(item.analyst_rating, 0, 2, true, true)
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className={styles.stockGain}>
                      {stockDataL.slice(0).reverse().map((item, key) => {
                        return (
                          <div
                            key={key}
                            className={
                              stockSelected.ticker == item.ticker
                                ? styles.moverSelectionStock
                                : styles.moverSelectionWithoutStock
                            }
                            onClick={() => setStockSelected(item)}
                          >
                            <div className={styles.tickerRowStock1}>
                              <div className={styles.selectDarkText}>
                                {item.name}
                              </div>
                              <div className={styles.selectGrayText}>
                                ({item.ticker})
                              </div>
                            </div>
                            <>
                              <div className={styles.selectLightTextRed}>
                                {formatCommaString(
                                  roundNum(item.gains_24h, 0, 2, true, true)
                                )}
                                %
                              </div>
                            </>

                            <div
                              className={styles.selectDarkText}
                              style={{
                                marginLeft: '-120px',
                                marginRight: '-70px'
                              }}
                            >
                              {item.current_price}
                            </div>

                            <div
                              className={styles.selectDarkText}
                              style={{
                                marginRight: '-60px'
                              }}
                            >
                              {item.target_price}
                            </div>
                            <div className={styles.selectDarkText}>
                              {item.mcap}
                            </div>

                            <div
                              className={
                                item.sentiment_score > 0
                                  ? styles.selectLightTextGreenC
                                  : styles.selectLightTextRed
                              }
                              style={{
                                marginLeft: '-70px'
                              }}
                            >
                              {formatCommaString(
                                roundNum(item.sentiment_score, 0, 2, true, true)
                              )}
                            </div>

                            <div
                              className={
                                item.analyst_rating > 0
                                  ? styles.selectLightTextGreenC
                                  : styles.selectLightTextRed
                              }
                              style={{
                                marginRight: '90px'
                              }}
                            >
                              {formatCommaString(
                                roundNum(item.analyst_rating, 0, 2, true, true)
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              // heree
              <>
                <div className={styles.stockMobTitle}>
                  <div
                    className={
                      stockSelection == 'gain'
                        ? styles.mobmoversTitle
                        : styles.mobmoversTitleWithout
                    }
                    onClick={() => setStockSelection('gain')}
                  >
                    Top Gainers
                  </div>
                  <div
                    className={
                      stockSelection == 'loss'
                        ? styles.mobmoversTitle2
                        : styles.mobmoversTitle2Without
                    }
                    onClick={() => setStockSelection('loss')}
                  >
                    Top Losers
                  </div>
                </div>
                <div className={styles.cryptoMover2}>
                  <div className={styles.mobStocktitle}>
                    <div className={styles.mobStockname}>
                      <div className={styles.moversTitleStock}>Stock</div>
                      <div className={styles.moversTitleStock1}>
                        Current Price($)
                      </div>
                      <div className={styles.moversTitleStock2}>
                        Target Price($)
                      </div>
                    </div>
                  </div>
                  <div className={styles.stockGain}>
                    {stockSelection == 'gain'
                      ? stockDataGL.slice(0, ((stockDataGL.length/2) + 1)).map((item, key) => {
                          return (
                            <Card
                              className={
                                stockGLSelected.ticker == item.ticker
                                  ? styles.stockCard
                                  : styles.stockCardWithout
                              }
                              onClick={() => setStockGLSelected(item)}
                              style={{
                                borderRadius: '6px',
                                boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                                backgroundColor:
                                  stockGLSelected.ticker == item.ticker
                                    ? '#d3dfff'
                                    : null
                              }}
                            >
                              <div className={styles.moversTitleStockLeft}>
                                <div className={styles.selectDarkText}>
                                  {item.name} ({item.ticker})
                                </div>
                                {/* color coding change as per sign */}
                                {(item.gains_24h > 0)?(
                                  <div className={styles.selectLightTextGreen}>
                                    ({formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%)
                                  </div>
                                ):(
                                  <div className={styles.selectLightTextRed}>
                                    ({formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%)
                                  </div>
                                )}
                                {/* <div className={styles.selectLightTextGreen}>
                                  (
                                  {formatCommaString(
                                    roundNum(item.gains_24h, 0, 2, true, true)
                                  )}
                                  %)
                                </div> */}
                              </div>
                              <div className={styles.moversTitleStockCenter}>
                                {item.current_price}
                              </div>
                              <div className={styles.moversTitleStockRight}>
                                {item.target_price}
                              </div>
                            </Card>
                          );
                        })
                      : stockDataGL.slice(((stockDataGL.length/2) + 1)).reverse().map((item, key) => {
                          return (
                            <Card
                              className={
                                stockGLSelected.ticker == item.ticker
                                  ? styles.stockCard
                                  : styles.stockCardWithout
                              }
                              onClick={() => setStockGLSelected(item)}
                              style={{
                                borderRadius: '6px',
                                boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                                backgroundColor:
                                  stockGLSelected.ticker == item.ticker
                                    ? '#d3dfff'
                                    : null
                              }}
                            >
                              <div className={styles.moversTitleStockLeft}>
                                <div className={styles.selectDarkText}>
                                  {item.name} ({item.ticker})
                                </div>
                                {/* color coding change as per sign */}
                                {(item.gains_24h > 0)?(
                                  <div className={styles.selectLightTextGreen}>
                                    ({formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%)
                                  </div>
                                ):(
                                  <div className={styles.selectLightTextRed}>
                                    ({formatCommaString(roundNum(item.gains_24h, 0, 2, true, true))}%)
                                  </div>
                                )}
                                {/* <div className={styles.selectLightTextRed}>
                                  (
                                  {formatCommaString(
                                    roundNum(item.gains_24h, 0, 2, true, true)
                                  )}
                                  %)
                                </div> */}
                              </div>
                              <div className={styles.moversTitleStockCenter}>
                                {item.current_price}
                              </div>
                              <div className={styles.moversTitleStockRight}>
                                {item.target_price}
                              </div>
                            </Card>
                          );
                        })}
                  </div>
                  <div className={styles.myStockHeadercry}>
                    <p className={styles.stockTextcry}>
                      {stockGLSelected.name} ({stockGLSelected.ticker})
                    </p>
                  </div>
                  <div className={styles.stockNameUpperMob}>
                    <div className={styles.stockCardDetail}>
                      <div className={styles.selectLightText}>
                        CURRENT PRICE($)
                      </div>
                      {stockGLSelected.current_price > 1 ? (
                        <div className={styles.selectDarkTexts}>
                          $
                          {formatCommaString(
                            roundNum(
                              stockGLSelected.current_price,
                              0,
                              2,
                              true,
                              true
                            )
                          )}
                        </div>
                      ) : (
                        <div className={styles.selectDarkTexts}>
                          $
                          {formatCommaString(
                            roundNum(
                              stockGLSelected.current_price,
                              0,
                              9,
                              true,
                              true
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.stockCardDetail}>
                      <div className={styles.selectLightText}>
                        TARGET PRICE($)
                      </div>
                      {stockGLSelected.current_price > 1 ? (
                        <div className={styles.selectDarkTexts}>
                          $
                          {formatCommaString(
                            roundNum(
                              stockGLSelected.target_price,
                              0,
                              2,
                              true,
                              true
                            )
                          )}
                        </div>
                      ) : (
                        <div className={styles.selectDarkTexts}>
                          $
                          {formatCommaString(
                            roundNum(
                              stockGLSelected.target_price,
                              0,
                              9,
                              true,
                              true
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.stockCardDetail}>
                      <div className={styles.selectLightText}>
                        MARKET CAP ($B)
                      </div>
                      <div className={styles.selectDarkTexts}>
                        ${stockGLSelected.mcap}
                      </div>
                    </div>
                    <div className={styles.stockCardDetail}>
                      <div className={styles.selectLightText}>
                        % GAIN (24 HRS)
                      </div>
                      <div className={styles.selectLightTextRow}>
                        <div
                          style={{ marginLeft: '5px' }}
                          className={
                            stockGLSelected.gains_24h > 0
                              ? styles.selectLightTextGreen
                              : styles.selectLightTextRed
                          }
                        >
                          (
                          {formatCommaString(
                            roundNum(stockGLSelected.gains_24h, 0, 2, true, true)
                          )}
                          %)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.speedometerViewMob}>
                    <div className={styles.title1}>
                      <div className={styles.nameWrapper}>
                        <div className={styles.name1}>
                          <div className={styles.moversTitle1}>
                            Sentiment Score
                          </div>
                          <Tooltip
                            title='Sentiment score is determined based on news from various media outlets.  Plootus AI analyses this news and determines if the overall sentiment is positive or negative for Cryptocurrency/Security. '
                            render={(props) => (
                              <img
                                style={{
                                  width: '17px',
                                  height: '17px',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  marginLeft: '7px',
                                  marginTop: '3px'
                                }}
                                src='/question.png'
                                alt=''
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '350px',
                        height: '250px',
                        background: '#ffffff',
                        marginTop: '10px',
                        marginLeft: '-30px'
                      }}
                    >
                      <ReactSpeedometer
                        fluidWidth={true}
                        startColor='red'
                        endColor='green'
                        ringWidth={30}
                        minValue={-1}
                        maxValue={1}
                        maxSegmentLabels={0}
                        segments={1000}
                        value={stockGLSelected.sentiment_score}
                        needleColor='black'
                      />
                    </div>

                    <div className={styles.speedSellContUpperRange1}>
                      <div className={styles.speedSellBtnTxt}>-1</div>

                      <div className={styles.speedBuyBtnTxt}>1</div>
                    </div>
                    <div className={styles.speedSellContUpper}>
                      <div className={styles.speedSellBtn}>
                        <div className={styles.speedSellBtnTxt}>Sell</div>
                      </div>
                      <div className={styles.speedBuyBtn}>
                        <div className={styles.speedBuyBtnTxt}>Buy</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.speedometerViewMob}>
                    <div className={styles.title1}>
                      <div className={styles.nameWrapper}>
                        <div className={styles.name1}>
                          <div className={styles.moversTitle1}>
                            Analyst Score
                          </div>
                          <Tooltip
                            title='Analyst Score is based on fundamental analysis of the Stock or Security and reflects the future expectations of the Analysts over the next 12 months (Source: Marketwatch)'
                            render={(props) => (
                              <img
                                style={{
                                  width: '17px',
                                  height: '17px',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  marginLeft: '7px',
                                  marginTop: '3px'
                                }}
                                src='/question.png'
                                alt=''
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '350px',
                        height: '250px',
                        background: '#ffffff',
                        marginTop: '10px',
                        marginLeft: '-30px'
                      }}
                    >
                      <ReactSpeedometer
                        fluidWidth={true}
                        startColor='red'
                        endColor='green'
                        ringWidth={30}
                        minValue={1}
                        maxValue={5}
                        maxSegmentLabels={0}
                        segments={1000}
                        value={stockGLSelected.analyst_rating}
                        needleColor='black'
                      />
                    </div>
                    <div className={styles.speedSellContUpperRange1}>
                      <div className={styles.speedSellBtnTxt}>1</div>

                      <div className={styles.speedBuyBtnTxt}>5</div>
                    </div>
                    <div className={styles.speedSellContUpper}>
                      <div className={styles.speedSellBtn}>
                        <div className={styles.speedSellBtnTxt}>Sell</div>
                      </div>

                      <div className={styles.speedBuyBtn}>
                        <div className={styles.speedBuyBtnTxt}>Buy</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.stockOverall}>
                    <div className={styles.overallTitle}>
                      <div className={styles.nameWrapper}>
                        <div className={styles.overallName}>
                          <div className={styles.moversTitle1}>
                            Overall Trend
                          </div>
                          <Tooltip
                            title='Overall Trend is a combination of Sentiment score and Analyst score and reflects the overall future expectation of the cryptocurrency/Security.'
                            render={(props) => (
                              <img
                                style={{
                                  width: '17px',
                                  height: '17px',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  marginLeft: '7px',
                                  marginTop: '3px'
                                }}
                                src='/question.png'
                                alt=''
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <input
                      type='range'
                      name='range'
                      id='range'
                      min='0'
                      max='10'
                      value={stockGLSelected.overall_rating}
                    />
                    <img
                      src='/ruler.png'
                      style={{
                        width: '340px',
                        height: '20px',
                        marginLeft: '-20px'
                      }}
                    />
                    <div className={styles.speedSellContUpperRange2}>
                      <div className={styles.speedSellBtnTxt}>0</div>

                      <div className={styles.speedBuyBtnTxt}>10</div>
                    </div>
                    <div className={styles.SellCont}>
                      <div className={styles.sellBtn}>
                        <div className={styles.sellBtnTxt}>Sell</div>
                      </div>
                      <div className={styles.speedSellBtnTxt}>
                        {formatCommaString(
                          roundNum(
                            stockGLSelected.overall_rating,
                            0,
                            0,
                            true,
                            true
                          )
                        )}
                      </div>
                      <div className={styles.buyBtn}>
                        <div className={styles.buyBtnTxt}>Buy</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/*  Stock Details */}

          {width > 590 ? (
            <div className={styles.stock}>
              <div className={styles.myStockHeadercry}>
                <p className={styles.stockTextcry}>
                  {stockSelected.name} ({stockSelected.ticker})
                </p>
              </div>
              <div className={styles.mainCard2}>
                <div>
                  <div className={styles.stockNameUpper1}>
                    <div className={styles.stockCardDetail}>
                      <div className={styles.selectLightText}>
                        CURRENT PRICE($)
                      </div>
                      {stockSelected.current_price > 1 ? (
                        <div className={styles.selectDarkTexts}>
                          $
                          {formatCommaString(
                            roundNum(
                              stockSelected.current_price,
                              0,
                              2,
                              true,
                              true
                            )
                          )}
                        </div>
                      ) : (
                        <div className={styles.selectDarkTexts}>
                          $
                          {formatCommaString(
                            roundNum(
                              stockSelected.current_price,
                              0,
                              9,
                              true,
                              true
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.stockCardDetail}>
                      <div className={styles.selectLightText}>
                        TARGET PRICE($)
                      </div>
                      {stockSelected.current_price > 1 ? (
                        <div className={styles.selectDarkTexts}>
                          $
                          {formatCommaString(
                            roundNum(
                              stockSelected.target_price,
                              0,
                              2,
                              true,
                              true
                            )
                          )}
                        </div>
                      ) : (
                        <div className={styles.selectDarkTexts}>
                          $
                          {formatCommaString(
                            roundNum(
                              stockSelected.target_price,
                              0,
                              9,
                              true,
                              true
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.stockCardDetail}>
                      <div className={styles.selectLightText}>
                        MARKET CAP ($B)
                      </div>
                      <div className={styles.selectDarkTexts}>
                        ${stockSelected.mcap}
                      </div>
                    </div>
                    <div className={styles.stockCardDetail}>
                      <div className={styles.selectLightText}>
                        % GAIN (24 HRS)
                      </div>
                      <div className={styles.selectLightTextRow}>
                        <div
                          style={{ marginLeft: '5px' }}
                          className={
                            stockSelected.gains_24h > 0
                              ? styles.selectLightTextGreen
                              : styles.selectLightTextRed
                          }
                        >
                          (
                          {formatCommaString(
                            roundNum(stockSelected.gains_24h, 0, 2, true, true)
                          )}
                          %)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.stockOverall}>
                    <div className={styles.overallTitle}>
                      <div className={styles.nameWrapper}>
                        <div className={styles.overallName}>
                          <Tooltip
                            title='Overall Trend is a combination of Sentiment score and Analyst score and reflects the overall future expectation of the cryptocurrency/Security.'
                            render={(props) => (
                              <img
                                style={{
                                  width: '17px',
                                  height: '17px',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                  marginRight: '-13px',
                                  marginTop: '-11px'
                                }}
                                src='/question.png'
                                alt=''
                              />
                            )}
                          />
                          <div className={styles.moversTitle1}>
                            Overall Trend
                          </div>

                          <div className={styles.sellBtnTxtOverall}>
                            {formatCommaString(
                              roundNum(
                                stockSelected.overall_rating,
                                0,
                                0,
                                true,
                                true
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type='range'
                      name='range'
                      id='range'
                      min='0'
                      max='10'
                      value={stockSelected.overall_rating}
                    />
                    <img
                      src='/ruler.png'
                      style={{
                        width: '340px',
                        height: '20px',
                        marginLeft: '-20px'
                      }}
                    />
                    <div className={styles.speedSellContUpperRange3}>
                      <div className={styles.speedSellBtnTxt}>0</div>

                      <div className={styles.speedBuyBtnTxt}>10</div>
                    </div>
                    <div className={styles.SellCont}>
                      <div className={styles.sellBtn}>
                        <div className={styles.sellBtnTxt}>Sell</div>
                      </div>

                      <div className={styles.buyBtn}>
                        <div className={styles.buyBtnTxt}>Buy</div>
                      </div>
                    </div>
                  </div>
                  {/* <Redirect to='/crypto'> */}

                  {/* </Redirect> */}
                </div>
                {/* speedometer */}
                <div className={styles.speedBottom}>
                  <div className={styles.speedometerView}>
                    <div className={styles.speedTitle1}>
                      <div className={styles.nameWrapper}>
                        <div className={styles.name1}>
                          <div className={styles.moversTitle1}>
                            Sentiment Score
                          </div>
                          <Tooltip
                            title='Sentiment score is determined based on news from various media outlets.  Plootus AI analyses this news and determines if the overall sentiment is positive or negative for Cryptocurrency/Security. '
                            render={(props) => (
                              <img
                                style={{
                                  width: '17px',
                                  height: '17px',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  marginLeft: '7px',
                                  marginTop: '3px'
                                }}
                                src='/question.png'
                                alt=''
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '290px',
                        height: '190px',
                        background: '#ffffff',
                        marginTop: '50px',
                        marginLeft: '50px'
                      }}
                    >
                      <ReactSpeedometer
                        fluidWidth={true}
                        startColor='red'
                        endColor='green'
                        ringWidth={30}
                        minValue={-1}
                        maxValue={1}
                        maxSegmentLabels={0}
                        segments={1000}
                        value={stockSelected.sentiment_score}
                        needleColor='black'
                      />
                    </div>
                    <div className={styles.speedSellContUpperRange1}>
                      <div className={styles.speedSellBtnTxt}>-1</div>

                      <div className={styles.speedBuyBtnTxt}>1</div>
                    </div>
                    <div className={styles.speedSellCont}>
                      <div className={styles.speedSellBtn}>
                        <div className={styles.speedSellBtnTxt}>Sell</div>
                      </div>
                      <div className={styles.speedBuyBtn}>
                        <div className={styles.speedBuyBtnTxt}>Buy</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.speedometerView}>
                    <div className={styles.speedTitle}>
                      <div className={styles.nameWrapper}>
                        <div className={styles.name1}>
                          <div className={styles.moversTitle1}>
                            Analyst Score
                          </div>
                          <Tooltip
                            title='Analyst Score is based on fundamental analysis of the Stock or Security and reflects the future expectations of the Analysts over the next 12 months (Source: Marketwatch)'
                            render={(props) => (
                              <img
                                style={{
                                  width: '17px',
                                  height: '17px',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  marginLeft: '7px',
                                  marginTop: '3px'
                                }}
                                src='/question.png'
                                alt=''
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '290px',
                        height: '190px',
                        background: '#ffffff',
                        marginTop: '50px'
                      }}
                    >
                      <ReactSpeedometer
                        fluidWidth={true}
                        startColor='red'
                        endColor='green'
                        ringWidth={30}
                        minValue={1}
                        maxValue={5}
                        maxSegmentLabels={0}
                        segments={1000}
                        value={stockSelected.analyst_rating}
                        needleColor='black'
                      />
                    </div>
                    <div className={styles.speedSellContUpperRange2}>
                      <div className={styles.speedSellBtnTxt}>1</div>

                      <div className={styles.speedBuyBtnTxt}>5</div>
                    </div>
                    <div className={styles.speedSellCont1}>
                      <div className={styles.speedSellBtn}>
                        <div className={styles.speedSellBtnTxt}>Sell</div>
                      </div>
                      <div className={styles.speedBuyBtn}>
                        <div className={styles.speedBuyBtnTxt}>Buy</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* <div className={styles.msgBoxCont}>
            <div className={styles.msgBox}>
              
            </div>
          </div> */}
          {/* <div className={styles.msgBoxCont}>
            <Tooltip
              title=''
              render={(props) => (
                <img
                  style={{
                    width: '17px',
                    height: '17px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginLeft: '7px',
                    marginTop: '3px'
                  }}
                  src='/exclaim.svg'
                  alt=''
                />
              )}
            />
            <div>
              <p>
                By using this website/mobile application, you understand the
                information being presented is provided for informational
                purposes only and agree to our Terms of Use and Privacy Policy.
                Plootus relies on information from various sources believed to
                be reliable, including clients and third parties, but cannot
                guarantee the accuracy and completeness of that information.
                Nothing in this communication should be construed as an offer,
                recommendation, or solicitation to buy or sell any security.
                Additionally, Plootus does not provide tax advice and investors
                are advised to consult with a tax professional. Plootus’s
                financial advisory and planning services, provided to investors
                who become clients pursuant to a written agreement, are designed
                to aid our clients in preparing for their retirement and other
                financial goals. All investing involves risk, including the
                possible loss of money you invest, and past performance does not
                guarantee future performance.
              </p>
            </div>
          </div> */}
          {/* <div style={{ marginTop: '30%' }} /> */}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

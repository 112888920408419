import img1 from "../../Assets/ads/01_FairField-university.gif";
import img2 from "../../Assets/ads/02_New-Haven.gif";
import img3 from "../../Assets/ads/03_Yale-University.gif";
import img4 from "../../Assets/ads/04_Plutoo-Nike.gif";
import img5 from "../../Assets/ads/AE-09.png";
import img6 from "../../Assets/ads/DD.gif";
import img7 from "../../Assets/ads/FB-Ad.gif";
import img8 from "../../Assets/ads/Loreal-06.png";
import img9 from "../../Assets/ads/Mastercard-04.png";
import img10 from "../../Assets/ads/Mcd.gif";
import img11 from "../../Assets/ads/Starbucks-03.png";
import img12 from "../../Assets/ads/United-Airlines.gif";

const data = [
  {
    id: 1,
    img: img1,
    alt:
      "Investing for retirement at Fairfield University for worry free future",
  },
  {
    id: 2,
    img: img2,
    alt:
      "Consider Investing In your retirement at The University Of New Heaven",
  },
  {
    id: 3,
    img: img3,
    alt: "Invest for you Tension Free Retirement at Yale University",
  },
  {
    id: 4,
    img: img4,
    alt: "Have a Bright Retirement With Plootus Optimized Portfolio",
  },
  {
    id: 5,
    img: img5,
    alt:
      "Get You Retirement Planning Done Free of Cost and with best investment mix",
  },
  {
    id: 6,
    img: img6,
    alt:
      "Plootus Gives Free Portfolio allocation for an average American with 401k, 403b, 457 and IRA plans",
  },
  {
    id: 7,
    img: img7,
    alt:
      "Priortize retirement Planning With Free Algorithmic based Investment options",
  },
  {
    id: 8,
    img: img8,
    alt: "Save Fees for your Investement in 401k plan with right mix of option",
  },
  {
    id: 9,
    img: img9,
    alt:
      "Planning Retirement Is the most Negelected Part plan yours with Free Plootus AI based Allocation",
  },
  {
    id: 10,
    img: img10,
    alt:
      "Retirement Planning solutions with free Plootus calculator and algorithmic allocation",
  },
  {
    id: 11,
    img: img11,
    alt:
      "Invest in your retirement from today for exponential Growth With Free Plootus Advice",
  },
  {
    id: 12,
    img: img12,
    alt: "Fly in your Retirement period with Plootus's Right Investment Advice",
  },
];

export default data;

import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './SmallFutureModal.module.css';
import FutureExpenses from '../FutureExpenses/FutureExpenses';
import { BLUE_PLOOT } from '@plootus/common';

const SmallFutureModal = () => {
  const [isOpen, handleIsOpen] = useState(false);

  const closeModal = () => {
    handleIsOpen(false);
  };

  return (
    <div>
      <button
        onClick={() => handleIsOpen(true)}
        className={styles.btn}
        style={{ backgroundColor: BLUE_PLOOT }}
      >
        Retirement Expenses
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleIsOpen(false)}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.outer}>
          <div onClick={closeModal} className={styles.crossBtn}>
            <img src="/cross-thin.png" alt="" className={styles.close} />
          </div>
          <div className={styles.futOuter}>
            <FutureExpenses />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SmallFutureModal;

import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import styles from './LogIn.module.css';
import InputCard from './InputCard';
import Picker from '../FutureExpenses/Picker/Picker';
import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';

const rows = [
  {
    label: 'Login',
  },
  {
    label: 'Login as user',
  },
  {
    label: 'Login as advisor',
  },
];

const LogIn = ({
  modalIsOpen,
  openModal,
  closeModal,
  signupopenModal,
  fromFees,
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { loginModal, idx, setIdx, setLoginModal } = useContext(
    LoginSignupContext
  );

  const handleClick = (idx) => {
    setIdx(idx);
    setLoginModal(true);
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        {fromFees ? (
          <div className={styles.fromFeesBtn} onClick={() => openModal()}>
            LogIn
          </div>
        ) : (
          <Picker
            rows={rows}
            index={idx}
            showIndex={true}
            visible={visible}
            handlePress={() => setVisible((prev) => !prev)}
            contStyle={{
              border: '1px solid #416ce1',
              borderRadius: '5px',
              width: '120px',
            }}
            headerStyle={{ borderBottom: '0px' }}
            dropdownStyle={{ width: '160px', height: '90px' }}
            itemStyle={{ fontSize: '15px', padding: '0px', height: '50px' }}
            handleClick={handleClick}
            isLogin={true}
            titleStyle={{ textAlign: 'center' }}
          />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <div>
          <div className={styles.closeContainer}>
            <button
              onClick={() => {
                if (!loading) {
                  closeModal();
                }
              }}
              className={styles.closeButton}
              disabled={loading}
            >
              <img
                className={styles.crossButton}
                src="/cross-thin.png"
                alt="Close"
              />
            </button>
          </div>
          <div className={styles.headingContainer}>
            <div className={styles.heading}>
              <h3>Welcome Back!</h3>
            </div>
            <div className={styles.belowHeader}>
              Please login to your account
            </div>
          </div>
          <InputCard
            closeModal={closeModal}
            signupopenModal={signupopenModal}
            setLoading={setLoading}
            loading={loading}
            index={idx}
            loginStyle={{ paddingBottom: '30px' }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default LogIn;

// import React, { useEffect, useState, useContext } from 'react';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
// import styles from './StaticEmployer.module.css';
// import EmployeeSvg from '../../Assets/employee.svg';
// import Fsvg from '../../Assets/fb.png';
// import SearchBox401k from '../../Utils/Search401kNew/SearchBox401kNew';
// import EmpCarousel from './EmpCarousel';
// import FeesGraph from '../Dashboard/Graphs/FeesGraph';
// import { Card } from '@material-ui/core';
// import axios from 'axios';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Legend,
//   Tooltip as TP,
//   ResponsiveContainer,
//   Area,
//   AreaChart,
//   linearGradient,
//   defs
// } from 'recharts';
// import { useSelector } from 'react-redux';
// import {
//   BLUE_PLOOT,
//   currentUserDataSelector,
//   store,
//   usePickerModal,
//   strategy_constants,
//   employerNewDataSelector
// } from '@plootus/common';

// import Picker from '../../Utils/Containers/Picker/Picker';
// import NewPie from '../../Utils/Charts/NewPie/NewPie';
// import FundsTable1 from './FundsTable';
// import FundsTable from '../Employer/FundsTable';
// import MessageBox from '../Employer/MessageBox/MessageBox';
// import MainSignupScreen from '../SignUp/MainScreen';
// import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
// import useEmployerSearch from '../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';
// import { Helmet } from 'react-helmet';
// import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';
// import Tooltip from '../../Utils/Tooltip/Tooltip';

// const StaticEmployer = () => {
//   const { width } = useWindowDimensions();
//   const data3 = [
//     { name: '2016-02-29 ', plootus: 10000, benchmark: 10000 },
//     {
//       name: '2016-03-31 ',
//       plootus: 10152.9833701802,
//       benchmark: 10153.6885245902
//     },
//     {
//       name: '2016-04-29 ',
//       plootus: 10234.405322186,
//       benchmark: 10204.1426113712
//     },
//     {
//       name: '2016-05-31 ',
//       plootus: 10238.3420798313,
//       benchmark: 10163.9819688009
//     },
//     {
//       name: '2016-06-30 ',
//       plootus: 10388.3943344966,
//       benchmark: 10325.2722913816
//     },
//     {
//       name: '2016-07-29 ',
//       plootus: 10467.9378421608,
//       benchmark: 10384.7961009054
//     },
//     {
//       name: '2016-08-31 ',
//       plootus: 10492.3584233408,
//       benchmark: 10355.2103020888
//     },
//     {
//       name: '2016-09-30 ',
//       plootus: 10509.1221810282,
//       benchmark: 10345.319105254
//     },
//     {
//       name: '2016-10-31 ',
//       plootus: 10462.717607462,
//       benchmark: 10266.1111844619
//     },
//     {
//       name: '2016-11-30 ',
//       plootus: 10263.6290775706,
//       benchmark: 9986.67006669743
//     },
//     {
//       name: '2016-12-30 ',
//       plootus: 10204.2704840193,
//       benchmark: 9884.00066217998
//     },
//     {
//       name: '2017-01-31 ',
//       plootus: 10253.8299880003,
//       benchmark: 9904.74755014678
//     },
//     {
//       name: '2017-02-28 ',
//       plootus: 10327.5574007505,
//       benchmark: 9966.85935138902
//     },
//     {
//       name: '2017-03-31 ',
//       plootus: 10331.0036641343,
//       benchmark: 9946.28321970178
//     },
//     {
//       name: '2017-04-28',
//       plootus: 10415.0147957079,
//       benchmark: 10018.4481681554
//     },
//     {
//       name: '2017-05-31 ',
//       plootus: 10479.3360582328,
//       benchmark: 10069.6252408268
//     },
//     {
//       name: '2017-06-30 ',
//       plootus: 10475.2335992698,
//       benchmark: 10028.8920432708
//     },
//     {
//       name: '2017-07-31 ',
//       plootus: 10538.7165430173,
//       benchmark: 10090.2417365224
//     },
//     {
//       name: '2017-08-31 ',
//       plootus: 10615.6811647942,
//       benchmark: 10141.0547446524
//     },
//     {
//       name: '2017-09-29 ',
//       plootus: 10585.6155341005,
//       benchmark: 10070.2761804462
//     },
//     {
//       name: '2017-10-31 ',
//       plootus: 10598.6406058805,
//       benchmark: 10070.2761804462
//     },
//     {
//       name: '2017-11-30 ',
//       plootus: 10591.9066812768,
//       benchmark: 10009.1763841122
//     },
//     {
//       name: '2017-12-29 ',
//       plootus: 10614.6006750172,
//       benchmark: 9998.93048247281
//     },
//     {
//       name: '2018-01-31 ',
//       plootus: 10532.145998732,
//       benchmark: 9855.34073888307
//     },
//     {
//       name: '2018-02-28 ',
//       plootus: 10461.330324017,
//       benchmark: 9740.87663898712
//     },
//     {
//       name: '2018-03-29 ',
//       plootus: 10505.195876864,
//       benchmark: 9740.87663898712
//     },
//     {
//       name: '2018-04-30 ',
//       plootus: 10448.9534617294,
//       benchmark: 9635.61348109239
//     },
//     {
//       name: '2018-05-31 ',
//       plootus: 10496.1013266336,
//       benchmark: 9667.52837470941
//     },
//     {
//       name: '2018-06-29 ',
//       plootus: 10488.0615053667,
//       benchmark: 9625.11055922691
//     },
//     {
//       name: '2018-07-31 ',
//       plootus: 10520.3998779379,
//       benchmark: 9635.76018648995
//     },
//     {
//       name: '2018-08-31 ',
//       plootus: 10551.0612120091,
//       benchmark: 9678.31337797931
//     },
//     {
//       name: '2018-09-28 ',
//       plootus: 10512.7978962726,
//       benchmark: 9604.16083560643
//     },
//     {
//       name: '2018-10-31 ',
//       plootus: 10446.198106863,
//       benchmark: 9518.78196687644
//     },
//     {
//       name: '2018-11-30 ',
//       plootus: 10483.7536800201,
//       benchmark: 9508.01770422843
//     },
//     {
//       name: '2018-12-31 ',
//       plootus: 10618.326112112,
//       benchmark: 9626.55218698705
//     },
//     {
//       name: '2019-01-31 ',
//       plootus: 10760.1362436359,
//       benchmark: 9764.99734140665
//     },
//     {
//       name: '2019-02-28 ',
//       plootus: 10786.9191180089,
//       benchmark: 9764.99734140665
//     },
//     {
//       name: '2019-03-29 ',
//       plootus: 10941.9102419004,
//       benchmark: 9933.0645682974
//     },
//     {
//       name: '2019-04-30 ',
//       plootus: 10960.4927419769,
//       benchmark: 9943.3951468098
//     },
//     {
//       name: '2019-05-31 ',
//       plootus: 11097.3818736103,
//       benchmark: 10067.2341560977
//     },
//     {
//       name: '2019-06-28 ',
//       plootus: 11219.4627640571,
//       benchmark: 10209.945674956
//     },
//     {
//       name: '2019-07-31 ',
//       plootus: 11250.0099718007,
//       benchmark: 10199.8954236998
//     },
//     {
//       name: '2019-08-30 ',
//       plootus: 11454.2540495039,
//       benchmark: 10401.1026671605
//     },
//     {
//       name: '2019-09-30 ',
//       plootus: 11427.5917555794,
//       benchmark: 10322.2072036497
//     },
//     {
//       name: '2019-10-31 ',
//       plootus: 11453.352691138,
//       benchmark: 10352.0282772083
//     },
//     {
//       name: '2019-11-29 ',
//       plootus: 11458.8147364363,
//       benchmark: 10322.2958688832
//     },
//     {
//       name: '2019-12-31 ',
//       plootus: 11465.7970838941,
//       benchmark: 10312.3555110304
//     },
//     {
//       name: '2020-01-31 ',
//       plootus: 11651.7216667137,
//       benchmark: 10491.4599886423
//     },
//     {
//       name: '2020-02-28 ',
//       plootus: 11757.6157624697,
//       benchmark: 10608.7620414282
//     },
//     {
//       name: '2020-03-31 ',
//       plootus: 11318.0170127502,
//       benchmark: 10144.9939254862
//     },
//     {
//       name: '2020-04-30 ',
//       plootus: 11557.7894964626,
//       benchmark: 10469.2087177861
//     },
//     {
//       name: '2020-05-29 ',
//       plootus: 11715.06892733,
//       benchmark: 10655.6660288754
//     },
//     {
//       name: '2020-06-30 ',
//       plootus: 11851.2729659841,
//       benchmark: 10790.5407880276
//     },
//     {
//       name: '2020-07-31 ',
//       plootus: 12031.2386712053,
//       benchmark: 10952.1377462025
//     },
//     {
//       name: '2020-08-31 ',
//       plootus: 12018.3878470166,
//       benchmark: 10905.3650614504
//     },
//     {
//       name: '2020-09-30 ',
//       plootus: 12006.6695043134,
//       benchmark: 10877.1695727286
//     },
//     {
//       name: '2020-10-30 ',
//       plootus: 11993.5287649851,
//       benchmark: 10839.4692899765
//     },
//     {
//       name: '2020-11-30 ',
//       plootus: 12154.5023300727,
//       benchmark: 10962.4588831648
//     },
//     {
//       name: '2020-12-31 ',
//       plootus: 12144.8754884979,
//       benchmark: 10747.5056121367
//     },
//     {
//       name: '2021-01-29 ',
//       plootus: 12089.9601121925,
//       benchmark: 10671.0968251262
//     },
//     {
//       name: '2021-02-03 ',
//       plootus: 12080.9078808175,
//       benchmark: 10661.4721860502
//     }
//   ];
//   const theme = createMuiTheme({
//     palette: {
//       primary: { main: BLUE_PLOOT } // Purple and green play nicely together.
//     },
//     typography: { useNextVariants: true }
//   });
//   const props = useEmployerSearch();
//   const { funds } = useSelector(employerNewDataSelector);
//   const [strategy, setStrategy] = useState(0);
//   const [prevS, setPrevS] = useState(strategy);
//   const [loadingPie, setLoadingPie] = useState(false);
//   const [empName, setEmpName] = useState();
//   const [empEin, setEmpEin] = useState();
//   const [kgraph, setKGraph] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [pickerval, setPickerVal] = useState(0);
//   const employerRedux = useSelector(employerNewDataSelector);

//   // const { employer_selected, selected_ein } = useSelector(
//   //   employerNewDataSelector
//   // );

//   const { employer_selected } = employerRedux;

//   var empImages = [];

//   const { picker, negativeModal, setIndex } = usePickerModal(
//     (idx) => {
//       setStrategy(idx);
//     },
//     strategy,
//     true
//   );
//   const pickerval1 = localStorage.getItem('100kgraph');
//   useEffect(() => {
//     try {
//       setPickerVal(localStorage.getItem('100kgraph'));
//       graphFunction();
//     } catch (e) {
//       console.log(e);
//       setLoading(false);
//     }
//   }, [pickerval1]);

//   useEffect(() => {
//     setLoadingPie(true);
//     setTimeout(() => {
//       setPrevS(strategy);
//       setLoadingPie(false);
//     }, 1000);
//   }, [strategy]);

//   const graphFunction = () => {
//     const emps = JSON.parse(localStorage.getItem('empData'));

//     setEmpEin(emps.ein);
//     setEmpName(emps.name);
//     console.log(pickerval);
//     console.log(emps);
//     axios
//       .get(`https://www.plootus.com/api/employee/getreturns?ein=201665019`)
//       .then((response) => {
//         console.log(response.data.graphData);
//         if (pickerval == 5) {
//           setKGraph(response.data.graphData[0]);
//         } else {
//           setKGraph(response.data.graphData[pickerval]);
//         }
//         console.log(kgraph);
//       })
//       .catch((e) => {
//         console.log(e);
//         setLoading(false);
//       });
//   };
//   useEffect(() => {
//     console.log(kgraph);
//   });
//   // useEffect(() => {
//   //   if (props.data?.length) {
//   //     for (var i = 0; i < props.data.length; i++) {
//   //       empImages.push(
//   //         `https://www.plootus.com/iconImages/${props.data[i].ein}.png`
//   //       );
//   //     }
//   //     console.log(empImages);
//   //   }
//   // }, [props.data]);

//   /* For Modal */
//   const { signupModal, setSignupModal, setLoginModal } =
//     useContext(LoginSignupContext);

//   const loginopenModal = () => {
//     setLoginModal(true);
//   };

//   const openModal = () => {
//     setSignupModal(true);
//   };

//   const closeModal = () => {
//     setSignupModal(false);
//   };

//   if (loading) {
//     return (
//       <div
//         style={{
//           width: '99vw',
//           height: '100vh',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center'
//         }}
//       >
//         <MuiThemeProvider theme={theme}>
//           <CircularProgress size={50} />
//         </MuiThemeProvider>
//       </div>
//     );
//   }

//   return (
//     <>
//       {/* {props.employer_selected !== '' && !props.data?.length && funds.length ? ( */}
//       <div>
//         <div className={styles.row123}>
//           <img
//             className={styles.empImg1}
//             src={`https://www.plootus.com/iconImages/${empEin}.png`}
//             alt=''
//           ></img>
//           <div className={styles.title1}>
//             <div className={styles.mainText1}>{empName}</div>
//             <div className={styles.einText1}>(EIN-{empEin})</div>
//           </div>
//         </div>
//         <div className={styles.row11}>
//           {/*  <div className={styles.column11}>
//               <div className={styles.tableOuter}>
//                 <div className={styles.tableInner}>
//                   <FundsTable1 strategy={strategy} />
//                 </div>
//               </div>
//             </div>
//             <div className={styles.column21}>
//               <h3 className={styles.stepper}>About the Plan</h3>
//               <br />

//               <ul>
//                 <li style={{ fontSize: 22 }}>
//                   Plootus rates this plan a 4.3/5 placing it among top plans in
//                   its peer group. Also, this plan makes into the top 15% of
//                   plans of company generosity, Par cipa on Rate, Salary
//                   Deferral, and Total plan cost.
//                 </li>
//                 <div style={{ marginTop: 10 }} />
//                 <li style={{ fontSize: 22 }}>
//                   Facebook 401k currently covers 30,200 ac ve par cipants and
//                   over $1.8B in plan assets.
//                 </li>
//               </ul>
//             </div>*/}
//         </div>
//         <div className={styles.pickPie}>
//           <div className={styles.pickerOuter}>
//             <div className={styles.outerPicker}>
//               <div className={styles.pickerHead}>Select Your Strategy</div>
//               <div className={styles.pickerActual}>
//                 <Picker
//                   rows={strategy_constants}
//                   {...picker}
//                   handlePress={negativeModal}
//                   setIndex={setIndex}
//                   myStyle={
//                     width < 1030
//                       ? {
//                           padding: '0px',
//                           width: '200px',
//                           marginLeft: width < 576 ? '20px' : '0px',
//                           marginTop: '10px',
//                           marginBottom: '20px'
//                         }
//                       : { padding: '0px' }
//                   }
//                   myStyle2={{
//                     margin: '0%',
//                     width: width < 1300 ? (width < 1024 ? '70%' : '40%') : '30%'
//                   }}
//                   myStyle3={{ width: width < 1300 ? '150%' : '100%' }}
//                   myStyle4={{ width: width < 1300 ? '150%' : '100%' }}
//                 />
//               </div>
//             </div>
//             <div className={styles.empty1}></div>
//           </div>
//         </div>
//         {/* <div className={styles.row11}> */}
//         <div className={styles.column31}>
//           <div className={styles.feesGraph1}>
//             <FeesGraph coming={false} strategy={3} fromStatic={true} />
//           </div>
//           {/* </div> */}
//           <div className={styles.column41}>
//             <Card
//               className={styles.mainCard}
//               style={{
//                 borderRadius: '10px',
//                 boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)'
//               }}
//             >
//               {/* <AreaChart width={1030} height={450} data={data3}
//   margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
//   <defs>
//     <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//       <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
//       <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
//     </linearGradient>
//     <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
//       <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
//       <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
//     </linearGradient>
//   </defs>
//   <XAxis dataKey="name" />
//   <YAxis />
//   <CartesianGrid strokeDasharray="3 3" />
//   <Tooltip />
//   <Area type="monotone" dataKey="plootus" stroke="red"   />
//   <Area type="monotone"  dataKey="benchmark" stroke="blue"   />
// </AreaChart> */}
//               <div className={styles.title}>
//                 <div className={styles.nameWrapper}>
//                   <div className={styles.name}>
//                     <div className={styles.headerFees}>Growth of $10,000</div>
//                     <Tooltip
//                       title="The Growth of $10,000 graph shows a fund's performance based on how $10,000 invested in the fund would have grown over time with dividends reinvested. The returns used in the graph are not load-adjusted "
//                       render={(props) => (
//                         <img
//                           style={{
//                             width: '15px',
//                             height: '15px',
//                             alignItems: 'center',
//                             cursor: 'pointer'
//                           }}
//                           src='/question.png'
//                           alt=''
//                         />
//                       )}
//                     />
//                   </div>
//                 </div>
//               </div>
//               {window.innerWidth > 576 ? (
//                 <LineChart
//                   width={700}
//                   height={250}
//                   data={kgraph}
//                   margin={{ right: 30, left: 0, bottom: 0 }}
//                 >
//                   <CartesianGrid strokeDasharray='3 3' />
//                   <XAxis dataKey='date' minTickGap={20} tickCount={5} />
//                   <YAxis domain={[8000, 'auto']} />
//                   <TP />

//                   <Line
//                     type='monotone'
//                     dataKey='superconservative'
//                     stroke='blue'
//                     dot={false}
//                   />
//                   <Line
//                     type='monotone'
//                     dataKey='VFICX'
//                     stroke='red'
//                     dot={false}
//                   />
//                 </LineChart>
//               ) : (
//                 <LineChart
//                   width={270}
//                   height={240}
//                   data={kgraph}
//                   margin={{ right: 10, left: -15, bottom: 0 }}
//                 >
//                   <CartesianGrid strokeDasharray='3 3' />
//                   <XAxis
//                     dataKey='date'
//                     minTickGap={20}
//                     tickCount={5}
//                     style={{ fontSize: 10 }}
//                   />
//                   <YAxis domain={[8000, 'auto']} style={{ fontSize: 11 }} />
//                   <TP contentStyle={{ fontSize: 8 }} />

//                   <Line
//                     type='monotone'
//                     dataKey='superconservative'
//                     stroke='blue'
//                     dot={false}
//                   />
//                   <Line
//                     type='monotone'
//                     dataKey='VFICX'
//                     stroke='red'
//                     dot={false}
//                   />
//                 </LineChart>
//               )}
//             </Card>
//           </div>
//         </div>
//         <div className={styles.row10}>
//           {/* {props.employer_selected !== '' &&
//            !props.data?.length &&
//            funds.length ? (
//              <> */}

//           <div className={styles.tableOuter}>
//             <div className={styles.tableInner}>
//               <FundsTable strategy={strategy} />
//             </div>
//           </div>
//           {/* </>
//            ) : null} */}
//         </div>
//         <div className={styles.empty1}></div>
//         <div className={styles.empty1}></div>
//         <div className={styles.empty1}></div>
//       </div>
//       {/* ) : (
//         <div className={funds.length ? styles.root : styles.invisibleRoot}>
//           <Helmet>
//             <meta charSet='utf-8' />
//             <title>
//               401k IRA 403b 457 Financial Investment Advisors | Plootus
//             </title>
//             <meta
//               name='description'
//               content='Professional Investment Advisors to give advice beyond traditional retirement. Analyze your 401k, IRA, 430b and 457 options. Simply add your employer to get your free financial advice.
//                 '
//             />
//             <meta
//               name='keywords'
//               content='401k plan, 403b plan, Retirement Planning, Financial Planning, 401k rollover, 401k early withdrawl, 457 plan, TSP plan,401k, 403b, early retirement, student loan
//                 '
//             />
//           </Helmet>

//           <div className={styles.row1}>
//             <div className={styles.search}>
//               <div className={styles.searchParent}>
//                 Search Your Employer
//                 <SearchBox401k {...props} fromStatic={true} dontNull={true} />
//               </div>
//               <div className={styles.empty}>
//                 {props.employer_selected !== '' &&
//                 !props.data?.length &&
//                 !funds.length ? (
//                   <div
//                     className={styles.messageBox}
//                     style={{ marginRight: '20px' }}
//                   >
//                     <MessageBox />
//                   </div>
//                 ) : null}
//               </div>
//             </div>
//             <div className={styles.image}>
//               <img className={styles.innerImage} src={EmployeeSvg} alt='' />
//             </div>
//           </div>
//           {props.data?.length
//             ? empImages.map((item, index) => {
//                 return (
//                   <>
//                     <div className={styles.row12}>
//                       <img
//                         className={styles.empImg}
//                         src={{ URL: item }}
//                         alt=''
//                       ></img>
//                     </div>
//                   </>
//                 );
//               })
//             : null}

//           <div className={styles.row2}>
//             {props.employer_selected !== '' &&
//             !props.data?.length &&
//             funds.length ? (
//               <>
//                 <div className={styles.pickPie}>
//                   <div className={styles.pickerOuter}>
//                     <div className={styles.outerPicker}>
//                       <div className={styles.pickerHead}>
//                         Select Your Strategy
//                       </div>
//                       <div className={styles.pickerActual}>
//                         <Picker
//                           rows={strategy_constants}
//                           {...picker}
//                           handlePress={negativeModal}
//                           setIndex={setIndex}
//                           myStyle={
//                             width < 1030
//                               ? {
//                                   padding: '0px',
//                                   width: '200px',
//                                   marginLeft: width < 576 ? '20px' : '0px',
//                                   marginTop: '10px',
//                                   marginBottom: '20px'
//                                 }
//                               : { padding: '0px' }
//                           }
//                           myStyle2={{
//                             margin: '0%',
//                             width:
//                               width < 1300
//                                 ? width < 1024
//                                   ? '70%'
//                                   : '40%'
//                                 : '30%'
//                           }}
//                           myStyle3={{ width: width < 1300 ? '150%' : '100%' }}
//                           myStyle4={{ width: width < 1300 ? '150%' : '100%' }}
//                         />
//                       </div>
//                     </div>
//                     <div className={styles.empty1}></div>
//                   </div>
//                 </div>
//                 <div className={styles.tableOuter}>
//                   <div className={styles.tableInner}>
//                     <FundsTable strategy={strategy} />
//                     <div className={styles.blur}>
//                       <div className={styles.btnOuter}>
//                         <MainSignupScreen
//                           modalIsOpen={signupModal}
//                           openModal={openModal}
//                           closeModal={closeModal}
//                           isInEmployeer={true}
//                           loginopenModal={loginopenModal}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </>
//             ) : null}
//           </div>
//           <div className={styles.empty}></div>
//           <div className={styles.adds}>
//             <EmpCarousel />
//           </div>
//         </div>
//       )} */}
//     </>
//   );
// };

// export default StaticEmployer;

import React, { useEffect, useState, useContext } from 'react';
import styles from './StaticEmployer.module.css';
import EmployeeSvg from '../../Assets/employee.svg';
import SearchBox401k from '../../Utils/Search401kNew/SearchBox401kNew';
import EmpCarousel from './EmpCarousel';
import Fsvg from '../../Assets/fb.png';
import { useSelector, useDispatch } from 'react-redux';
import {
  usePickerModal,
  strategy_constants,
  fetch_allocations,
  employerNewDataSelector
} from '@plootus/common';
import Picker from '../../Utils/Containers/Picker/Picker';
import NewPie from '../../Utils/Charts/NewPie/NewPie';
import FundsTable from '../Employer/FundsTable';
import MessageBox from '../Employer/MessageBox/MessageBox';
import MainSignupScreen from '../SignUp/MainScreen';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import useEmployerSearch from '../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';

import { Helmet } from 'react-helmet';
import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';

const StaticEmployer = () => {
  const { width } = useWindowDimensions();

  const props = useEmployerSearch();
  const { funds } = useSelector(employerNewDataSelector);
  const [strategy, setStrategy] = useState(0);
  const [prevS, setPrevS] = useState(strategy);
  const [loadingPie, setLoadingPie] = useState(false);
  useEffect(() => {
    setLoadingPie(true);
    setTimeout(() => {
      setPrevS(strategy);
      setLoadingPie(false);
    }, 1000);
  }, [strategy]);
  const dispatch = useDispatch();
  const { picker, negativeModal, setIndex } = usePickerModal(
    (idx) => {
      setStrategy(idx);
    },
    strategy,
    true
  );

  /* For Modal */
  const { signupModal, setSignupModal, setLoginModal } =
    useContext(LoginSignupContext);

  const loginopenModal = () => {
    setLoginModal(true);
  };

  const openModal = () => {
    setSignupModal(true);
  };

  const closeModal = () => {
    setSignupModal(false);
  };

  const empLogos = [
    {
      amazon: require('../../Assets/employee-logos/amazon.png'),
      ein: '911986545',
      name: 'AMAZON.COM SERVICES,INC. 401k',
    },
    {
      amazon: require('../../Assets/employee-logos/ge.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/fb.png'),
      ein: '201665019',
      name: 'FACEBOOK, INC. 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/testla.png'),
      ein: '912197729',
      name: 'TESLA, INC. 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/apple.png'),
      ein: '942404110',
      name: 'APPLE INC. 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/star.png'),
      ein: '911325671',
      name: 'STARBUCKS CORPORATION 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/nissan.png'),
      ein: '952108010',
      name: 'NISSAN NORTH AMERICA, INC. 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/newyork.png'),
      ein: '135562308',
      name: 'NEW YORK UNIVERSITY 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/fairuni.png'),
      ein: '60646623',
      name: 'FAIRFIELD UNIVERSITY 403B'
    },
    {
      amazon: require('../../Assets/employee-logos/alabama.png'),
      ein: '999994609',
      name: 'THE UNIVERSITY OF ALABAMA SYSTEM 403(B) PLAN'
    },
    {
      amazon: require('../../Assets/employee-logos/sacri.png'),
      ein: '140689340',
      name: 'Sacred Heart University 403b plan'
    },
    {
      amazon: require('../../Assets/employee-logos/michan.png'),
      ein: '999994755',
      name: 'UNIVERSITY OF MICHIGAN 401A RETIREMENT PLAN'
    }
  ];

  return (
    <div className={funds.length ? styles.root : styles.invisibleRoot}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>401k IRA 403b 457 Financial Investment Advisors | Plootus</title>
        <meta
          name='description'
          content='Professional Investment Advisors to give advice beyond traditional retirement. Analyze your 401k, IRA, 430b and 457 options. Simply add your employer to get your free financial advice.
          '
        />
        <meta
          name='keywords'
          content='401k plan, 403b plan, Retirement Planning, Financial Planning, 401k rollover, 401k early withdrawl, 457 plan, TSP plan,401k, 403b, early retirement, student loan
          '
        />
      </Helmet>

      <div className={styles.row1}>
        <div className={styles.search}>
          <div className={styles.searchParent}>
            <SearchBox401k {...props} fromStatic={true} dontNull={true} />
          </div>
          <div className={styles.empty1}>
            {props.employer_selected !== '' &&
            !props.data?.length &&
            !funds.length ? (
              <div
                className={styles.messageBox}
                style={
                  width > 630
                    ? { marginRight: '20px', marginTop: '100px' }
                    : { marginTop: '200px' }
                }
              >
                <MessageBox />
              </div>
            ) : null}
          </div>
        </div>

        <div className={styles.image}>
          <img className={styles.innerImage} src={EmployeeSvg} alt='' />
        </div>
      </div>

      {props.employer_selected !== '' &&
      !props.data?.length &&
      funds.length ? null : (
        <>
          <div className={styles.row12}>
            <h5 className={styles.spe}>Popular 401k and 403b Searches</h5>
            {empLogos.map((item, key) => {
              return (
                <>
                  <img
                    onClick={async () => {
                      await dispatch(
                        fetch_allocations(
                          {
                            ein: item.ein,
                            name: item.name.split(' (EIN')[0],
                            showLimitExceedError: true
                          },
                          true
                        )
                      );
                    }}
                    className={styles.empImg}
                    src={item.amazon}
                    alt={item.name}
                    title={`Plootus: ${item.name}`}
                  ></img>
                </>
              );
            })}
          </div>
        </>
      )}

      <div className={styles.row2}>
        {props.employer_selected !== '' &&
        !props.data?.length &&
        funds.length ? (
          <>
            <div className={styles.pickPie}>
              <div className={styles.pickerOuter}>
                <div className={styles.outerPicker}>
                  <div className={styles.pickerHead}>Select Your Strategy</div>
                  <div className={styles.pickerActual}>
                    <Picker
                      rows={strategy_constants}
                      {...picker}
                      handlePress={negativeModal}
                      setIndex={setIndex}
                      myStyle={
                        width < 1030
                          ? {
                              padding: '0px',
                              width: '200px',
                              marginLeft: width < 576 ? '20px' : '0px',
                              marginTop: '10px',
                              marginBottom: '20px'
                            }
                          : { padding: '0px' }
                      }
                      myStyle2={{
                        margin: '0%',
                        width:
                          width < 1300 ? (width < 1024 ? '70%' : '40%') : '30%'
                      }}
                      myStyle3={{ width: width < 1300 ? '150%' : '100%' }}
                      myStyle4={{ width: width < 1300 ? '150%' : '100%' }}
                    />
                  </div>
                </div>
                <div className={styles.empty1}></div>
              </div>
            </div>
            <div className={styles.tableOuter}>
              <div className={styles.tableInner}>
                <FundsTable strategy={strategy} />
                <div className={styles.blur}>
                  <div className={styles.btnOuter}>
                    <MainSignupScreen
                      modalIsOpen={signupModal}
                      openModal={openModal}
                      closeModal={closeModal}
                      isInEmployeer={true}
                      loginopenModal={loginopenModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div
        className={styles.empty}
        style={
          props.employer_selected !== '' && !props.data?.length && funds.length
            ? { marginTop: width < 576 ? '50px' : '40px' }
            : { marginTop: width < 576 ? '50px' : '440px' }
        }
      ></div>
      <div className={styles.adds}>
        <EmpCarousel />
      </div>
    </div>
  );
};

export default StaticEmployer;

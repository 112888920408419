"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("./types");
var initialState = {
    employer: {
        employer_list: [],
        funds: [],
        value: '',
        visible: false,
        showFunds: false,
        employer_selected: false,
        funds_load: false,
        search_loading: false,
    },
    futureExpenses: {
        total: null,
        listData: null,
        currentTotal: null,
        futureTotal: null,
        graphData: null,
        inflation: 2,
    },
    employerNew: {
        funds: [],
        employer_selected: '',
        selected_ein: null,
    },
};
exports.default = (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case types_1.GENERAL_EMPLOYER:
            return __assign(__assign({}, state), { employer: __assign(__assign({}, state.employer), action.payload) });
        case types_1.GENERAL_FUTURE_EXPENSES_TABLE:
            return __assign(__assign({}, state), { futureExpenses: __assign(__assign({}, state.futureExpenses), action.payload) });
        case types_1.GENERAL_EMPLOYER_NEW:
            return __assign(__assign({}, state), { employerNew: __assign(__assign({}, state.employerNew), action.payload) });
        case types_1.RESET_GENERAL:
            return __assign(__assign({}, initialState), { employer: __assign(__assign({}, initialState.employer), { employer_list: [], funds: [] }), futureExpenses: __assign({}, initialState.futureExpenses), employerNew: __assign({}, initialState.employerNew) });
        default:
            return state;
    }
});

import React from "react";
import BarGraph from "../../../Utils/Charts/BarGraph/BarGraph";
import { GREEN_PLOOT, DARK_RED_PLOOT } from "@plootus/common";
import useWindowDimensions from "../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";

function CashFlowGraph(props) {
  //console.log(props.data);

  const styleB = { height: "100%", width: "100%" };

  const styleS = { height: "115%", width: "115%", marginLeft: "-40px" };

  const { width, height } = useWindowDimensions();
  return (
    <div style={width <= 768 ? styleS : styleB}>
      <BarGraph
        sampleData={props.data}
        color1={GREEN_PLOOT}
        color2={DARK_RED_PLOOT}
        barRatio={0.8}
        gType="cash"
        showTickX={true}
      />
    </div>
  );
}

export default CashFlowGraph;

import { GREEN_PLOOT, OFF_WHITE } from '@plootus/common';
import React, { useRef } from 'react';
import MyText from '../Texts/MyText';
import ErrorBelow from './ErrorBelow';
import styles from './InputText.module.css';

const RED = 'red';

const InputText = (props) => {
  const {
    containerStyle,
    titleStyle,
    textInputStyle,
    config,
    title,
    err,
    type,
    typed,
    emailExist,
    closeModal,
    loginopenModal,
  } = props;

  const ipRef = useRef(null);
  const showError = typed && err;

  return (
    <div className={styles.container} style={{ ...containerStyle }}>
      {title ? (
        <MyText className={styles.title} style={{ ...titleStyle }}>
          {title}
        </MyText>
      ) : (
        <MyText className={styles.title} style={{ ...titleStyle }} />
      )}
      <input
        type={type}
        ref={ipRef}
        className={styles.inputContainer}
        style={{
          ...{ textDecoration: 'none !important', outline: 'none' },
          ...textInputStyle,
          ...(showError ? { borderColor: err?.err ? RED : GREEN_PLOOT } : null),
          ...(!(config?.editable || true) && { backgroundColor: OFF_WHITE }),
        }}
        {...config}
      />
      {emailExist ? (
        <div
          style={{
            fontSize: '10px',
            alignSelf: 'center',
            width: '90%',
            padding: '0px',
          }}
        >
          This email ID has already been registered.
          <button
            onClick={() => {
              closeModal();
              loginopenModal();
            }}
            className={styles.alreadyBtn}
          >
            Log in!
          </button>
        </div>
      ) : (
        <ErrorBelow err={err} showError={showError} />
      )}
    </div>
  );
};
export default InputText;

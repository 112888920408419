import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import styles from "./GraphStyles.module.css";
import LineChart from "../../../Utils/Charts/NivoLine/LineChart";
import { useDispatch, useSelector } from "react-redux";
import {
  get_networth,
  formatCommaString,
  roundNum,
  GREEN_PLOOT,
  RED_PLOOT,
  DRAK_GREEN_PLOOT,
  DARK_RED_PLOOT,
} from "@plootus/common";
import { Link } from "react-router-dom";

const NetworthGraph = (props) => {
  const dispatch = useDispatch();

  const graph = useSelector((state) => state.portfolioReducer);
  const user = useSelector((state) => state.userReducer);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await dispatch(get_networth());
      setLoading(false);
    })();
  }, [user.advisor.selectedClient]);

  if (loading) return <div>Loading...</div>;

  const amount = `$${formatCommaString(
    roundNum(Math.abs(graph.networth.networth_total), 0, 2)
  )}`;

  return (
    <Card
      className={styles.mainCard}
      style={{
        borderRadius: "10px",
        boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className={styles.cardHeader}>
        <div className={styles.headerLeft}>Net Worth</div>
        <div
          className={styles.headerRight}
          style={{
            color:
              graph.networth.networth_total > 0
                ? DRAK_GREEN_PLOOT
                : DARK_RED_PLOOT,
          }}
        >
          {amount.slice(0, -2)}
          <span className={styles.afterDecimal}>{amount.slice(-2)}</span>
          {/* {graph.networth.networth_total} */}
        </div>
      </div>
      {graph.networth.networth_graph ? (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <LineChart
            data={graph.networth.networth_graph}
            pickerIndex={0}
            small={true}
          />
        </div>
      ) : null}
      <Link className={styles.seeMore} to="/auth/networth">
        See More {">"}
      </Link>
    </Card>
  );
};

export default NetworthGraph;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXPENSES = exports.CONTRIBUTION = exports.LIFE_EXP = exports.RETIRE_AGE = exports.CURRENT_AGE = void 0;
exports.CURRENT_AGE = {
    TITLE: 'Current Age (Years)',
    MIN: 20,
    MAX: 50,
    TYPE: 'num',
    VALUE: 25,
};
exports.RETIRE_AGE = {
    TITLE: 'Retirement Age (Years)',
    MIN: 26,
    MAX: 70,
    TYPE: 'num',
    VALUE: 65,
};
exports.LIFE_EXP = {
    TITLE: 'Life Expectancy (Years)',
    MIN: 66,
    MAX: 120,
    TYPE: 'num',
    VALUE: 85,
};
exports.CONTRIBUTION = {
    TITLE: 'Annual Contribution ($)',
    MIN: 0,
    MAX: 40000,
    TYPE: 'money',
    VALUE: 5000,
};
exports.EXPENSES = {
    TITLE: 'Annual Retirement Expenses ($)',
    MIN: 1,
    MAX: 1000000,
    TYPE: 'money',
    VALUE: 50000,
};

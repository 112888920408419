import {
  DANGER_RED,
  formatCommaString,
  roundNum,
  TEXT_BLACK_3,
} from '@plootus/common';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import useEmployerSearch from '../../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';
import Button from '../../../Utils/Buttons/Button';
import SearchBox401k from '../../../Utils/Search401kNew/SearchBox401kNew';
import Slider2 from '../../../Utils/Slider2/Slider2';
import MyText from '../../../Utils/Texts/MyText';
import styles from './Strategy.module.css';

const Strategy = ({ setSignupData, index, nextPress, signupData }) => {
  const { strategy, approx_401k_bal } = signupData;
  const [_401k_balance, set_401k_balance] = useState(approx_401k_bal);
  const [maxSlider, setmaxSlider] = useState(2000000);
  const [stratValue, setStratValue] = useState(strategy);
  const submitted = useRef(false);
  const [isError, setIsError] = useState(false);

  const [myError, setMyError] = useState(false);
  const handlePress = (e) => {
    e.preventDefault();
    submitted.current = true;

    if (stratValue >= 0 && _401k_balance > 0) {
      setSignupData((prevState) => ({
        ...prevState,
        strategy: stratValue,
        approx_401k_bal: _401k_balance,
      }));
      nextPress();
    } else {
      if (_401k_balance === 0) setMyError(true);
      if (stratValue < 0) setIsError(true);
    }
  };

  const handleChange = (idx) => {
    if (idx >= 0) setIsError(false);
    else setIsError(true);
  };

  const [checkValue, setCheck] = useState(false);

  const props = useEmployerSearch();

  console.log(props.value);

  return (
    <div className={styles.root}>
      <div className={styles.investment}>
        <MyText className={styles.title} style={{ fontSize: '16px' }}>
          Investment Strategy
        </MyText>
        <div className={styles.customSelect}>
          <select
            className={styles.selectBox}
            value={stratValue}
            onChange={(e) => {
              setStratValue(parseInt(e.target.value));
              handleChange(parseInt(e.target.value));
            }}
            style={{ color: stratValue === -1 ? TEXT_BLACK_3 : 'black' }}
          >
            <option value={-1} className={styles.selectOption}>
              Select Strategy
            </option>
            <option
              value={0}
              className={styles.selectOption}
              style={{ color: 'black' }}
            >
              Conservative
            </option>
            <option
              value={1}
              className={styles.selectOption}
              style={{ color: 'black' }}
            >
              Moderate
            </option>
            <option
              value={2}
              className={styles.selectOption}
              style={{ color: 'black' }}
            >
              Growth
            </option>
            <option
              value={3}
              className={styles.selectOption}
              style={{ color: 'black' }}
            >
              Super Growth
            </option>
            <option
              value={4}
              className={styles.selectOption}
              style={{ color: 'black' }}
            >
              Super Conservative
            </option>
          </select>
          <span className={styles.customArrow}></span>
        </div>

        {isError && submitted.current ? (
          <p
            style={{
              fontSize: 12,
              color: DANGER_RED,
              marginRight: 'auto',
              marginTop: 2,
            }}
          >
            Select a strategy
          </p>
        ) : null}
      </div>

      <div className={styles.employer}>
        <MyText
          className={styles.title}
          style={{ marginBottom: '3px', fontSize: '16px' }}
        >
          Employer
        </MyText>
        <SearchBox401k
          value={checkValue ? '' : props.value}
          //setValue={handleTextChange}
          setSignupData={setSignupData}
          disable={checkValue}
          style1={{ width: '100%' }}
          dontNull={true}
          {...props}
        />
      </div>

      <div className={styles.label}>
        <input
          className={styles.checkBox}
          type="checkbox"
          value={checkValue}
          onClick={() => {
            setCheck((state) => !state);
          }}
        />
        <label className={styles.tp}>Don't know the Employer</label>
      </div>

      <div className={styles.slider}>
        <div>
          <MyText style={{ fontSize: '16px' }}>
            Approximate 401k/403b Balance
          </MyText>
          {myError && submitted.current ? (
            <p
              style={{
                fontSize: 12,
                color: DANGER_RED,
                marginRight: 'auto',
                marginTop: 2,
              }}
            >
              Starting Balance cannot be 0
            </p>
          ) : null}
        </div>
        <input
          className={styles.employerText}
          value={`$${formatCommaString(roundNum(_401k_balance, 0))}`}
        />

        <Slider2
          initialValue={_401k_balance}
          changeHandler={(value) => {
            set_401k_balance(value);
            setMyError(false);
          }}
          maxValue={maxSlider}
          stepValue={1000}
          page="home"
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button text="Next" action={handlePress} />
      </div>
    </div>
  );
};

export default connect()(Strategy);

import React, { useReducer, useState } from 'react';
import styles from './Table.module.css';
import {
  roundNum,
  removeCommaString,
  GENERAL_FUTURE_EXPENSES_TABLE,
  useDidUpdate,
  SEND_ALERT,
  SUCCESS_CODE,
  saveFutureExpenses,
  resetFutureExpenses,
  GENERAL_GRAPH,
  getEditAssumptionsInitialState,
  getUser
} from '@plootus/common';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import TableHeader from './TableHeader';
import TableHead from './TableHead';
import ExpenseRow from './ExpenseRow';
import { Scrollbars } from 'react-custom-scrollbars';
import { Typography } from '@material-ui/core';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'GENERAL':
      return { ...state, ...payload };

    case 'CHANGE_EDITING':
      return { ...state, isEditing: payload };
    default:
      return state;
  }
};

const Table = ({ from, to, years, dob, pickerYears, getFutureExpenses }) => {
  const dispatch = useDispatch();
  const general = useSelector((state) => state.generalReducer);
  const { futureExpenses } = general;
  const { currentTotal, futureTotal, listData, inflation } = futureExpenses;

  const [{ data, totalFuture, totalCurrent, isEditing }, dispatchAction] =
    useReducer(reducer, {
      data: listData,
      totalCurrent: roundNum(currentTotal, 0, 0, true, true),
      totalFuture: roundNum(futureTotal, 0, 0, true, true),
      isEditing: false
    });

  /* COPY PASTED */

  const [currentChangedItems, setCurrentChangedItems] = useState(new Map());

  const calcInflation = inflation / 100 + 1;

  const changeItem = async () => {
    let oldValues = 0;
    let newValues = 0;

    const updatedData = data.map((item, idx) => {
      const { changed, future, new_future } = item;

      if (currentChangedItems.has(idx)) {
        oldValues += roundNum(
          !changed ? future : removeCommaString(new_future),
          0,
          0,
          true,
          true
        );
        newValues += removeCommaString(currentChangedItems.get(idx));

        return {
          ...item,
          new_future: currentChangedItems.get(idx),
          changed: true
        };
      } else {
        return item;
      }
    });

    const newTotalFuture = totalFuture + newValues - oldValues;

    const code = await dispatch(saveFutureExpenses(updatedData));

    if (code === SUCCESS_CODE) {
      dispatch({
        type: GENERAL_FUTURE_EXPENSES_TABLE,
        payload: {
          listData: updatedData,
          futureTotal: newTotalFuture,
          total:
            newTotalFuture *
            calcInflation ** years *
            ((calcInflation ** Math.abs(pickerYears) - 1) / (calcInflation - 1))
        }
      });
      setCurrentChangedItems(new Map());
      await getFutureExpenses();
      let token = localStorage.getItem('jwt_token');
      let userDataTemp = await dispatch(getUser());

      await dispatch({
        type: GENERAL_GRAPH,
        payload: await getEditAssumptionsInitialState(token, userDataTemp)
      });
    }
  };

  const reset = async (index) => {
    const code = await dispatch(resetFutureExpenses(data[index].category));

    if (code === SUCCESS_CODE) {
      let token = localStorage.getItem('jwt_token');
      let userDataTemp = await dispatch(getUser());

      await getFutureExpenses();
      await dispatch({
        type: GENERAL_GRAPH,
        payload: await getEditAssumptionsInitialState(token, userDataTemp)
      });
    }
  };

  const resetItem = async (index) => {
    await dispatch({
      type: SEND_ALERT,
      payload: {
        title: 'Alert',
        textContent: `This will reset the ${data[index].category} to the modeled output
            based on Plootus' algorithm for retirement expenses.`,
        buttonLeftText: 'Cancel',
        buttonRightPress: () => {
          reset(index);
        },
        buttonRightText: 'OK'
      }
    });
  };

  useDidUpdate(() => {
    dispatchAction({
      type: 'GENERAL',
      payload: {
        data: listData,
        totalFuture: futureTotal,
        isEditing: false
      }
    });
  }, [futureExpenses]);

  useDidUpdate(() => {
    dispatchAction({
      type: 'CHANGE_EDITING',
      payload: currentChangedItems.size !== 0
    });
  }, [currentChangedItems]);

  return (
    <div className={styles.tableBox}>
      <div className={styles.headerAll}>{'  '}</div>
      <div className={styles.sliderDiv}>
        <TableHeader
          changeItem={changeItem}
          dispatch={dispatch}
          dispatchAction={dispatchAction}
          inflation={inflation}
          isEditing={isEditing}
        />
      </div>
      <div className={styles.tableDiv}>
        <TableHead
          from={from}
          inflation={inflation}
          years={years}
          totalCurrent={totalCurrent}
          totalFuture={totalFuture}
        />
        <div className={styles.tableBody}>
          <Scrollbars style={{ width: '100%', height: '100%' }}>
            {data.map((item, index) => {
              const multi = !item.changed
                ? item.future
                : removeCommaString(item.new_future);
              const value = Math.abs(multi * (1 + inflation / 100) ** years);
              return (
                <ExpenseRow
                  setCurrentEdit={setCurrentChangedItems}
                  resetItem={resetItem}
                  {...item}
                  futureInf={value}
                  index={index}
                  isEditing={isEditing}
                />
              );
            })}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default Table;

import { get_networth, NET_API, NET_SCREEN } from '@plootus/common';
import React from 'react';
import { connect } from 'react-redux';
import VisualData from '../../Reusables/VisualAndAccord/Container/VisualData';
import LineChart from '../../Utils/Charts/NivoLine/LineChart';

const NetWorthData = (props) => (
  <VisualData
    {...props}
    api_name={NET_API}
    screen={NET_SCREEN}
    chartComponent={(pickerIndex) => (
      <LineChart data={props.networth_graph} pickerIndex={pickerIndex} />
    )}
    dispatchAction={get_networth}
    type="graph"
    title="Total Networth"
    _key="type"
    _key_accord="id"
    singleScreen
    nested
  />
);

export default connect(({ portfolioReducer: { networth } }) => ({
  ...networth,
}))(NetWorthData);

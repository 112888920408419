"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("./types");
var initialState = {
    assets: {
        assets_cat_data: null,
        assets_cat_pie: null,
        assets_total: null,
    },
    incomexp: {
        income_data: null,
        income_pie: null,
        income_total: null,
        income_trans_data: null,
        expense_data: null,
        expense_pie: null,
        expense_total: null,
        expense_trans_data: null,
        cashflow_data: null,
        cashflow_graph: null,
        cashflow_total: null,
    },
    liability: {
        credit_data: null,
        credit_pie: null,
        credit_total: null,
    },
    networth: {
        networth_data: null,
        networth_graph: null,
        networth_total: null,
    },
};
exports.default = (function (state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case types_1.GENERAL_SET_PIE_DATA: {
            return __assign(__assign({}, state), (_a = {}, _a[action.payload.screen] = __assign(__assign({}, state[action.payload.screen]), (_b = {}, _b["".concat(action.payload.api_name, "_data")] = action.payload.accord_data, _b["".concat(action.payload.api_name, "_pie")] = action.payload.pie_data, _b["".concat(action.payload.api_name, "_total")] = action.payload.total, _b)), _a));
        }
        case types_1.GENERAL_SET_GRAPH_DATA:
            return __assign(__assign({}, state), (_c = {}, _c[action.payload.screen] = __assign(__assign({}, state[action.payload.screen]), (_d = {}, _d["".concat(action.payload.api_name, "_data")] = action.payload.accord_data, _d["".concat(action.payload.api_name, "_graph")] = action.payload.graph_data, _d["".concat(action.payload.api_name, "_total")] = action.payload.total, _d)), _c));
        case types_1.GENERAL_TRASACTION:
            return __assign(__assign({}, state), (_e = {}, _e[action.payload.screen] = __assign(__assign({}, state[action.payload.screen]), (_f = {}, _f["".concat(action.payload.api_name, "_trans_data")] = action.payload.trans_data, _f)), _e));
        case types_1.PIE_DATA_EMPTY:
            return __assign(__assign({}, state), (_g = {}, _g[action.payload.screen] = __assign(__assign({}, state[action.payload.screen]), (_h = {}, _h["".concat(action.payload.api, "_data")] = [], _h)), _g));
        case types_1.RESET_PORTFOLIO: {
            return __assign(__assign({}, initialState), { assets: __assign({}, initialState.assets), incomexp: __assign({}, initialState.incomexp), liability: __assign({}, initialState.liability), networth: __assign({}, initialState.networth) });
        }
        case types_1.RESET_GENERAL_PORT: {
            return __assign(__assign({}, state), (_j = {}, _j[action.payload.screen] = __assign({}, initialState[action.payload.screen]), _j));
        }
        // Remove this, incorrect method to update state.
        case types_1.RESET_DATA:
            return __assign(__assign({}, state), initialState);
        default:
            return state;
    }
});

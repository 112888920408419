import React from 'react';
import styles from './Footer.module.css';
import FooterTop from './FooterTop';
import FooterBottom from './FooterBottom';

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.footerContainer}>
        <div className={styles.footerTop}>
          <FooterTop />
        </div>
        <div className={styles.footerBottom}>
          <FooterBottom />
        </div>
      </div>
    </div>
  );
};

export default Footer;

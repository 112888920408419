"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.strategy_constants_5 = exports.strategy_constants = exports.timePeriod = void 0;
exports.timePeriod = [
    { label: 'This Month', value: '1' },
    { label: 'Last Month', value: '2' },
    { label: 'Last 3 Months', value: '5' },
    { label: 'This Year', value: '6' },
    { label: 'Last Year', value: '7' },
];
exports.strategy_constants = [
    { label: 'Super Conservative', value: '5' },
    { label: 'Conservative', value: '1' },
    { label: 'Moderate', value: '2' },
    { label: 'Growth', value: '3' },
    { label: 'Super Growth', value: '4' },
];
exports.strategy_constants_5 = [
    { label: 'Super Conservative', value: '5' },
    { label: 'Conservative', value: '1' },
    { label: 'Moderate', value: '2' },
    { label: 'Growth', value: '3' },
    { label: 'Super Growth', value: '4' },
    { label: "Don't Know", value: '0' },
];

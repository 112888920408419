/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  forgotPassword,
  GREY_1,
  sendAlert,
  SUCCESS_CODE,
} from '@plootus/common';
import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import a1 from '../../Assets/forgot-password/a2.svg';
import Button from '../../Utils/Buttons/Button';
import InputText from '../../Utils/InputText/InputText';
import styles from './ForgotFirstScreen.module.css';

const ForgotFirstScreen = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState({
    value: '',
    err: { err: false, value: '' },
    typed: false,
  });

  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const submitted = useRef(false);
  const handleEmail = (value) => {
    let newFields;

    if (submitted.current) {
      const result = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

      newFields = {
        value,
        typed: submitted.current || false,
        err: {
          err: !result,
          value: !result ? 'Enter valid email address' : '',
        },
      };
    } else {
      newFields = { ...email, value: value };
    }
    setEmail(newFields);
  };

  const handleClick = async (e) => {
    submitted.current = true;
    e.preventDefault();
    const result = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email.value
    );
    let newFields;
    newFields = {
      value: email.value,
      typed: submitted.current || false,
      err: {
        err: !result,
        value: !result ? 'Enter valid email address' : '',
      },
    };
    setEmail(newFields);

    if (!email.err.err && email.value.length) {
      // Dispatch action & Send Email Sent Notification -> if possible by-react-toastify and close modal
      const resCode = await dispatch(forgotPassword(email.value));

      if (resCode === SUCCESS_CODE) {
        dispatch(
          sendAlert({
            title: 'Success',
            textContent: 'Reset password link has been sent to your email',
          })
        );
      }
      closeModal();
    }
  };

  return (
    <>
      <a
        className={styles.forgotPassword}
        onClick={() => openModal()}
        style={{ cursor: 'pointer', fontSize: '14px', color: GREY_1 }}
      >
        Forgot Password?
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.container}>
          <button onClick={closeModal} className={styles.closeButton}>
            <img
              src="/cross-thin.png"
              className={styles.crossButton}
              alt="Close"
            />
          </button>
          <div className={styles.header}>Forgot Password</div>
          <div className={styles.image}>
            <img src={a1} className={styles.innerImage} alt="" />
          </div>

          <div className={styles.content}>
            <p className={styles.t1}>Please enter your registered email ID</p>
            <p>We will send a verification code to your registered email ID</p>
          </div>

          <div className={styles.inputBox}>
            <InputText
              title="Email"
              config={{
                onChange: (e) => handleEmail(e.target.value),
                value: email.value,
              }}
              containerStyle={{ width: '100%', marginBottom: '0px' }}
              textInputStyle={{
                height: '35px',
                borderRadius: '10px',
                paddingLeft: '10px',
              }}
              titleStyle={{
                paddingLeft: '10px',
                paddingBottom: '5px',
                paddingTop: '0px',
              }}
              err={email?.err}
              typed={email?.typed}
            />
          </div>
          <div className={styles.btnDiv}>
            <Button text="Next" action={handleClick} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ForgotFirstScreen;

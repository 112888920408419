import React from "react";
import styles from "./PasswordText.module.css";

const PasswordText = ({ title, style }) => {
  return (
    <div className={styles.container} style={style}>
      {title}
    </div>
  );
};

export default PasswordText;

import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import VisualData from "../../Reusables/VisualAndAccord/Container/VisualData";
import { CREDIT_API, LIAB_SCREEN } from "@plootus/common";
import { get_liab_credit } from "@plootus/common";
import { HIGHLIGHT_PIE } from "@plootus/common";
import styles from "./CreditData.module.css";

const CreditData = (props) => {
  const liab = useSelector((state) => state.portfolioReducer.liability);
  const dispatch = useDispatch();
  return (
    <div className={styles.wrapper}>
      <VisualData
        {...props}
        {...liab}
        dispatch={dispatch}
        api_name={CREDIT_API}
        screen={LIAB_SCREEN}
        dispatchAction={get_liab_credit}
        type="pie"
        title="TOTAL LIABILITIES"
        _key="id"
        actionType={HIGHLIGHT_PIE}
        _key_accord="id"
        nested
        singleScreen
      />
    </div>
  );
};
export default CreditData;

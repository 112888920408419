import React, { useState } from 'react';
import Register from './Register/Register';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import QueryString from 'query-string';

const MainModal = (props) => {
  const tp = QueryString.parse(props.location.search);
  //  console.log(tp);

  const [isOpenOne, setIsOpenOne] = useState(false);
  const [isOpenTwo, setIsOpenTwo] = useState(false);

  const openOneModal = () => {
    setIsOpenOne(true);
  };
  const closeOneModal = () => {
    setIsOpenOne(false);
    setIsOpenTwo(true);
  };

  const closeTwoModal = () => {
    setIsOpenTwo(false);
  };

  if (tp.isRegistered === 'true') {
    return (
      <ConfirmModal
        modalIsOpen={true}
        closeModal={closeTwoModal}
        status={true}
        userAdvisorId={tp.userAdvisorId}
        isRegistered={true}
        emailTo={tp.emailTo}
        emailFrom={tp.emailFrom}
        advisorUId={tp.advisorUId}
      />
    );
  }

  return (
    <div>
      <Register
        {...props}
        modalIsOpen={isOpenOne}
        openModal={openOneModal}
        closeModal={closeOneModal}
        setIsOpenOne={setIsOpenOne}
      />
      <ConfirmModal
        modalIsOpen={isOpenTwo}
        closeModal={closeTwoModal}
        emailFrom={tp.emailFrom}
      />
    </div>
  );
};

export default MainModal;

import React, { useState, useEffect, useRef } from 'react';
import { BLUE_PLOOT, BORDER_1, WHITE } from '@plootus/common';
import styles from './PasswordInput.module.css';
import PasswordText from '../Texts/PasswordText';

const PasswordInput = ({
  containerStyle,
  titleStyle,
  textInputStyle,
  config,
  title,
  iconStyle,
  iconContStyle,
  msgStyle,
  msg,
}) => {
  const [visible, setvisible] = useState(false);
  const handleClick = () => {
    setvisible((prevState) => !prevState);
  };
  return (
    <div style={{ ...containerStyle }} className={styles.container}>
      <div className={styles.innerContainer}>
        {title ? <PasswordText title={title} style={titleStyle} /> : <></>}
        <div className={styles.inputDiv}>
          <input
            type={visible ? 'text' : 'password'}
            style={{ ...textInputStyle }}
            secureTextEntry={visible}
            {...config}
            onChange={(e) => config.onChangeText(e.target.value)}
            className={styles.password}
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
            onDrag={(e) => e.preventDefault()}
            onDrop={(e) => e.preventDefault()}
            autocomplete={false}
          />
          <p className={styles.show} onClick={handleClick}>
            {visible ? 'Hide' : 'Show'}
          </p>
        </div>
        <div className={styles.msg} style={msgStyle}>
          {msg}
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;

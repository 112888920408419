import { Button, Typography } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import "./ConfirmUnsubscribe.css";

function ConfirmUnsubscribe(props) {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let id = props.location.search.split("=")[1];
    if (id === undefined) {
      props.history.push("/");
      return;
    }
    setUserId(id);
    console.log(id);
  }, []);

  const handleUnsubscribe = async () => {
    try {
      const response = await fetch(
        `https://www.plootus.com/api/user/unsubscribeEmail?id=${userId}`
      );
      if (response) {
        props.history.push(`/unsubscribe?id=${userId}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <span className="center-item logospan">
        <img src={require("../../Assets/plootus-p(2).png")} className="logo" />
      </span>
      <Typography.Text className="center-item head">
        UNSUBSCRIBE
      </Typography.Text>
      <Typography.Text className="sub-head center-item">
        We're sad to see you go!
      </Typography.Text>
      <span className="center-item">
        <img src={require("../../Assets/unsubscribe.png")} className="sad" />
      </span>
      <p className="text">
        Are you sure you want to unsubscribe <br />
        from our newsletters?
      </p>
      <div className="center-item">
        <Link to="/">
          <Button type="primary" className="buttons" color="#337dd6">
            Cancel
          </Button>
        </Link>

        <Button
          className="buttons"
          color="#337dd6"
          onClick={handleUnsubscribe}
          disabled={userId === null}
        >
          Unsubscribe
        </Button>
      </div>
    </div>
  );
}

export default ConfirmUnsubscribe;

/* eslint-disable array-callback-return */
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { does_user_exist } from '@plootus/common';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDateValidation from '../../../Reusables/VisualAndAccord/Hooks/useDateValidation';
import useWindowDimensions from '../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import Button from '../../../Utils/Buttons/Button';
import InputText from '../../../Utils/InputText/InputText';
import MyText from '../../../Utils/Texts/MyText';
import { validate } from '../utils';
import styles from './PersonalInfo.module.css';

const getInputConfig = (value = '') => ({
  value,
  err: { err: false, value: '' },
  typed: false,
});

const getInitialState = (signupData) => [
  {
    firstName: getInputConfig(signupData.firstName),
    lastName: getInputConfig(signupData.lastName),
    mobileNo: getInputConfig(signupData.mobileNo),
    email: getInputConfig(signupData.email),
    zipcode: getInputConfig(signupData.zipcode),
  },
  {
    dob: signupData.dob,
    gender: signupData.gender,
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px',
    boxShadow: '0 4px 16px 0 rgba(69, 91, 99, 0.08)',
    border: 'solid 0.5px #406bdf',
    fontSize: '10',
    marginTop: 0,
    marginBottom: '5px',
    htmlFontSize: 10,
  },
});

const PersonalInfo = ({
  signupData,
  setSignupData,
  nextPress,
  closeModal,
  loginopenModal,
}) => {
  const [initFields, initOtherFields] = getInitialState(signupData);
  const [fields, setFields] = useState(initFields);
  const [otherFields, setOtherFields] = useState(initOtherFields);
  const submitted = useRef(false);

  const { width } = useWindowDimensions();

  /* Date Validation */
  const { minDate, maxDate } = useDateValidation();

  const [emailExist, setEmailExist] = useState(false);

  const dispatch = useDispatch();

  /* Function for validating TextInput Fields */
  const handleTextChange = (field, value) => {
    let newFields;

    if (submitted.current) {
      newFields = validate(field, value, fields, submitted.current);
    } else {
      newFields = { ...fields };
      const newField = { ...newFields[field], value };
      newFields[field] = newField;
    }
    setFields(newFields);
  };

  /* Function on pressing Next */
  const handlePress = async () => {
    submitted.current = true;

    let flag = false;
    const newFields = validate('all', '', fields, true);
    Object.keys(newFields).map((field) => {
      if (newFields[field].err.err) {
        flag = true;
      }
    });

    if (!newFields.email.err.err) {
      if (await dispatch(does_user_exist(newFields.email.value))) {
        setEmailExist(true);
        flag = true;
      } else {
        setEmailExist(false);
      }
    }

    if (flag) {
      setFields(newFields);
    } else {
      setSignupData((prevState) => ({
        ...prevState,
        firstName: fields.firstName?.value,
        lastName: fields.lastName?.value,
        mobileNo: fields.mobileNo?.value,
        email: fields.email?.value,
        zipcode: fields.zipcode?.value,
        dob: otherFields.dob,
        gender: otherFields.gender,
      }));
      nextPress();
    }
  };

  const { firstName, lastName, mobileNo, email, zipcode } = fields;
  const { dob, gender } = otherFields;

  const [selectedDate, setSelectedDate] = React.useState(new Date(dob));

  function convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  const handleDateChange = (date) => {
    console.log(convert(date));
    setSelectedDate(new Date(date));

    setOtherFields({
      ...otherFields,
      dob: moment(date).format('YYYY-MM-DD'),
    });
  };

  const classes = useStyles();
  return (
    <div className={styles.container}>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <InputText
          type="text"
          containerStyle={{ width: '40%' }}
          titleStyle={{
            fontSize: width > 576 ? '16px' : '12px',
            marginBottom: '5px',
          }}
          textInputStyle={null}
          config={{
            value: firstName?.value,
            onChange: (e) => handleTextChange('firstName', e.target.value),
          }}
          title="First Name"
          err={firstName?.err}
          typed={firstName?.typed}
        />
        <InputText
          type="text"
          containerStyle={{ width: '40%' }}
          titleStyle={{
            fontSize: width > 576 ? '16px' : '12px',
            marginBottom: '5px',
          }}
          textInputStyle={null}
          config={{
            value: lastName?.value,
            onChange: (e) => handleTextChange('lastName', e.target.value),
          }}
          title="Last Name"
          err={lastName?.err}
          typed={lastName?.typed}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <InputText
          type="text"
          containerStyle={{ width: '40%' }}
          titleStyle={{
            fontSize: width > 576 ? '16px' : '12px',
            marginBottom: '5px',
          }}
          textInputStyle={null}
          config={{
            maxlength: 5,
            value: zipcode?.value,
            onChange: (e) => handleTextChange('zipcode', e.target.value),
          }}
          title="Zip Code"
          err={zipcode?.err}
          typed={zipcode?.typed}
        />
        <InputText
          type="text"
          containerStyle={{ width: '40%' }}
          titleStyle={{
            fontSize: width > 576 ? '16px' : '12px',
            marginBottom: '5px',
          }}
          textInputStyle={null}
          config={{
            maxlength: 10,
            value: mobileNo?.value,
            onChange: (e) => handleTextChange('mobileNo', e.target.value),
          }}
          title="Phone Number"
          err={mobileNo?.err}
          typed={mobileNo?.typed}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <InputText
          type="text"
          containerStyle={{ width: '90%' }}
          titleStyle={{
            fontSize: width > 576 ? '16px' : '12px',
            marginBottom: '5px',
          }}
          textInputStyle={null}
          config={{
            value: email?.value,
            onChange: (e) => {
              setEmailExist(false);
              handleTextChange('email', e.target.value);
            },
          }}
          title="Email ID"
          err={email?.err}
          typed={email?.typed}
          emailExist={emailExist}
          closeModal={closeModal}
          loginopenModal={loginopenModal}
          setEmailExist={setEmailExist}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div className={styles.genderContainer}>
          <MyText
            className="title"
            style={{
              marginBottom: '5px',
              fontSize: width > 576 ? '16px' : '12px',
            }}
          >
            Gender
          </MyText>
          <select
            className={styles.selectBox}
            value={gender}
            onChange={(e) => {
              setOtherFields({
                ...otherFields,
                gender: parseInt(e.target.value),
              });
            }}
          >
            <option value={0}>Male</option>
            <option value={1}>Female</option>
            <option value={2}>Other</option>
            <option value={3}>Rather Not to say</option>
          </select>
        </div>
        {width <= 1024 ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{ width: '40%' }}>
              <MyText
                className="title"
                style={{
                  marginBottom: '5px',
                  fontSize: width > 576 ? '16px' : '12px',
                }}
              >
                Date of Birth
              </MyText>
              <KeyboardDatePicker
                // disableToolbar
                variant="inline"
                maxDate={maxDate}
                minDate={minDate}
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-dialog"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                classes={{
                  root: classes.root,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                autoOk={true}
              />
            </div>
          </MuiPickersUtilsProvider>
        ) : (
          <InputText
            type="date"
            containerStyle={{ width: '40%' }}
            titleStyle={{
              fontSize: width > 576 ? '16px' : '12px',
              marginBottom: '5px',
            }}
            textInputStyle={null}
            config={{
              value: dob,
              onChange: (e) => {
                setOtherFields({
                  ...otherFields,
                  dob: e.target.value,
                });
              },
              defaultValue: '1993-01-01',
              disabled: false,
              min: minDate,
              max: maxDate,
            }}
            title="Date of Birth"
            err={null}
            typed={null}
          />
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button text="Next" action={handlePress} />
      </div>
    </div>
  );
};

export default PersonalInfo;

import React, { useState } from 'react';
import { add_advisor } from '@plootus/common';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import styles from './Modal.module.css';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

const AddModal = () => {
  const [modalIsOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const dispatch = useDispatch();

  const handleSubmit = (val) => {
    dispatch(add_advisor(email));
    setEmail('');
    closeModal();
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.btnDiv}>
        <button onClick={openModal} className={styles.addButton}>
          <img src="/plus1.png" className={styles.img} alt="" />
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.aModal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.header}>Please enter the Advisor’s email ID</div>
        <div className={styles.content}>
          We will send them a registration link to the specified email address
        </div>
        <div className={styles.inputOuter}>
          <MailOutlineIcon color="disabled" />
          <input
            type="email"
            value={email}
            placeholder="Enter email address"
            onChange={(e) => setEmail(e.target.value)}
            className={styles.emailInput}
          />
        </div>
        <div className={styles.btnsDiv}>
          <button className={styles.cancelBtn} onClick={closeModal}>
            Cancel
          </button>
          <button
            className={styles.confirmBtn}
            onClick={() => {
              handleSubmit(email);
            }}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddModal;

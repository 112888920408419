import React from "react";
import CaresActProvider from "./CaresActContext";
import SocialSecurityProvider from "./SocialSecurityContext";

function ContextProvider(props) {
  return (
    <SocialSecurityProvider>
      <CaresActProvider>{props.children}</CaresActProvider>
    </SocialSecurityProvider>
  );
}

export default ContextProvider;

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import styles from './ProcessPicker.module.css';

const ProcessPicker = ({
  processPick,
  dataTypePick,
  setprocessPick,
  setLoading,
}) => {
  const pIndex = processPick.index;

  return (
    <>
      {dataTypePick.index === 1 ? (
        <div className={styles.container}>
          <div className={styles.title}>Details</div>
          <div className={styles.category}>Category</div>
          <div
            className={styles.datePicker}
            onClick={() => {
              setLoading();
              setprocessPick(pIndex === 2 ? 3 : 2);
            }}
          >
            Date
            {pIndex === 2 ? (
              <ArrowDropUpIcon fontSize="large" color="#404a57" />
            ) : pIndex === 3 ? (
              <ArrowDropDownIcon fontSize="large" color="#404a57" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" color="#404a57" />
            )}
          </div>
          <div
            className={styles.amountPicker}
            onClick={() => {
              setLoading();
              setprocessPick(pIndex === 0 ? 1 : 0);
            }}
          >
            Amount
            {pIndex === 1 ? (
              <ArrowDropUpIcon fontSize="large" color="#404a57" />
            ) : pIndex === 0 ? (
              <ArrowDropDownIcon fontSize="large" color="#404a57" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" color="#404a57" />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProcessPicker;

import React from 'react';
import styles from './FooterAuth.module.css';

const FooterAuth = () => {
  return (
    <div className={styles.main}>
      <p className={styles.cp}>©</p>
      <p>{'  '}</p>
      <img src="/logo.png" className={styles.footerLogo} alt="Plootus" />
      <p className={styles.content}>
        | 2018-{new Date().getFullYear()} Analyze Future LLC | All rights reserved.
      </p>
    </div>
  );
};

export default FooterAuth;

import React, { useState, useEffect, useRef } from "react";
import styles from "./MainScreen.module.css";
import Modal from "react-modal";
import { notNull, validate } from "./utils";
import img1 from "../../Assets/Artboard-1.svg";
import img2 from "../../Assets/Artboard-2.svg";
import img3 from "../../Assets/Artboard-3.svg";
import head1 from "../../Assets/Timeline-1.svg";
import head2 from "../../Assets/Timeline-2.svg";
import head3 from "../../Assets/Timeline-3.svg";
import back from "../../Assets/back.png";
import PersonalInfo from "./screens/PersonalInfo";
import Strategy from "./screens/Strategy";
import Security from "./screens/Security";
import Start from "./screens/Start";

const switchTab = (index, data, handlePress) => {
  switch (index) {
    case 0:
      return <PersonalInfo {...data} nextPress={handlePress} index={index} />;

    case 1: {
      return <Strategy {...data} nextPress={handlePress} index={index} />;
    }

    case 2:
      return <Security {...data} index={index} />;

    default:
      return undefined;
  }
};

const switchImage = (index) => {
  switch (index) {
    case 0:
      return <img className={styles.img} src={img3} />;

    case 1: {
      return <img className={styles.img} src={img1} />;
    }

    case 2:
      return <img className={styles.img} src={img2} />;

    default:
      return undefined;
  }
};

const MainSignupScreen = ({
  modalIsOpen,
  openModal,
  closeModal,
  loginopenModal,
  isInEmployeer,
  show,
  fromFees,
}) => {
  const [signupData, setSignupData] = useState({
    email: "",
    password: "",
    confPassword: "",
    firstName: "",
    lastName: "",
    mobileNo: "",
    gender: 0, // This wont store the actual values but would store the indices
    dob: "1993-01-01", // So while sending values in register send appropriately
    zipcode: "",
    strategy: -1, // This also
    approx_401k_bal: 0,
    sponser_name: "",
    employerEin: null,
  });

  const [indexSelec, setIndexSelec] = useState(3);
  const [validations, setValidations] = useState({
    validationArr: null,
    isMatched: null,
  });

  const nextBackHandlePress = (sign) => {
    setIndexSelec(indexSelec + sign);
  };

  return (
    <div>
      {fromFees ? (
        <div className={styles.fromFeesBtn} onClick={() => openModal()}>
          Signup
        </div>
      ) : isInEmployeer ? (
        <button className={styles.myButton} onClick={openModal}>
          SIGN UP TO VIEW FULL ALLOCATION
        </button>
      ) : (
        <button
          onClick={openModal}
          className={styles.signupBtn}
          style={show ? { display: "none" } : {}}
        >
          Get Started
        </button>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={indexSelec === 3 ? styles.initModal : styles.Modal}
        overlayClassName={styles.Overlay}
      >
        {indexSelec !== 3 ? (
          <div className={styles.container}>
            <div className={styles.image}>{switchImage(indexSelec)}</div>
            <div className={styles.header}>
              {indexSelec !== 0 ? (
                <img
                  src={back}
                  className={styles.backButton}
                  onClick={() => nextBackHandlePress(-1)}
                />
              ) : null}
              <p className={styles.heading}>Create Account </p>
              <img
                className={styles.crossButton}
                src="/cross-thin.png"
                onClick={() => {
                  setIndexSelec(3);
                  closeModal();
                }}
              />

              <img
                src={
                  indexSelec === 0 ? head1 : indexSelec === 1 ? head2 : head3
                }
                className={styles.timeline}
              />
            </div>
            <div className={styles.main}>
              {switchTab(
                indexSelec,
                {
                  setSignupData,
                  signupData,
                  validations,
                  setValidations,
                  closeModal,
                  loginopenModal,
                },
                () => nextBackHandlePress(1)
              )}
            </div>
          </div>
        ) : (
          <Start closeModal={closeModal} nextPress={() => setIndexSelec(0)} />
        )}
      </Modal>
    </div>
  );
};

export default MainSignupScreen;

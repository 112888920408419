import React, { useState, useEffect, useRef } from 'react';
import useEmployerSearch from '../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';
import Search401kNew from '../../Utils/Search401kNew/SearchBox401kNew';
import styles from './Employer.module.css';
import FundsOrEmployerList from './FundsOrEmployerList';
import { useSelector, useDispatch } from 'react-redux';
import { fetch_allocations } from '@plootus/common';
const Employer = () => {
  const dispatch = useDispatch();
  const props = useEmployerSearch();

  const empLogos = [
    {
      amazon: require('../../Assets/employee-logos/amazon.png'),
      ein: '911986545',
      name: 'AMAZON.COM SERVICES,INC. 401k',
    },
    {
      amazon: require('../../Assets/employee-logos/ge.png'),
      ein: '140689340',
      name: 'GENERAL ELECTRIC COMPANY 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/fb.png'),
      ein: '201665019',
      name: 'FACEBOOK, INC. 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/testla.png'),
      ein: '912197729',
      name: 'TESLA, INC. 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/apple.png'),
      ein: '942404110',
      name: 'APPLE INC. 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/star.png'),
      ein: '911325671',
      name: 'STARBUCKS CORPORATION 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/nissan.png'),
      ein: '952108010',
      name: 'NISSAN NORTH AMERICA, INC. 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/newyork.png'),
      ein: '135562308',
      name: 'NEW YORK UNIVERSITY 401k'
    },
    {
      amazon: require('../../Assets/employee-logos/fairuni.png'),
      ein: '60646623',
      name: 'FAIRFIELD UNIVERSITY 403B'
    },
    {
      amazon: require('../../Assets/employee-logos/alabama.png'),
      ein: '999994609',
      name: 'THE UNIVERSITY OF ALABAMA SYSTEM 403(B) PLAN'
    },
    {
      amazon: require('../../Assets/employee-logos/sacri.png'),
      ein: '140689340',
      name: 'Sacred Heart University 403b plan'
    },
    {
      amazon: require('../../Assets/employee-logos/michan.png'),
      ein: '999994755',
      name: 'UNIVERSITY OF MICHIGAN 401A RETIREMENT PLAN'
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.searchDiv}>
        <div className={styles.title}>401k Plan</div>
        <div className={styles.searchWrap}>
          <Search401kNew dontNull={true} {...props} />
        </div>
        {/* this container will contain the search bar */}
      </div>
      <div className={styles.mainContent}>
        {/* this div will contain the main elements */}
        {props.employer_selected !== '' && !props.data?.length ? (
          <FundsOrEmployerList />
        ) : (
          <>
            <div className={styles.msgBoxCont}>
              <div className={styles.msgBox}>
                Plootus has analysed $279 billion assets in 401k retirement
                plans. Search your employer and we'll suggest you investment
                options based on your investment strategy
              </div>
            </div>
            <div className={styles.row12}>
              <h5 className={styles.spe}>
                Here are some popular 401k plans that we have covered :
              </h5>
              {empLogos.map((item, key) => {
                return (
                  <>
                    <img
                      onClick={async () => {
                        await dispatch(
                          fetch_allocations(
                            {
                              ein: item.ein,
                              name: item.name.split(' (EIN')[0],
                              showLimitExceedError: true
                            },
                            true
                          )
                        );
                      }}
                      className={styles.empImg}
                      src={item.amazon}
                      alt={item.name}
                      title={`Plootus: ${item.name}`}
                    ></img>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Employer;

import {
  facebook_login,
  GENERAL_GRAPH,
  getEditAssumptionsInitialState,
  getUser,
  get_clients,
  google_login,
  TEXT_BLACK_1,
  tokenSelector
} from '@plootus/common';
import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Logo from '../../../Assets/latestLogo.jpeg';
import fb from '../../../Assets/facesign.png';
import google from '../../../Assets/googlesign.png';
import email from '../../../Assets/emailsign.png';
import styles from './Start.module.css';
import styles1 from '../../LogIn/InputCard.module.css';

const Start = ({ closeModal, nextPress }) => {
  const { token } = useSelector(tokenSelector);
  const [route, setRoute] = useState(false);
  const [addRoute, setAddRoute] = useState(false);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (token) {
        console.log('Updated from Login modal');
        const userData = await dispatch(getUser());
        await localStorage.setItem('jwt_token', token);
        if (userData.userType === 'advisor') {
          await dispatch(get_clients());
          setAddRoute(true);
        } else {
          dispatch({
            type: GENERAL_GRAPH,
            payload: await getEditAssumptionsInitialState(token, userData)
          });

          setRoute(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const responseGoogle = async (response) => {
    setLoading(true);
    await dispatch(google_login(response.tokenId));
    setLoading(false);
  };

  const responseFacebook = async (response) => {
    setLoading(true);
    await dispatch(facebook_login(response.accessToken));
    setLoading(false);
  };

  if (addRoute) {
    return <Redirect to='/auth/adash' />;
  }

  if (route) {
    return <Redirect to='/auth/401k' />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <LazyLoadImage
          className={styles.crossButton}
          src='/cross-thin.png'
          onClick={() => {
            closeModal();
          }}
        />
      </div>

      <div className={styles.header} style={{ color: TEXT_BLACK_1 }}>
        Create Account
      </div>
      <div className={styles.logoDiv}>
        <img src={Logo} className={styles.logo} alt='Plootus'  title='Plootus icon'/>
      </div>
      <div className={styles1.connectWith}>
        <p className={styles1.connectText}>
          <span
            style={{
              backgroundColor: 'white',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Sign up with
          </span>
        </p>
      </div>
      {/* <div className={styles.buttonDiv}>
        <div className={styles.buttonCont}>
          <button className={styles.emailButton} onClick={nextPress}>
            <img src='/mail.svg' className={styles.icon} alt='' />
            <span style={{ paddingLeft: '10px' }}>Sign up with Email</span>
          </button>
        </div>
        <div className={styles.buttonCont}>
          <GoogleLogin
            scope='email'
            clientId='489489080349-shsamdnahde9q73s76e7b71mrjm9snvc.apps.googleusercontent.com'
            render={(renderProps) => (
              <button
                className={styles.googleButton}
                onClick={renderProps.onClick}
              >
                <LazyLoadImage src='/google-logo.svg' className={styles.icon} />
                <span style={{ paddingLeft: '10px' }}>Sign up with Google</span>
              </button>
            )}
            buttonText='Login'
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
        </div>
        <div className={styles.buttonCont}>
          <FacebookLogin
            appId='465677784149006'
            // autoLoad
            callback={responseFacebook}
            render={(renderProps) => (
              <button className={styles.fbButton} onClick={renderProps.onClick}>
                <LazyLoadImage
                  src='/facebook-logo.svg'
                  className={styles.icon}
                />
                <span style={{ paddingLeft: '10px' }}>
                  Sign up with Facebook
                </span>
              </button>
            )}
          />
        </div>
      </div> */}
      <div className={styles1.oAuth1}>
        <div className={styles1.fb}>
          <FacebookLogin
            appId='465677784149006'
            // autoLoad
            callback={responseFacebook}
            render={(renderProps) => (
              <img
                onClick={() => (loading ? null : renderProps.onClick())}
                src={fb}
                style={{
                  height: '50px',
                  width: '50px',
                  cursor: 'pointer',
                  marginRight: '-10px'
                }}
              />
            )}
          />
        </div>
        <div className={styles1.google}>
          <GoogleLogin
            scope='email'
            clientId='489489080349-shsamdnahde9q73s76e7b71mrjm9snvc.apps.googleusercontent.com'
            render={(renderProps) => (
              <img
                onClick={() => (loading ? null : renderProps.onClick())}
                //disabled={renderProps.disabled}
                src={google}
                style={{
                  height: '70px',
                  width: '70px',
                  cursor: 'pointer',
                  marginTop: -8,
                  marginLeft: '10px'
                }}
              />
            )}
            buttonText='Login'
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
        </div>
        <div className={styles1.google}>
          <img
            onClick={nextPress}
            src={email}
            style={{
              height: '50px',
              width: '50px',
              marginLeft: -15,
              cursor: 'pointer'
            }}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};

export default Start;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateRetirementGap = exports.calculateAfterRetirementReturns = exports.calculateRetirementAssessts = exports.getKeyArray = exports.resolveObjectPath = exports.getColorFromType = exports.getRandomColor = exports.isNull = exports.isKhaliCheck = exports.errorCheck = void 0;
var types_1 = require("./../redux/graph/types");
// import { Alert } from 'react-native';
var colors_1 = require("../styles/colors");
var return_code_constants_1 = require("../constants/return_code_constants");
var index_1 = require("../constants/index");
var index_2 = require("../constants/index");
// Used to add some other functionality to a function if error occurs
var errorCheck = function (code) { return code === return_code_constants_1.ERROR_OCCURED; };
exports.errorCheck = errorCheck;
var isKhaliCheck = function (value) {
    return value === '' || value === null || value === undefined;
};
exports.isKhaliCheck = isKhaliCheck;
var isNull = function (value) { return value === null; };
exports.isNull = isNull;
// Get random color
var getRandomColor = function () {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
exports.getRandomColor = getRandomColor;
// Get color for the Accord item value amount
var getColorFromType = function (type) {
    return type === 'INCOME' ? colors_1.GREEN_PLOOT : colors_1.RED_PLOOT;
};
exports.getColorFromType = getColorFromType;
// resolves string path from object (obj.a.b.c : path = 'a.b.c')
function resolveObjectPath(path, obj) {
    return path
        ? path.split('.').reduce(function (prev, curr) { return (prev ? prev[curr] : null); }, obj)
        : obj;
}
exports.resolveObjectPath = resolveObjectPath;
// Get key array
var getKeyArray = function (objectArray, key) {
    return objectArray.map(function (item) { return item[key]; });
};
exports.getKeyArray = getKeyArray;
var calculateRetirementAssessts = function (retireAge, current_age, openingBalance, contribution, strategyIndex, dispatch) {
    var assetsGraph = [];
    var earnYears = retireAge - current_age;
    var total = openingBalance;
    // assetsGraph.push({ x: current_age, y: total });
    for (var i = 0; i <= earnYears - 1; i += 1) {
        total += contribution;
        total *= 1 + index_2.strategyOptions[strategyIndex].value;
        assetsGraph.push({ x: i + current_age, y: total });
    }
    dispatch({
        type: types_1.GRAPH_GRAPH_RESULTS,
        payload: {
            graph: { assets: assetsGraph },
            results: { retirementAssests: total },
        },
    });
    return total;
};
exports.calculateRetirementAssessts = calculateRetirementAssessts;
// Retirment Returns graph
// Todo: format
var calculateAfterRetirementReturns = function (_a) {
    var lifeExp = _a.lifeExp, retireAge = _a.retireAge, retirementAssests = _a.retirementAssests, strategyIndex = _a.strategyIndex, expense_home = _a.expense_home, socialSecurity = _a.socialSecurity, inflation = _a.inflation, dispatch = _a.dispatch;
    var retirementYears = lifeExp - retireAge;
    var retirementAssets = retirementAssests;
    var remainingAssets = retirementAssets;
    var totalReturn = 0;
    var totalExpenses = 0;
    var gap = [];
    var future = [];
    var index = +strategyIndex ? +strategyIndex - 1 : 0;
    var flag = false;
    var findSocialSecurity = function (age) {
        var item = socialSecurity === null || socialSecurity === void 0 ? void 0 : socialSecurity.find(function (i) { return i.Age === age; });
        return item ? item.Benefits : 0;
    };
    console.log('Retire Age in Gap : ', retireAge, '+++++++==');
    for (var i = 0; i <= retirementYears - 1; i += 1) {
        var x = i + retireAge;
        var benefit = findSocialSecurity(x);
        var expense = expense_home * Math.pow(inflation, i);
        expense -= benefit;
        future.push({ x: x, y: expense });
        totalExpenses += expense;
        if (expense >= remainingAssets) {
            gap.push({ x: i + retireAge, y: 0 });
            remainingAssets = 0;
            flag = true;
        }
        else {
            remainingAssets -= expense;
            var retirementReturn = remainingAssets * index_2.strategyOptions[index].value;
            remainingAssets += retirementReturn;
            totalReturn += retirementReturn;
            gap.push({ x: x, y: remainingAssets });
        }
    }
    dispatch({
        type: types_1.GRAPH_GRAPH_RESULTS,
        payload: { graph: { gap: gap, future: future, flag: flag } },
    });
    return { totalReturn: totalReturn, totalExpenses: totalExpenses };
};
exports.calculateAfterRetirementReturns = calculateAfterRetirementReturns;
// Retirment gap calculations
// Todo: format
var calculateRetirementGap = function (_a) {
    var retireAge = _a.retireAge, lifeExp = _a.lifeExp, strategyIndex = _a.strategyIndex, expense_home = _a.expense_home, retirementAssests = _a.retirementAssests, socialSecurity = _a.socialSecurity, _b = _a.inflation, inflation = _b === void 0 ? index_1.inflation : _b, dispatch = _a.dispatch;
    var _c = (0, exports.calculateAfterRetirementReturns)({ lifeExp: lifeExp, retireAge: retireAge, retirementAssests: retirementAssests, strategyIndex: strategyIndex, expense_home: expense_home, socialSecurity: socialSecurity, inflation: inflation, dispatch: dispatch }), totalReturn = _c.totalReturn, totalExpenses = _c.totalExpenses;
    var retirementGap = retirementAssests + totalReturn - totalExpenses;
    dispatch({
        type: types_1.GRAPH_GRAPH_RESULTS,
        payload: {
            results: {
                futureExpenses: totalExpenses,
                retireReturns: totalReturn,
                retirementGap: retirementGap,
            },
        },
    });
    return retirementGap;
};
exports.calculateRetirementGap = calculateRetirementGap;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCount = void 0;
var react_1 = require("react");
// Used to check how many times a component rendered
var useRenderCount = function (screen) {
    var ref = (0, react_1.useRef)(0);
    console.log(ref.current++, "".concat(screen));
};
exports.useRenderCount = useRenderCount;
exports.default = exports.useRenderCount;

import React from "react";
import styles from "./SmallFutureGraph.module.css";
import { useSelector } from "react-redux";
import BarGraph from "../../../Utils/Charts/BarGraph/BarGraph";
import { BLUE_PLOOT } from "@plootus/common";

const SmallFutureGraph = () => {
  const graphData = useSelector((state) => state.graphReducer.graph.future);

  // console.log(graphData);

  return (
    <div className={styles.graphOuter}>
      <BarGraph
        sampleData={graphData}
        showTickX={true}
        showTickY={true}
        color1={BLUE_PLOOT}
        borderRadius={4}
        barWidth={7}
        fontSize={20}
        yLabelShow={false}
        giveMargin={false}
        yLabelShow={true}
        showTooltip={true}
        gType="future"
      />
    </div>
  );
};

export default SmallFutureGraph;

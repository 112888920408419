"use strict";
// Error codes from apis
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_LIMIT = exports.SESSION_EXPIRED = exports.FORBIDDEN = exports.SERVER_ERROR = exports.ERROR_OCCURED = exports.SUCCESS_CODE = exports.INVALID_TOKEN_CODE = void 0;
exports.INVALID_TOKEN_CODE = 4010;
exports.SUCCESS_CODE = 1;
exports.ERROR_OCCURED = -99;
exports.SERVER_ERROR = 500;
exports.FORBIDDEN = 403;
exports.SESSION_EXPIRED = 401;
exports.SEARCH_LIMIT = 400;

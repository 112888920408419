/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import styles from './FooterTop.module.css';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { SEND_ALERT } from '@plootus/common';

const FooterTop = () => {
  const [email, setEmail] = useState('');
  const [blogs, setBlogs] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    async function f() {
      const result = await Axios.get(
        'https://www.plootus.com/blog/?rest_route=/wp/v2/posts&per_page=1'
      );
      setBlogs(result.data);
    }
    f();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email !== '') {
      Axios.post('https://www.plootus.com/api/user/mailingList', {
        email: email,
      })
        .then((res) => {
          dispatch({
            type: SEND_ALERT,
            payload: {
              title: 'Done',
              textContent: 'You have been added to our mailing list.',
            },
          });
          setEmail('');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={styles.topWrapper}>
      <div className={styles.linkDiv}>
        <div className={styles.link1}>
          <Link to="/" className={styles.link}>
            Home
          </Link>
          <Link to="/philosophy" className={styles.link}>
            Philosophy
          </Link>
          <Link to="/algorithm" className={styles.link}>
            Algorithm
          </Link>
        </div>
        <div className={styles.link1}>
          <Link to="/security" className={styles.link}>
            Security
          </Link>
          <Link to="/faq" className={styles.link}>
            FAQ
          </Link>
          {blogs.length ? (
            <a
              href={blogs[0].link}
              target="_blank"
              rel="noopener"
              className={styles.link}
            >
              Our Blogs
            </a>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={styles.BtnsDiv}>
        <div className={styles.header}>Download App</div>
        <div className={styles.btnDiv}>
          <button className={styles.googlePLay}>
            <a
              href="https://play.google.com/store/apps/details?id=com.plootus.android"
              target="_blank"
              rel="noopener"
              className={styles.link}
            >
              <img src="/googlePlay.png" height="25px" width="25px" alt="Google Play App" title= 'Plootus Google Play App' />{' '}
              Google Play
            </a>
          </button>
        </div>
        <div className={styles.btnDiv}>
          <button className={styles.appStore}>
            <a
              href="https://apps.apple.com/us/app/plootus/id1311669590"
              target="_blank"
              rel="noopener"
              className={styles.splink}
            >
              <img
                src="/appleblack.png"
                height="20px"
                width="20px"
                style={{
                  marginBottom: '3px',
                  marginRight: '5px',
                }}
                alt='Plootus App Store'
                title= 'App Store'
              />{' '}
              App Store
            </a>
          </button>
        </div>
      </div>
      <div className={styles.subscribeDiv}>
        <div className={styles.heading}>SUBSCRIBE</div>
        <div className={styles.tagLine}>
          Lets get in touch. It will be worth it.
        </div>
        <div className={styles.shortForm}>
          <input
            type="email"
            placeholder="Enter Your Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.ipt}
          />
          <button className={styles.submitBtn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
        <div className={styles.socialIcons}>
          <a
            href="https://www.facebook.com/Plootus-1831199213836690/"
            target="_blank"
            rel="noopener"
            className={`${styles.icon}`}
          >
            <i className="fa fa-facebook-f" style={{ fontSize: '20px' }}></i>
          </a>
          <a
            href="https://twitter.com/WearePlootus"
            target="_blank"
            rel="noopener"
            className={`${styles.icon}`}
          >
            <i className="fa fa-twitter" style={{ fontSize: '20px' }}></i>
          </a>
          <a
            href="https://www.linkedin.com/company/plootus/"
            target="_blank"
            rel="noopener"
            className={`${styles.icon}`}
          >
            <i className="fa fa-linkedin" style={{ fontSize: '20px' }}></i>
          </a>
          <a
            href="https://www.instagram.com/weareplootus/"
            target="_blank"
            rel="noopener"
            className={`${styles.icon}`}
          >
            <i className="fa fa-instagram" style={{ fontSize: '20px' }}></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterTop;

import React, { useState } from 'react';
import styles from './Modal.module.css';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import {
  delete_advisor,
  get_advisors,
  sendAlert,
  DANGER_RED,
} from '@plootus/common';

const DeleteModal = ({ show, firstName, lastName, id }) => {
  const [modalIsOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleClick = async () => {
    await dispatch(delete_advisor(id));
    await dispatch(get_advisors());
    closeModal();
  };

  return (
    <div className={show ? styles.outerContainer : styles.noneDiv}>
      <div className={styles.btnDiv}>
        <button
          onClick={() =>
            dispatch(
              sendAlert({
                buttonRightPress: handleClick,
                buttonLeftText: 'Cancel',
                title: 'Alert',
                textContent: `Are you sure you want to remove ${firstName} ${lastName} as your
          advisor? The person won’t be able to access your information anymore.`,
                buttonRightText: 'Remove',
                buttonRightContStyle: { backgroundColor: DANGER_RED },
              })
            )
          }
          className={styles.deleteButton}
        >
          -
        </button>
      </div>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.dModal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.content}>
          {`Are you sure you want to remove ${firstName} ${lastName} as your
          advisor? The person won’t be able to access your information anymore.`}
        </div>
        <div className={styles.delBtnsDiv}>
          <button className={styles.cancelBtn} onClick={closeModal}>
            Cancel
          </button>
          <button className={styles.removeBtn} onClick={handleClick}>
            Remove
          </button>
        </div>
      </Modal> */}
    </div>
  );
};

export default DeleteModal;

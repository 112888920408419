import { KeyOutlined, MailOutlined } from "@ant-design/icons";
import { BLUE_PLOOT, login_user } from "@plootus/common";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AdminDashboard from "./AdminDashboard";
import { Redirect } from "react-router-dom";

const { Title, Text } = Typography;

const Login = () => {
  const [form] = Form.useForm();
  const [{ loading, success }, setState] = useState({
    loading: false,
    success: localStorage.getItem("admin_token"),
  });
  const dispatch = useDispatch();

  const onFinish = async (data) => {
    setState((prev) => ({ ...prev, loading: true }));
    const token = await dispatch(
      login_user(data.email, data.password, "admin")
    );
    console.log(token);
    if (token) {
      localStorage.setItem("admin_token", token);
    }

    setState((prev) => ({
      ...prev,
      loading: false,
      success: token !== null,
      success: true,
    }));
  };

  return (
    <Row
      justify={"center"}
      align={"middle"}
      style={{
        height: "100vh",
      }}
    >
      {!success ? (
        <>
          <Title
            level={4}
            style={{
              marginTop: "60px",
              position: "absolute",
              top: 0,
              color: "#fff",
            }}
          >
            <Text
              style={{
                fontSize: "12px",
                color: BLUE_PLOOT,
              }}
            >
              Admin Dashboard
            </Text>
          </Title>
          <Col
            span={8}
            style={{
              backgroundColor: "#fff",
              padding: "0px 32px",
              paddingTop: "12px",
              borderRadius: "16px",
            }}
          >
            <Title level={3} style={{ marginBottom: "20px" }}>
              Login
            </Title>
            <Form
              labelAlign="left"
              name="basic"
              initialValues={{ remember: true }}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  placeholder="Enter your email address"
                  prefix={<MailOutlined />}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  prefix={<KeyOutlined />}
                />
              </Form.Item>

              <Row justify="start">
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </>
      ) : (
        <Redirect to="/admin/email-users" />
      )}
    </Row>
  );
};

export default Login;

import React from "react";
import styles from "./DrawerToggleButton.module.css";

const DrawerToggleButton = ({ drawerToggleClickHandler }) => {
  return (
    <button className={styles.toggle_button} onClick={drawerToggleClickHandler}>
      <div className={styles.toggle_button_line} />
      <div className={styles.toggle_button_line} />
      <div className={styles.toggle_button_line} />
    </button>
  );
};

export default DrawerToggleButton;

import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import styles from "./UserPicker.module.css";
import ProfileModal from "../../components/AuthPages/ProfileModal/ProfileModal";
import { Redirect, Link } from "react-router-dom";
import { logout_user } from "@plootus/common";

const itemStyle = {
  borderBottom: "1px solid #e5e5e5",
};

class MyComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      redirect: false,
    };
  }

  handleClick = async () => {
    await localStorage.removeItem("jwt_token");
    this.props.dispatch(logout_user());
    this.setState({
      redirect: true,
    });
  };

  handleClickOutside = (evt) => {
    if (!this.state.modalIsOpen)
      this.setState({
        ...this.state,
        open: false,
      });
  };

  openModal = () => {
    this.setState({
      ...this.state,
      modalIsOpen: !this.state.modalIsOpen,
    });
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      modalIsOpen: !this.state.modalIsOpen,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className={styles.dropDiv}>
        <div
          onClick={() =>
            this.setState({
              ...this.state,
              open: !this.state.open,
            })
          }
          className={styles.name}
        >
          <div>{this.props.username}</div>
          <i
            className="fa fa-caret-down"
            aria-hidden="true"
            style={{ padding: "0px 10px" }}
          ></i>
        </div>
        {this.state.open ? (
          <div className={styles.dropDown}>
            <ul className={styles.list}>
              <li className={styles.listItem} style={itemStyle}>
                <ProfileModal
                  modalIsOpen={this.state.modalIsOpen}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                />
              </li>
              <li
                className={styles.listItem}
                style={itemStyle}
                onClick={this.handleClickOutside}
              >
                <img src="/a2.svg" className={styles.icon} alt="" />
                <Link to="/auth/privacypolicy" className={styles.text}>
                  Privacy Policy
                </Link>
              </li>
              <li
                className={styles.listItem}
                style={itemStyle}
                onClick={this.handleClickOutside}
              >
                <img src="/a3.svg" className={styles.icon} alt="" />
                <Link to="/auth/termsandcondition" className={styles.text}>
                  Terms and Conditions
                </Link>
              </li>
              <li
                className={`${styles.listItem} ${styles.signout}`}
                onClick={this.handleClick}
              >
                <img src="/a4.svg" className={styles.icon} alt="" />
                <div className={styles.signOuttext}>Sign Out</div>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

export default onClickOutside(MyComponent);

import React, { useState } from "react";
import Sidebar from "./Sidebar";
import SideDrawer from "./SideDrawer";

const SideNavbar = () => {
  const [SideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  let sideDrawer;

  if (SideDrawerOpen) {
    sideDrawer = (
      <SideDrawer drawerToggleClickHandler={drawerToggleClickHandler} />
    );
  }

  return (
    <div>
      <Sidebar drawerToggleClickHandler={drawerToggleClickHandler} />
      {sideDrawer}
    </div>
  );
};

export default SideNavbar;

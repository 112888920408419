export function notNull(value) {
  return value !== null;
}

export const isEmpty = (value) => value === "";

export const validate = (field, value, fields, forcedTyped) => {
  const newFields = { ...fields };
  let newField;
  let result;

  switch (field) {
    case "firstName":
      result = /^[A-Z]+$/i.test(value);
      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? "" : "Enter valid first name",
        },
      };
      break;

    case "lastName":
      result = /^[A-Z]+$/i.test(value);
      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? "" : "Enter valid last name",
        },
      };
      break;
    case "mobileNo":
      result = /^\d{10}$/.test(value);

      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? "Enter valid phone number" : "",
        },
      };
      break;

    case "email":
      result = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? "Enter valid email address" : "",
        },
      };
      break;

    case "zipcode":
      result = /^\d{5}$/.test(value);

      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? "Enter valid zip code" : "",
        },
      };
      break;

    case "all": {
      // Validate all

      //first name
      result = /^[A-Z]+$/i.test(newFields.firstName.value);
      newField = {
        value: newFields.firstName.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? "" : "Enter valid first name",
        },
      };
      newFields.firstName = newField;

      // last name
      result = /^[A-Z]+$/i.test(newFields.lastName.value);
      newField = {
        value: newFields.lastName.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? "" : "Enter valid last name",
        },
      };
      newFields.lastName = newField;

      // Phone number
      result = /^\d{10}$/.test(newFields.mobileNo.value);
      newField = {
        value: newFields.mobileNo.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? "Enter valid phone number" : "",
        },
      };
      newFields.mobileNo = newField;

      // Email
      result = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        newFields.email.value
      );
      newField = {
        value: newFields.email.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? "Enter valid email address" : "",
        },
      };
      newFields.email = newField;

      // Zip code
      result = /^\d{5}$/.test(newFields.zipcode.value);
      newField = {
        value: newFields.zipcode.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? "Enter valid zip code" : "",
        },
      };

      newFields.zipcode = newField;

      return newFields;
    }

    default:
      return newFields;
  }

  newFields[field] = newField;

  return newFields;
};

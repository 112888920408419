"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePickerModal = void 0;
var react_1 = require("react");
var useDidUpdate_1 = require("./useDidUpdate");
/**
 *
 * @param {func} func The function to execute after the picer index changes
 * @param {number} initalIndex The initial index for the picker, default 0
 */
var usePickerModal = function (func, initalIndex, didUpdate) {
    if (initalIndex === void 0) { initalIndex = 0; }
    if (didUpdate === void 0) { didUpdate = false; }
    var _a = (0, react_1.useState)({
        index: initalIndex,
        visible: false,
    }), picker = _a[0], setPicker = _a[1];
    (0, useDidUpdate_1.useDidUpdate)(function () {
        func(picker.index);
    }, [picker.index], didUpdate);
    return {
        // Set Picker index onChange
        setIndex: function (index) {
            return setPicker(function (prevState) { return (__assign(__assign({}, prevState), { index: index, visible: false })); });
        },
        // Hide picker modal
        hideModal: function () {
            return setPicker(function (prevState) { return (__assign(__assign({}, prevState), { visible: false })); });
        },
        // Show picker modal
        showModal: function () {
            return setPicker(function (prevState) { return (__assign(__assign({}, prevState), { visible: true })); });
        },
        negativeModal: function () {
            return setPicker(function (prevState) { return (__assign(__assign({}, prevState), { visible: !prevState.visible })); });
        },
        picker: picker,
    };
};
exports.usePickerModal = usePickerModal;

import React, { useRef, useEffect } from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import { formatCommaString, roundNum, BLUE_PLOOT } from '@plootus/common';

import styles from './AdminNav.module.css';
import Logo from '../../Assets/logo.png';

function AdminNav({
  isSaved,
  handleTestEmail,
  handleEmailUsers,
  handleSaveDesign,
  handleLoadDesignModal,
  location
}) {
  const dashboardRef = useRef(null);
  const emailRef = useRef(null);
  const excelRef = useRef(null);
  const [userAsset, setUserAsset] = React.useState();

  useEffect(() => {
    if (location.pathname === '/admin/dashboard') {
      dashboardRef.current.className = styles.active;
    } else if (location.pathname === '/admin/email-users') {
      emailRef.current.className = styles.active;
    } else if (location.pathname === '/admin/excel-upload') {
      excelRef.current.className = styles.active;
    }
  });

  const menu = () => (
    <Menu>
      <Menu.Item>
        <Button
          className={styles.button}
          type='primary'
          onClick={handleTestEmail}
        >
          Test email
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          className={styles.button}
          type='primary'
          onClick={handleEmailUsers}
        >
          Email Users
        </Button>
      </Menu.Item>
    </Menu>
  );

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          'https://www.plootus.com/api/user/getAllUser',
          {
            headers: {
              Authorization: localStorage.getItem('admin_token')
            }
          }
        );
        console.log(data.result[0].totalAssetsOfAllUsers);
        setUserAsset(data.result[0].totalAssetsOfAllUsers);
      } catch (err) {
        if (err.response?.status === 401) {
          localStorage.removeItem('admin_token');
        }
        console.log('error in all users');
      }
    })();
  }, []);

  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <a href='/' className={styles.link}>
          <img src={Logo} className={styles.logo} alt='PLOOTUS' title= 'Plootus icon' />
        </a>
        <a href='/admin/dashboard' className={styles.link} ref={dashboardRef}>
          Dashboard
        </a>
        <a href='/admin/email-users' className={styles.link} ref={emailRef}>
          Email Users
        </a>
        <a href='/admin/excel-upload' className={styles.link} ref={excelRef}>
          Excel Upload
        </a>
        {location.pathname === '/admin/dashboard' && (
          <a className={styles.link}>TotalAsset : {formatCommaString(
            roundNum(
              userAsset,
              0,
              0,
              true,
              true
            )
          )}</a>
        )}
        {location.pathname === '/admin/email-users' && (
          <div className={styles.buttonDiv}>
            <Button
              className={styles.saveButton}
              type='primary'
              onClick={handleSaveDesign}
            >
              {isSaved ? 'Update Design' : 'Save Design'}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              onClick={() => handleLoadDesignModal(true)}
            >
              Load Design
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              onClick={handleEmailUsers}
            >
              Email Users
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              onClick={handleTestEmail}
            >
              Test email
            </Button>
            {/* <Dropdown overlay={menu}>
              <DownOutlined />
            </Dropdown> */}
          </div>
        )}
      </nav>
    </div>
  );
}

export default AdminNav;

import React, { useState } from 'react';
import PasswordInput from '../../../Utils/PasswordInput/PasswordInput';
import PasswordRow from './PasswordRow';
import styles from './Security.module.css';
import {
  user_signup,
  DANGER_RED,
  TEXT_BLACK,
  useDidUpdate,
  resendActivationEmail,
  BLUE_PLOOT,
  WHITE,
  sendAlert,
} from '@plootus/common';
import { connect, useSelector } from 'react-redux';
import { notNull } from '../utils';
import TermsConditionsModal from './TermsConditionsModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import useWindowDimensions from '../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#34d86a' },
  },
  typography: { useNextVariants: true },
});

const Security = ({
  signupData,
  dispatch,
  setSignupData,
  validations,
  setValidations,
  closeModal,
  loginopenModal,
}) => {
  const [fields, setFields] = useState({
    password: '',
    confPassword: '',
  });

  const userReducer = useSelector((state) => state.userReducer);
  const { height, width } = useWindowDimensions();
  const handleTextChange = (value, field) => {
    setFields((prevState) => ({ ...prevState, [field]: value }));
    setSignupData((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
    if (field === 'password') {
      let val_arr = [false, false, false, false];
      if (value.length >= 8) {
        val_arr[0] = true;
      }

      if (/(?=.*[A-Z])/.test(value)) {
        val_arr[1] = true;
      }

      if (/(?=.*\d)/.test(value)) {
        val_arr[2] = true;
      }

      if (/(?=.*[a-z])/.test(value)) {
        val_arr[3] = true;
      }

      setValidations({
        validationArr: val_arr,
        isMatched: notNull(isMatched) ? value === fields.confPassword : null,
      });
    } else {
      setValidations((prevState) => ({
        ...prevState,
        isMatched: value === fields.password,
      }));
    }
  };

  const { validationArr, isMatched } = validations;
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setIsOpen(false);
    setLoading(true);
    await dispatch(
      user_signup({
        ...signupData,
        password: fields.password,
      })
    );
    setLoading(false);
  };

  useDidUpdate(() => {
    setLoading(false);

    if (userReducer.register_success) {
      dispatch(
        sendAlert({
          title: 'Verify your email',
          textContent:
            'Link for account activation has been sent to your email. Please check your Promotions or Spam folder.',
          buttonLeftText: 'Resend',
          buttonLeftPress: () => {
            dispatch(resendActivationEmail(signupData.email));
          },
          buttonRightPress: () => {
            closeModal();
            loginopenModal();
          },
        })
      );
    }
  }, [userReducer]);

  const handlePress = () => {
    let isInvalid = false;

    if (validationArr != null && isMatched !== null) {
      isInvalid = validationArr.includes(false) || !isMatched;
    } else {
      isInvalid = true;
      if (validationArr != null)
        setValidations((prevState) => ({
          ...prevState,
          isMatched: false,
        }));
      else
        setValidations((prevState) => ({
          ...prevState,
          isMatched: false,
          validationArr: Array(5).fill(false),
        }));
    }

    if (!isInvalid) {
      setIsOpen(true);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container}>
      <TermsConditionsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={handleSubmit}
      />
      <div>
        <PasswordInput
          title="Set Password"
          config={{
            onChangeText: (value) => handleTextChange(value, 'password'),
            value: signupData.password,
            maxLength: 32,
          }}
          msg={null}
          textInputStyle={{
            borderRadius: '5px',
            height: width > 576 ? '50px' : '40px',
          }}
          titleStyle={{
            fontSize: width > 576 ? '18px' : '14px',
            color: TEXT_BLACK,
          }}
        />
      </div>
      <div>
        <PasswordRow val_arr={validationArr} />
      </div>
      <div>
        <PasswordInput
          title="Confirm Password"
          textInputStyle={{
            borderRadius: '5px',
            height: width > 576 ? '50px' : '40px',
          }}
          config={{
            onChangeText: (value) => handleTextChange(value, 'confPassword'),
            value: signupData.confPassword,
          }}
          msg={
            notNull(isMatched)
              ? isMatched
                ? 'Passwords match'
                : "Passwords don't match"
              : null
          }
          titleStyle={{
            fontSize: width > 576 ? '18px' : '14px',
            color: TEXT_BLACK,
          }}
          msgStyle={{
            color: isMatched ? '#52da9c' : DANGER_RED,
            height: '3vh',
            fontSize: '11.5px',
          }}
        />
      </div>
      <div className={styles.signup}>
        {loading ? (
          <MuiThemeProvider theme={theme}>
            <CircularProgress size={30} />
          </MuiThemeProvider>
        ) : (
          <button className={styles.signupButton} onClick={handlePress}>
            Sign Up
          </button>
        )}
      </div>
    </div>
  );
};

export default connect(({ userReducer }) => ({
  register_success: userReducer.register_success,
}))(Security);

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetUser = exports.setChoosenClient = exports.changeTokenState = exports.generalUserAction = exports.confirmPassword = exports.forgotPassword = exports.deleteAccount = exports.getAccounts = exports.updateProfile = exports.get_clients = exports.delete_advisor = exports.add_advisor = exports.get_advisors = exports.resendActivationEmail = exports.user_signup = exports.logout_user = exports.facebook_login = exports.google_login = exports.login_user = exports.does_user_exist = exports.getUser = void 0;
var types_1 = require("../user/types");
var helperfunctions_1 = require("../Utils/helperfunctions");
var return_code_constants_1 = require("./../../constants/return_code_constants");
var actions_1 = require("./../global/actions");
var selectors_1 = require("./selectors");
var getUser = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/getuser', null, [getState, dispatch], 'get')];
            case 1:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                (0, helperfunctions_1.handleResponse)(errorCode, dispatch, (0, exports.generalUserAction)({ userData: responseData }));
                return [2 /*return*/, responseData];
        }
    });
}); }; };
exports.getUser = getUser;
var does_user_exist = function (email) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var responseData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/getUserType', { email: email.trim() }, [getState, dispatch], 'get', false)];
            case 1:
                responseData = (_a.sent()).responseData;
                return [2 /*return*/, responseData.types.length];
        }
    });
}); }; };
exports.does_user_exist = does_user_exist;
var login_user = function (email, password, type) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var resData, params, _a, responseData, errorCode, token;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/getUserType', { email: email.trim() }, [getState, dispatch], 'get', false)];
            case 1:
                resData = (_b.sent()).responseData;
                if (!resData.types.length) return [3 /*break*/, 3];
                if (type && !resData.types.includes(type)) {
                    dispatch((0, actions_1.sendAlert)({ title: 'Error', textContent: 'Login failed.' }));
                    return [2 /*return*/, return_code_constants_1.ERROR_OCCURED];
                }
                params = {
                    email: email.trim(),
                    password: password,
                    userType: type ? type : resData.types[0],
                };
                if (getState().userReducer.viaEmail) {
                    params.viaEmail = true;
                    params.userAdvisorId = getState().userReducer.userAdvisorId;
                }
                return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/signIn', params, [getState, dispatch], 'post', false)];
            case 2:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                token = responseData.token;
                if (type === 'admin') {
                    if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                        return [2 /*return*/, token];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                }
                return [2 /*return*/, (0, helperfunctions_1.handleResponse)(errorCode, dispatch, 
                    // If admin dont save token
                    (0, exports.generalUserAction)({ token: token, viaEmail: null }))];
            case 3:
                dispatch((0, actions_1.sendAlert)({ title: 'Error', textContent: 'Login failed.' }));
                _b.label = 4;
            case 4: return [2 /*return*/, return_code_constants_1.ERROR_OCCURED];
        }
    });
}); }; };
exports.login_user = login_user;
var google_login = function (tokenId, platform) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode, token;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/google/oauth', {
                    access_token: tokenId,
                }, [getState, dispatch], 'post', false)];
            case 1:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                token = responseData.token;
                if (token) {
                    return [2 /*return*/, (0, helperfunctions_1.handleResponse)(errorCode, dispatch, (0, exports.changeTokenState)(token))];
                }
                else {
                    dispatch((0, actions_1.sendAlert)({
                        title: 'Error',
                        textContent: 'Could not connect with Google.',
                    }));
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.google_login = google_login;
var facebook_login = function (tokenId, platform) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode, token;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/facebook/oauth', {
                    access_token: tokenId,
                    platform: platform,
                }, [getState, dispatch], 'post', false)];
            case 1:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                token = responseData.token;
                if (token) {
                    return [2 /*return*/, (0, helperfunctions_1.handleResponse)(errorCode, dispatch, (0, exports.changeTokenState)(token))];
                }
                else {
                    dispatch((0, actions_1.sendAlert)({
                        title: 'Error',
                        textContent: 'Could not connect with Facebook.',
                    }));
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.facebook_login = facebook_login;
var logout_user = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch((0, exports.changeTokenState)(null));
        return [2 /*return*/];
    });
}); }; };
exports.logout_user = logout_user;
var user_signup = function (data, advisor, userAdvisorId, advisorAddress) {
    if (advisor === void 0) { advisor = false; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var _data, errorCode;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _data = !advisor
                        ? __assign(__assign({}, data), { userType: 'primary' }) : __assign(__assign({}, data), { viaEmail: true, userType: 'advisor', userAdvisorId: userAdvisorId, advisorAddress: advisorAddress });
                    return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/signUp', _data, [getState, dispatch], 'post', false)];
                case 1:
                    errorCode = (_a.sent()).errorCode;
                    if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                        dispatch((0, exports.generalUserAction)({ register_success: true }));
                    }
                    else {
                        dispatch((0, exports.generalUserAction)({ register_success: false }));
                        if (errorCode === return_code_constants_1.FORBIDDEN) {
                            dispatch((0, actions_1.sendAlert)({
                                title: 'Error',
                                textContent: 'This Email is already registered.',
                            }));
                        }
                        else {
                            dispatch((0, actions_1.sendAlert)({ title: 'Error', textContent: 'Registration failed.' }));
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
exports.user_signup = user_signup;
var resendActivationEmail = function (email) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var errorCode;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/resendEmail', { email: email === null || email === void 0 ? void 0 : email.trim() }, [getState, dispatch], 'post')];
            case 1:
                errorCode = (_a.sent()).errorCode;
                if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                    return [2 /*return*/, (0, helperfunctions_1.handleResponse)(errorCode, dispatch, null)];
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.resendActivationEmail = resendActivationEmail;
// Get all user advisors
var get_advisors = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode, data;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('advisor/getAllAdvisor', null, [getState, dispatch], 'get')];
            case 1:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                data = responseData.data;
                if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                    dispatch((0, exports.generalUserAction)({ client: { advisors: data } }));
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.get_advisors = get_advisors;
var add_advisor = function (email) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var errorCode;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('advisor/create', { email: email.trim() }, [getState, dispatch], 'post')];
            case 1:
                errorCode = (_a.sent()).errorCode;
                if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                    dispatch((0, actions_1.sendAlert)({
                        title: 'Email sent to Advisor',
                        textContent: 'Once they activate, we will provide access to your Plootus account.',
                    }));
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.add_advisor = add_advisor;
var delete_advisor = function (id) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var errorCode;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('advisor/delete', { id: id }, [getState, dispatch], 'delete')];
            case 1:
                errorCode = (_a.sent()).errorCode;
                if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                    dispatch((0, actions_1.sendAlert)({ title: 'Success', textContent: 'Advisor Removed' }));
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.delete_advisor = delete_advisor;
var get_clients = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode, data;
    var _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('advisor/getAllUser', null, [getState, dispatch], 'get')];
            case 1:
                _a = _d.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                data = responseData.data;
                if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                    dispatch((0, exports.generalUserAction)({
                        advisor: __assign(__assign({}, getState().userReducer.advisor), { clients: ((_b = data === null || data === void 0 ? void 0 : data[0]) === null || _b === void 0 ? void 0 : _b.primaryUsers) || [] }),
                    })); // Have to change interface of Client according to backend data
                }
                return [2 /*return*/, ((_c = data === null || data === void 0 ? void 0 : data[0]) === null || _c === void 0 ? void 0 : _c.primaryUsers) || []];
        }
    });
}); }; };
exports.get_clients = get_clients;
var updateProfile = function (data, shouldUpdateClient) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var isAdvisor, userId, errorCode;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                isAdvisor = (0, selectors_1.isAdvisorSelector)(getState());
                userId = isAdvisor ? (_a = (0, selectors_1.currentClientSelector)(getState())) === null || _a === void 0 ? void 0 : _a.uuid : null;
                return [4 /*yield*/, (0, helperfunctions_1.async_func_data)(isAdvisor && shouldUpdateClient
                        ? "user/updateUser?userId=".concat(userId)
                        : 'user/updateUser', data, [getState, dispatch], 'post')];
            case 1:
                errorCode = (_b.sent()).errorCode;
                if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                    // In future send floating alert that profile updated
                }
                else {
                    dispatch((0, actions_1.sendAlert)({ title: 'Error', textContent: 'Profile Update failed.' }));
                }
                return [2 /*return*/, errorCode];
        }
    });
}); }; };
exports.updateProfile = updateProfile;
var getAccounts = function (userId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('account/getAllAccounts', { userId: userId }, [getState, dispatch], 'get')];
            case 1:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                (0, helperfunctions_1.handleResponse)(errorCode, dispatch, (0, exports.generalUserAction)({ accounts: responseData }));
                return [2 /*return*/];
        }
    });
}); }; };
exports.getAccounts = getAccounts;
var deleteAccount = function (acc_id) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var errorCode;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('account/deleteAccount', { acc_id: acc_id }, [getState, dispatch], 'delete')];
            case 1:
                errorCode = (_a.sent()).errorCode;
                if (errorCode === return_code_constants_1.SUCCESS_CODE) {
                    dispatch((0, actions_1.sendAlert)({
                        title: 'Account Deleted',
                        textContent: 'Account deleted successfully',
                    }));
                    return [2 /*return*/, return_code_constants_1.SUCCESS_CODE];
                }
                else {
                    dispatch((0, actions_1.sendAlert)({ title: 'Error', textContent: 'Deletion failed.' }));
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.deleteAccount = deleteAccount;
var forgotPassword = function (email) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var resData, errorCode;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/getUserType', { email: email.trim() }, [getState, dispatch], 'get', false)];
            case 1:
                resData = (_a.sent()).responseData;
                if (!resData.types.length) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/forgotPasswordEmail', {
                        email: email.trim(),
                        userType: resData.types[0],
                    }, [getState, dispatch], 'get', false)];
            case 2:
                errorCode = (_a.sent()).errorCode;
                return [2 /*return*/, errorCode];
            case 3:
                dispatch((0, actions_1.sendAlert)({ title: 'Error', textContent: 'Password Reset Failed.' }));
                return [2 /*return*/, return_code_constants_1.ERROR_OCCURED];
        }
    });
}); }; };
exports.forgotPassword = forgotPassword;
var confirmPassword = function (id, password, vt) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var errorCode;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('user/forgotPasswordNew', { id: id, newPassword: password, vt: vt }, [getState, dispatch], 'post', false)];
            case 1:
                errorCode = (_a.sent()).errorCode;
                return [2 /*return*/, errorCode];
        }
    });
}); }; };
exports.confirmPassword = confirmPassword;
var generalUserAction = function (payload) { return ({
    type: types_1.GENERAL_USER_REDUCER,
    payload: payload,
}); };
exports.generalUserAction = generalUserAction;
var changeTokenState = function (token) { return ({
    type: types_1.CHANGE_TOKEN_STATE,
    payload: {
        token: token,
    },
}); };
exports.changeTokenState = changeTokenState;
var setChoosenClient = function (index) { return ({
    type: types_1.CHANGE_CLIENT,
    payload: index,
}); };
exports.setChoosenClient = setChoosenClient;
var resetUser = function () { return ({
    type: types_1.RESET_USER_DATA,
}); };
exports.resetUser = resetUser;

import React, { useState } from "react";
import styles from "./CategoryTabs.module.css";

const CategoryTabs = ({ rows, index, handlePress, visible, setIndex }) => {
  const [active, setActive] = useState(index);
  return (
    <div className={styles.container}>
      {rows.map((row, idx) => {
        return (
          <div className={styles.outer} key={idx}>
            <button
              onClick={() => {
                if (idx !== index) {
                  setIndex(idx);
                  setActive(idx);
                }
              }}
              className={idx === active ? styles.btn : styles.dead}
            >
              Sort {row.label}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryTabs;

import React, { useState } from 'react';
import styles from './phiosophy.module.css';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios, { post } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  BLUE_PLOOT,
  DANGER_RED,
  ERROR_OCCURED,
  facebook_login,
  GENERAL_GRAPH,
  getEditAssumptionsInitialState,
  getUser,
  get_clients,
  google_login,
  login_user,
  sendAlert,
  SEND_ALERT,
  TEXT_BLACK,
  tokenSelector,
  WHITE,
  resendActivationEmail,
  RESET_GENERAL
} from '@plootus/common';
const Admin = () => {
  window.scrollTo(0, 0);
  let ex1, ex2;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = createMuiTheme({
    palette: {
      primary: { main: BLUE_PLOOT } // Purple and green play nicely together.
    },
    typography: { useNextVariants: true }
  });
  const handleUploadFile = (event) => {
    ex1 = event.target.files;
    console.log(event.target.files);
  };

  const handleUploadFile2 = (event) => {
    ex2 = event.target.files;
    console.log(ex2);
  };
  const handleUnsubscribe = async () => {
    const formData = new FormData();
    formData.append('uploadType', 'allocation');
    if (ex1 != null || undefined) {
      for (let i = 0; i < ex1.length; i++) {
        formData.append('files', ex1[i]);
      }
    }

    if (ex2 != null || undefined) {
      for (let i = 0; i < ex2.length; i++) {
        formData.append('files', ex2[i]);
      }
    }

    console.log(formData);
    console.log(ex1);

    try {
      setLoading(true);
      const url = `https://www.plootus.com/api/uploadxldata`;

      await axios
        .post(url, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            dispatch({
              type: SEND_ALERT,
              payload: {
                title: 'Upload Success',
                textContent: 'File was suceessfully uploaded'
              }
            });
          }
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      if (err.response.status === 500) {
        dispatch({
          type: SEND_ALERT,
          payload: {
            title: 'Upload Error',
            textContent: 'Please upload only Excel file'
          }
        });
      }
      if (err.response.status === 400) {
        dispatch({
          type: SEND_ALERT,
          payload: {
            title: 'Upload Error',
            textContent: 'Please Select Excel file'
          }
        });
      }

      console.log(err.response.status);
    }
  };
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Admin</title>
        <meta
          name='description'
          content='Plootus 401k, IRA, 403b and 457 investment philosophy & strategy starts with picking the right mix of stocks, bonds, & funds totally free. Get your analysis!'
        />
        <meta
          name='keywords'
          content='Expert financial advice, Democratize financial planning, guaranted confidentiality, algorithm analyzes, proper asset allocation, avoiding fees, constantly monitoring, 401k, IRA, 403b, 457 plans and risk tolerance'
        />
      </Helmet>

      <div className={styles.main}>
        <div style={{ flexDirection: 'column' }}>
          <h4 style={{ color: 'blue', marginBottom: 20 }}>Upload Employer</h4>
          <input type='file' onChange={handleUploadFile} multiple />
        </div>
        <div style={{ flexDirection: 'column' }}>
          <h4 style={{ color: 'blue', marginBottom: 20 }}>Upload Allocation</h4>
          <input type='file' onChange={handleUploadFile2} multiple />
        </div>
      </div>
      <div className={styles.login}>
        {loading ? (
          <div className={styles.loader}>
            <MuiThemeProvider theme={theme}>
              <CircularProgress size={40} />
            </MuiThemeProvider>
          </div>
        ) : (
          <>
            <button
              type='submit'
              onClick={() => {
                handleUnsubscribe();
              }}
              className={styles.loginButton}
            >
              Submit
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Admin;
